.wt-services-section{

    .wt-services-container{
        display: block;
        margin: 0 auto;
        width: 100%;

        .wt-services-tabs-buttons{
            padding: 0 10px;

            .slick-prev,
            .slick-next {
                font-size: 0;
                line-height: 0;
                position: absolute;
                top: 289px;
                left:0;
                display: block;
                width: 20px;
                height: 20px;
                padding: 0;

                transform: translate(50%, -50%);
                cursor: pointer;
                color: transparent;
                border: none;
                outline: none;
                background: transparent;
                z-index:999;
            }

            .slick-prev:before,
            .slick-next:before {
                display: block;
                position: absolute;
                font-family: 'slick';
                font-size: 30px;
                line-height: 1;
                color: #978E76;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            .slick-prev {
                left: 0;
                // background-color: $whiteF4;
                background-color: $whiteF4;
                background-image: url("/libs/img/up-arrow-svgrepo-com.svg");
                background-size: 14px 14px;
                background-position: center;
                background-repeat: no-repeat;
                
                padding: 15px;
                width: 40px;
                height: 40px;
                border-radius: 5px 5px 0 0  ;
                transform: translate(-50%, 0) rotate(270deg);
                right: unset;
                left: calc(50% + -20px);
                &:before {
                content: '';

                }
            }
            [dir='rtl'] {
                .slick-prev {
                right: 0;
                // left: auto;
               

                &:before {
                    content: '';
                }
                }
                .slick-next {
                // right: auto;
                left: 0;
                &:before {
                    content: '';
                }
                }
            }
            .slick-next {
                
                background-color: $whiteF4 ;
                background-image: url("/libs/img/up-arrow-svgrepo-com.svg");
                background-size: 14px 14px;
                background-position: center;
                background-repeat: no-repeat;

                padding: 15px;
                width: 40px;
                height: 40px;
                border-radius: 5px 5px 0 0  ;
                
                right: calc(50% + -20px);
                transform: translate(50%, 0) rotate(90deg);
                left: unset;

                &:before {
                content: '';
                }
                &:after {
                content: "";
                font-family: "slick";
                font-size: 28px;
                display: block;
                color: #978E76;
                bottom: 0;
                left: 0;
                position: absolute;
                }
            }
                    

            .slick-list{
                overflow: unset;
                
            }

            .slick-slide{
                margin: 0 10px;
            }


        @media (min-width: $screen-sm-min) {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(160px, 30%));
            justify-content: center;
            column-gap: 20px;
            max-width: 1180px;
            margin: 0 auto;
            padding: 0 20px;
        }
        @media (min-width: $screen-xl-min) {
                    padding: 0 0px;
        }

            
            .wt-service-tab{

                background-color: $green1;
                width: 100%;
                height: 310px;
                list-style-type: none;
                border-radius: 5px;
                transform: translateY(0px);
                transition: 0.5s;

                

                @media (min-width:$screen-sm-min){
                    height: 320px;
                    background-color: $whiteF4;
                }

                .wt-tab-img{
                    width: 180px;
                    height: 246px;
                    border-radius: 5px;
                    overflow: hidden;
                    margin: 0 auto;
                    position: relative;
                    top: -41px;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .wt-service-title{
                    font-family: 'PFD';
                    font-size: 24px;
                    line-height: 34px;
                    font-weight: 500;
                    text-transform: uppercase;
                    text-align: center;
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                    color: $white;
                    position: relative;
                    top: -24px;

                    

                    @media (min-width: $screen-sm-min) {
                        font-size: 20px;
                        line-height: 30px;
                        color: $text1;
                        position: unset;
                        top: unset;
                    }
                    @media (min-width: $screen-xl-min) {
                          font-size: 24px;
                           line-height: 34px;      
                    }

                    
                }
                



            }

            .wt-selected-tab{

                @media (min-width:$screen-sm-min){

                
                background-color: $green1;
                
                transform: translateY(-41px);
                transition: 0.5s;
                    .wt-service-title{
                        color: $white;
                    }
                }
            }
        }
        
        
       
    }

    .wt-information-service-container{

        display: block;
        margin: 0 auto;
        width: 100%;
        margin-top: 57px;
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 70px;

        @media (min-width:$screen-sm-min){
            padding-bottom: 0;
            margin-top: 30px;
        }

        


        &::after{
            content: '';
            display: block;
            position: absolute;
            background-color: $green1;
            width: 100% ;
            top: unset;
            bottom: 0;
            right: 0;
            z-index: -1;
            height: 57%;

            @media (min-width: $screen-sm-min) {
                width: calc(50% + -156px);
                top: 0;
                bottom: unset;
                height: 500px;
            }

            @media (min-width: $screen-xl-min ) {
                
            }

            @media (min-width: $screen-xl-min +59px) {
                width: calc(50% + -126px);
            }
            

        }
        
        
        .wt-services-tab-content{
       
            max-width: 1180px;
            margin: 0 auto;
            position: relative;
            display: block;
            padding: 0 10px;
            

            @media (min-width: $screen-sm-min) {
                padding: 0;
                display: none;
            }

           
            .wt-service-tab-title{
                font-family: 'PFDC';
                font-weight: 700;
                text-transform: uppercase;
                position: relative;

                @media (min-width: $screen-sm-min) {
                    padding-left: 20px;
                }
                    
                @media (min-width: $screen-xl-min) {
                            padding-left: 0;
                }


               

                .tab-title1{
                    font-size: 35px;
                    -webkit-text-stroke-color: $text1;
                    -webkit-text-stroke-width: 1.00px;
                    font-weight: 600;
                    color: transparent;

                    @media (min-width: $screen-sm-min) {
                        line-height: 1;
                        font-size: 70px;
                        height: 55px;
                        left: 20px;
                    }
                    @media (min-width: $screen-xl-min) {
                        font-size: 100px;
                        height: 80px;
                        left: 0;
                    }
                   

                }

                .tab-title2{
                    font-size: 35px;
                    color: $text1;
                    top: 33px;
                    font-weight: 600;
                    left: 0;

                    @media (min-width: $screen-sm-min) {
                        line-height: 1;
                        position: relative;
                        top: -30px;
                        font-size: 60px;
                        left: 20px;
                    }
                    @media (min-width: $screen-xl-min) {
                        top: -40px;
                        font-size: 80px;
                        left: 0;
                    }
                    
                }
            }

            .wt-service-information-block{
                margin-top: 40px;
                height: fit-content;
                position: relative;
                z-index: 2;
                margin-right: 0;
                border-radius: 5px;
                overflow: hidden;

                @media (min-width: $screen-sm-min) {
                    margin-top: 0;
                    margin-right: 20px;
                    height: 400px;
                    width: unset;
                }
                @media (min-width: $screen-xl-min) {
                    margin-right: 0px;
                    width: 100%;
                }

                &::before{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    background: rgb(244,244,244);
                    background: linear-gradient(90deg ,#f4f4f4 69%,rgba(244,244,244,0.01) 120%);
                    z-index: 2;

                    @media (min-width: $screen-xl-min) {
                        background: linear-gradient(90deg ,#f4f4f4 49%,rgba(244,244,244,0.01) 100%);
                    }
                }

                img{
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    z-index: 1;
                }

                .wt-service-text-information{
                    position: relative;
                    z-index: 3;
                    text-align: left;
                    font-family: 'PFD';
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 26px;
                    padding-left: 21px;
                    padding-top: 21px;
                    width: unset;
                    max-width: unset;

                    @media (min-width: $screen-sm-min) {
                    width: 100%;
                    max-width: 700px;
                    padding-left: 21px;
                    padding-top: 34px;
                    
                    }

                    @media (min-width: $screen-xl-min) {
                        
                        padding-left: 42px;
                        padding-top: 42px;
                    }



                   

                    span{
                        display: inline-block;

                        &:nth-of-type(2){
                            margin-top:13px;
                        }

                        &:nth-of-type(3){
                            margin-top:13px;
                        }
                    }
                    ul{
                        list-style-type: none;
                        margin-left: 10px;
                        li{
                            margin-top: 13px;
                            position: relative;
                            margin-left: 20px;
                            &::before{
                                content: '';
                                display: block;
                                position: absolute;
                                top: 5px;
                                left: -20px;
                                width: 2px;
                                height: 14px;
                                background-color: $green2;
                            }

                        }
                    }
                    
                }
                
            }

        }

        .wt-selected-tab{
            display: block!important;
            left: unset;

            .wt-service-information-block{
                opacity: 1;
                transition: 0.5s;
            }
            
        }
    }
}