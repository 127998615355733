.wt-buy-form-section{
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    z-index: 100;
    padding: 15px;
    box-sizing: border-box;

    .wt-buy-form-wrap{
        position: relative;
        margin: 0 auto;
        max-width: 500px;
        width: 100%;
        background-color: white;
        border-radius: 15px;
        box-sizing: border-box;
        padding: 15px;
        padding-top: 50px;

        .wt-close-button{
            position: absolute;
            top: 10px;
            right: 15px;

            height: 30px;
            width: 30px;

            background-image: url('/libs/img/close-1.svg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            cursor: pointer;
        }

        .wt-buy-form{
            display: grid;

            .wt-input{
                font-family: "PFD";
                font-size: 16px;

                height: 45px;
                border-radius: 5px;
                background-color: #d2e6d1;
                margin-bottom: 10px;  
                border: 1px solid #d2e6d1;
                box-sizing: border-box;
                padding: 0 12px 0 12px;
                color: $text1;
            }

            .wt-message{
                font-family: "PFD";
                font-size: 16px;
                color: $text1;
                display: none;
                
                height: 45px;
                border-radius: 5px;
                background-color: #d2e6d1;
                margin-bottom: 10px;

                font-weight: 500;
                font-size: 25px;
                line-height: 45px;
                font-family: "PFD";
                text-align: center;
            }

            .wt-button{
                height: 45px;
                border-radius: 5px;
                font-size: 20px;
                color: $white;
                font-weight: 500;
                font-family: "PFD";
                text-align: center;
                background-color: $green2;
                margin: 0;         
                display: block;
                border: 1px solid $green2;

                cursor: pointer;
            }

            .input-message{
                min-height: 100px;
                padding: 12px;
            }

            .wt-input-file{
                display: none;
            }

            .wt-input-file-label{
                display: block;
                position: relative;
                font-family: "PFD";
                font-size: 16px;
                line-height: 40px;
                color: $text1;
                padding-left: 45px;
                margin-bottom: 10px;
                cursor: pointer;

                &::before{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 35px;
                    height: 40px;

                    background-image: url('/libs/img/attachment-paperclip.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    transform: rotate(-45deg);

                    opacity: 0.5;
                    cursor: pointer;
                }
            }
        }
    }
}