.wt-woodtrade-info{
    position: relative;
    .wt-grid{
        display: grid;
        grid-template-columns: 15px auto 15px;
        position: relative;
        row-gap: 40px;
        padding: 20px 0 0 0;
        margin: 0 0 70px 0;
        @media (min-width: $screen-sm-min) {
            grid-template-rows: auto 62px auto 63px auto;
            grid-template-columns: 1fr 30px 250px;
            row-gap: 30px;
            padding: 0px 20px 40px 20px;
        }
        @media (min-width: $screen-contain-min) {
            grid-template-columns: 1fr 800px 180px 200px 1fr;
            grid-template-rows: 95px 154px 100px 237px 1fr;
            padding: 70px 0px 70px 0px;
        }
        .wt-title{
            // display: grid;
            grid-column: 2;
            @media (min-width: $screen-sm-min) {
                grid-column: 1;
                margin: 40px 0 0 0;
            }
            @media (min-width: $screen-contain-min) {
                grid-column: 2;
                grid-row: 1;
                margin: 0;
            }
            .wt-border-text{
                position: relative;
                text-align: center;
                font-family: 'PFDC';
                font-size: 35px;
                font-weight: 700;
                color: transparent;
                -webkit-text-stroke-color: $white;
                -webkit-text-stroke-width: 1px;
                @media (min-width: $screen-sm-min) {
                    font-size: 70px;
                    text-align: left;
                    height: 49px;
                    line-height: 63px;
                }
                @media (min-width: $screen-contain-min) {
                    font-size: 100px;
                    line-height: 90px;
                    height: 71px;
                    // align-self: start;
                }
            }
            .wt-text{
                text-align: center;
                font-family: 'PFDC';
                font-size: 35px;
                font-weight: 700;
                color: $white;
                @media (min-width: $screen-sm-min) {
                    font-size: 60px;
                    text-align: left;
                    position: relative;
                    line-height: 0;
                    top: 7px;
                }
                @media (min-width: $screen-contain-min) {
                    font-size: 80px;
                }
            }
        }
        .wt-info{
            grid-column: 2;
            grid-row: 3;
            padding: 20px;
            box-sizing: border-box;
            background-color: $white;
            border-radius: 5px;
            @media (min-width: $screen-sm-min) {
                grid-column: 1/-1;
                grid-row: 2/5;
            }
            @media (min-width: $screen-contain-min) {
                grid-column: 2/4;
                grid-row: 2/-1;
                padding: 20px 200px 20px 20px;
            }
            h6{
                font-size: 19px;
                font-weight: 500;
                margin: 0 0 20px 0;
            }
            ul{
                list-style: none;
                font-size: 18px;
                font-weight: 400;
                li{
                    margin: 0 0 20px 0;
                    padding: 0 0 0 20px;
                    box-sizing: border-box;
                    position: relative;
                    &::before{
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 4px;
                        display: block;
                        height: 16px;
                        width: 0;
                        border-left: 2px solid $green2;
                    }
                }
            }
            p{
                font-size: 18px;
                font-weight: 400;
                margin: 0 0 20px 0;
            }
            span{
                font-size: 18px;
                font-family: 'PFDC';
                font-weight: 700;
            }
        }
        .wt-video{
            grid-column: 2;
            grid-row: 2;
            height: 200px;
            background-image: url('/libs/img/doska-video.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 5px;
            @media (min-width: $screen-sm-min) {
                grid-column: 2/-1;
                grid-row: 1/3;
                height: 100%;
            }
            @media (min-width: $screen-contain-min) {
                grid-column: 3/5;
                grid-row: 1/3;
            }
        }
        .wt-name{
            position: relative;
            grid-column: 2;
            grid-row: 4;
            background-color: $green2;
            border-radius: 5px;
            padding: 17px 20px 15px 20px;
            box-sizing: border-box;
            color: $white;
            font-size: 18px;
            font-weight: 400;
            @media (min-width: $screen-sm-min) {
                grid-column: 3;
                grid-row: 4/-1;
                line-height: 30px;
                padding: 15px 25px 15px 25px;
                width: 280px;
            }
            @media (min-width: $screen-contain-min) {
                grid-column: 3/-1;
                grid-row: 3;
                width: 100%;
                border-radius: 5px 0px 0px 5px;
                padding: 0;
                display: grid;
            }
            p{
                align-self: center;
                @media (min-width: $screen-contain-min) {
                    width: 380px;
                    padding: 15px 0px 15px 30px;
                }
            }
        }
        .wt-background-green{
            position: absolute;
            display: block;
            z-index: -1;
            grid-row: 1/4;
            top: -20px;
            width: 100%;
            height: calc(100% + 40px);
            background-color: $green2;
            @media (min-width: $screen-sm-min) {
                display: none;
            }
        }
    }
    .wt-background-green{
        display: none;
        @media (min-width: $screen-sm-min) {
            display: block;
            position: absolute;
            background-color: $green2;
            bottom: 0;
            left: 0;
            height: 100%;
            width: calc(50% + 75px);
            z-index: -1;
        }
        @media (min-width: $screen-contain-min) {
            width: calc(50% + 90px);
        }
    }
    .wt-background-gray{
        display: none;
        @media (min-width: $screen-sm-min) {
            display: block;
            position: absolute;
            background-color: $whiteF4;
            bottom: 0;
            right: 0;
            height: calc(100% + 50px);
            width: calc(50% + 75px);
            z-index: -2;
        }
        @media (min-width: $screen-contain-min) {
            bottom: unset;
            top: -100px;
            height: calc(100% + 35px);
            width: calc(50% + 280px);
        }
    }
}

