.wt-side-menu-grid{
    .wt-mobile-filters{
        display: none;
        position: fixed;
        background-color: $white;
        z-index: 999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: 100vh;
        width: 100vw;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        opacity: 0;
        transform: translateY(-20px);
        transition: 0.2s;
        overflow-x: scroll;

        .wt-close{
            display: block;
            background-image: url('../img/close-1.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 25px;
            width: 25px;
        }
        .wt-filters{
            margin: 20px 0 0 0;
            list-style: none;
            font-size: 20px;
            font-family: 'PFDC';
            font-weight: 700;

            a{
                color: $text1;
                text-decoration: none;
            }
            
            .wt-item-filters{
                position: relative;
                font-size: 18px;
                font-weight: 400;
                padding: 0 0 0 0px;
                box-sizing: border-box;
                line-height: 30px;
            }
        }
    }
    .wt-opened-mobile-filters{
        display: block;
        opacity: 1;
        transform: translateY(0px);
        transition: .2s;
    }
}