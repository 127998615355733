@charset "UTF-8";
/*Normalize style*/
html {
  margin: 0;
  padding: 0;
  border: 0; }

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

article,
aside,
details,
figcaption,
figure,
dialog,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1.5;
  background: white; }

table {
  border-collapse: separate;
  border-spacing: 0; }

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  float: none !important; }

table,
th,
td {
  vertical-align: middle; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: ""; }

blockquote,
q {
  quotes: "" ""; }

a img {
  border: none; }

:focus {
  outline: 0; }

/*general css style */
html {
  font-size: 100%;
  /* This means 16px */
  overflow-x: hidden; }

body {
  color: #373737;
  font-family: 'PFD';
  line-height: unset;
  overflow-x: hidden; }

.clear {
  clear: both; }

.wt-container {
  box-sizing: border-box;
  padding: 0 10px 0 10px; }
  @media (min-width: 1025px) {
    .wt-container {
      max-width: 1180px;
      width: 100%;
      margin: 0 auto; } }
  @media (min-width: 1190px) {
    .wt-container {
      padding: 0 0 0 0;
      overflow: unset; } }

.wt-breadcrumbs {
  height: 60px; }

/*---------------------------------------
   Typography
-----------------------------------------*/
/* HEADINGS */
h1,
h2,
h3,
h4,
h5,
h6,
em,
p {
  font-weight: 400;
  margin: 0;
  padding: 0; }

.slider-section-bottom {
  background-color: #ffffff; }
  .slider-section-bottom .slider-container {
    width: 100%;
    max-width: 1360px;
    margin: 0 auto; }
    .slider-section-bottom .slider-container .grid-col {
      max-width: 80rem;
      margin: auto; }
      .slider-section-bottom .slider-container .grid-col .slider-bottom {
        margin-top: 40px;
        margin-bottom: 96px; }
        .slider-section-bottom .slider-container .grid-col .slider-bottom .slide {
          position: relative;
          min-height: 670px; }
          .slider-section-bottom .slider-container .grid-col .slider-bottom .slide .slider-content-text {
            text-align: center;
            position: absolute;
            top: 0;
            right: 120px;
            background-color: #2E3A36;
            font-size: 15px;
            color: #ffffff;
            height: 445px;
            width: 610px;
            z-index: 993; }
            .slider-section-bottom .slider-container .grid-col .slider-bottom .slide .slider-content-text .slide-heading {
              font-size: 38px;
              padding-left: 3em;
              padding-right: 3em; }
            .slider-section-bottom .slider-container .grid-col .slider-bottom .slide .slider-content-text .inner-slide-divider {
              height: 1px;
              width: 51px;
              background-color: #978E76;
              margin: 0 auto; }
            .slider-section-bottom .slider-container .grid-col .slider-bottom .slide .slider-content-text .subtext {
              padding-left: 130px;
              padding-right: 130px;
              font-size: 13px; }
            .slider-section-bottom .slider-container .grid-col .slider-bottom .slide .slider-content-text .slide-button {
              display: block;
              width: 100%;
              max-width: 250px;
              margin: 0 auto;
              height: 55px;
              background: #ffffff;
              line-height: 55px;
              text-transform: uppercase;
              text-decoration: none; }
          .slider-section-bottom .slider-container .grid-col .slider-bottom .slide .img-block {
            margin-top: 31px;
            width: 100%;
            height: 100%;
            max-width: 563px;
            max-height: 383px;
            position: absolute; }
            .slider-section-bottom .slider-container .grid-col .slider-bottom .slide .img-block img {
              width: 100%;
              max-width: 563px;
              z-index: 996;
              position: absolute;
              max-height: 383px; }
            .slider-section-bottom .slider-container .grid-col .slider-bottom .slide .img-block .border-line {
              position: absolute;
              width: 90%;
              height: 90%;
              border: 1px solid #fff;
              z-index: 997;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%); }
    .slider-section-bottom .slider-container .slick-prev,
    .slider-section-bottom .slider-container .slick-next {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 0;
      transform: translate(50%, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
      z-index: 999; }
    .slider-section-bottom .slider-container .slick-prev:before,
    .slider-section-bottom .slider-container .slick-next:before {
      display: block;
      position: absolute;
      font-family: 'slick';
      font-size: 30px;
      line-height: 1;
      color: #978E76;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .slider-section-bottom .slider-container .slick-prev {
      left: 0; }
      .slider-section-bottom .slider-container .slick-prev:before {
        content: ''; }
    .slider-section-bottom .slider-container [dir='rtl'] .slick-prev {
      right: 0;
      left: auto; }
      .slider-section-bottom .slider-container [dir='rtl'] .slick-prev:before {
        content: ''; }
    .slider-section-bottom .slider-container [dir='rtl'] .slick-next {
      right: auto;
      left: 0; }
      .slider-section-bottom .slider-container [dir='rtl'] .slick-next:before {
        content: ''; }
    .slider-section-bottom .slider-container .slick-next:before {
      content: ''; }
    .slider-section-bottom .slider-container .slick-next:after {
      content: "";
      font-family: "slick";
      font-size: 28px;
      display: block;
      color: #978E76;
      bottom: 0;
      left: 0;
      position: absolute; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 0px;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:after {
    clear: both; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  margin: 0 0px; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-initialized .slick-slide {
  display: block; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow {
  position: absolute;
  z-index: 99; }
  .slick-arrow.slick-hidden {
    display: none; }
  .slick-arrow .slick-prev:hover,
  .slick-arrow .slick-prev:focus,
  .slick-arrow .slick-next:hover,
  .slick-arrow .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent; }
  .slick-arrow .slick-prev:hover:before,
  .slick-arrow .slick-prev:focus:before,
  .slick-arrow .slick-next:hover:before,
  .slick-arrow .slick-next:focus:before {
    opacity: 1; }
  .slick-arrow .slick-dotted.slick-slider {
    margin-bottom: 30px; }
  .slick-arrow .slick-dots {
    position: absolute;
    bottom: -20px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 0 0 0;
    list-style: none;
    text-align: center; }
    .slick-arrow .slick-dots li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      .slick-arrow .slick-dots li button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent; }
        .slick-arrow .slick-dots li button:before {
          font-family: 'slick';
          font-size: 6px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          content: '•';
          text-align: center;
          opacity: .25;
          color: black;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
      .slick-arrow .slick-dots li.slick-active button:before {
        opacity: .75;
        color: black; }
  .slick-arrow .slick-dots li button:hover,
  .slick-arrow .slick-dots li button:focus {
    outline: none; }
  .slick-arrow .slick-dots li button:hover:before,
  .slick-arrow .slick-dots li button:focus:before {
    opacity: 1; }

.wt-title-product-catalog {
  display: block;
  line-height: 33px;
  font-family: 'PFDC';
  font-size: 35px;
  font-weight: 700;
  text-align: center; }
  .wt-title-product-catalog sub {
    font-size: 25px; }
    @media (min-width: 768px) {
      .wt-title-product-catalog sub {
        font-size: 60px; } }
  @media (min-width: 768px) {
    .wt-title-product-catalog {
      text-align: left;
      font-size: 60px;
      line-height: 60px; } }

.wt-product-catalog {
  width: 100%;
  display: inline-flex; }
  .wt-product-catalog .wt-main-grid {
    display: grid;
    row-gap: 20px;
    width: 100%; }
    @media (min-width: 768px) {
      .wt-product-catalog .wt-main-grid {
        grid-template-columns: 1fr 230px;
        grid-auto-rows: min-content; } }
    .wt-product-catalog .wt-main-grid .wt-title {
      display: block;
      line-height: 33px;
      font-family: 'PFDC';
      font-size: 35px;
      font-weight: 700;
      text-align: center; }
      @media (min-width: 768px) {
        .wt-product-catalog .wt-main-grid .wt-title {
          text-align: left;
          font-size: 60px; } }
      @media (min-width: 1025px) {
        .wt-product-catalog .wt-main-grid .wt-title {
          line-height: 60px; } }
    .wt-product-catalog .wt-main-grid .wt-filters {
      display: block;
      line-height: 45px;
      font-size: 16px;
      max-width: 300px;
      width: 100%;
      margin: 0 auto;
      color: #ffffff;
      font-weight: 500;
      text-align: center;
      background-color: #1e8219;
      border-radius: 5px; }
      @media (min-width: 768px) {
        .wt-product-catalog .wt-main-grid .wt-filters {
          grid-column: 2;
          grid-row: 1; } }
      @media (min-width: 1025px) {
        .wt-product-catalog .wt-main-grid .wt-filters {
          display: none; } }
    .wt-product-catalog .wt-main-grid .wt-products {
      display: grid;
      grid-column: 1/-1;
      grid-template-columns: repeat(1, minmax(240px, 425px));
      justify-content: space-around;
      column-gap: 20px;
      row-gap: 30px; }
      @media (min-width: 768px) {
        .wt-product-catalog .wt-main-grid .wt-products {
          grid-template-columns: repeat(3, minmax(240px, 425px));
          justify-content: space-between; } }
      @media (min-width: 868px) and (max-width: 1025px) {
        .wt-product-catalog .wt-main-grid .wt-products {
          justify-content: space-around; } }
      .wt-product-catalog .wt-main-grid .wt-products .wt-item {
        position: relative;
        z-index: 1;
        color: black;
        width: 100%; }
        .wt-product-catalog .wt-main-grid .wt-products .wt-item .wt-background {
          position: absolute;
          display: block;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #f4f4f4;
          border-radius: 8px;
          transform: scale3d(1, 0.9, 1); }
        .wt-product-catalog .wt-main-grid .wt-products .wt-item .wt-link-wrap {
          display: block;
          box-sizing: border-box;
          padding: 0 10.7%;
          text-decoration: none;
          color: unset; }
          .wt-product-catalog .wt-main-grid .wt-products .wt-item .wt-link-wrap .wt-image {
            display: block;
            margin: 0 auto 0 auto;
            border-radius: 5px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden; }
            .wt-product-catalog .wt-main-grid .wt-products .wt-item .wt-link-wrap .wt-image img {
              display: block;
              width: 100%; }
          .wt-product-catalog .wt-main-grid .wt-products .wt-item .wt-link-wrap .wt-item-name {
            display: block;
            min-height: 60px;
            margin: 0 auto;
            margin-top: 10px;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            text-align: center; }
          .wt-product-catalog .wt-main-grid .wt-products .wt-item .wt-link-wrap .wt-order {
            display: block;
            margin: 7px auto;
            font-size: 18px;
            line-height: 26px;
            font-family: 'PFD';
            font-weight: 300;
            text-align: center; }
          .wt-product-catalog .wt-main-grid .wt-products .wt-item .wt-link-wrap .wt-order-type {
            display: block;
            margin: 0 auto 0 auto;
            font-size: 18px;
            line-height: 30px;
            font-family: 'PFD';
            font-weight: 300;
            text-align: center; }
          .wt-product-catalog .wt-main-grid .wt-products .wt-item .wt-link-wrap .wt-price {
            display: block;
            margin: 0 auto 0 auto;
            font-size: 18px;
            line-height: 53px;
            font-family: 'PFD';
            font-weight: 300;
            text-align: center; }
            .wt-product-catalog .wt-main-grid .wt-products .wt-item .wt-link-wrap .wt-price b {
              color: #1e8219;
              font-family: 'PFDC';
              font-weight: 700; }
        .wt-product-catalog .wt-main-grid .wt-products .wt-item .wt-line {
          display: block;
          position: relative;
          height: 3px;
          background-color: white;
          top: -50px; }
        .wt-product-catalog .wt-main-grid .wt-products .wt-item .wt-button {
          display: block;
          position: relative;
          top: -8px;
          margin: 0 auto;
          width: 180px;
          height: 45px;
          line-height: 48px;
          cursor: pointer;
          background-color: #1e8219;
          border-radius: 5px;
          color: #ffffff;
          font-weight: 500;
          font-size: 16px;
          text-align: center;
          text-decoration: none; }
    .wt-product-catalog .wt-main-grid .wt-catalog-seo-text {
      grid-column: 1/-1; }
      .wt-product-catalog .wt-main-grid .wt-catalog-seo-text .wt-title {
        display: block;
        line-height: 33px;
        font-family: 'PFDC';
        font-size: 35px;
        font-weight: 700;
        text-align: center; }
        @media (min-width: 768px) {
          .wt-product-catalog .wt-main-grid .wt-catalog-seo-text .wt-title {
            text-align: left;
            font-size: 60px; } }
        @media (min-width: 1025px) {
          .wt-product-catalog .wt-main-grid .wt-catalog-seo-text .wt-title {
            line-height: 60px; } }
      .wt-product-catalog .wt-main-grid .wt-catalog-seo-text .wt-text {
        font-family: 'PFDC';
        text-align: center;
        font-weight: 700;
        font-size: 18px; }
        @media (min-width: 768px) {
          .wt-product-catalog .wt-main-grid .wt-catalog-seo-text .wt-text {
            text-align: left; } }

.wt-side-menu-grid {
  margin: 20px 0;
  display: block;
  justify-content: space-between;
  gap: 20px; }
  @media (min-width: 1025px) {
    .wt-side-menu-grid {
      display: grid;
      grid-template-columns: 280px minmax(0, 880px); } }
  .wt-side-menu-grid .wt-side-menu {
    grid-column: 1;
    grid-row: 1/3;
    display: none; }
    @media (min-width: 1025px) {
      .wt-side-menu-grid .wt-side-menu {
        display: block; } }
    .wt-side-menu-grid .wt-side-menu .wt-menu {
      background-color: #f4f4f4;
      border-radius: 5px;
      overflow: hidden;
      box-sizing: border-box; }
      .wt-side-menu-grid .wt-side-menu .wt-menu .wt-title {
        display: block;
        height: 45px;
        background-color: #1e8219;
        border-radius: 5px;
        padding: 0 0 0 20px;
        font-size: 24px;
        font-weight: 500;
        text-align: left;
        line-height: 50px;
        color: #ffffff; }
      .wt-side-menu-grid .wt-side-menu .wt-menu .wt-filters {
        list-style: none;
        padding: 0 20px 0 20px; }
        .wt-side-menu-grid .wt-side-menu .wt-menu .wt-filters .wt-item-menu {
          line-height: 41px;
          font-size: 18px;
          font-family: 'PFD';
          font-weight: 300;
          cursor: pointer; }
          .wt-side-menu-grid .wt-side-menu .wt-menu .wt-filters .wt-item-menu span {
            position: relative; }
            .wt-side-menu-grid .wt-side-menu .wt-menu .wt-filters .wt-item-menu span::before {
              content: '';
              position: absolute;
              top: -1px;
              left: -20px;
              width: 0;
              height: 100%;
              border-right: 2px solid #1e8219; }
            .wt-side-menu-grid .wt-side-menu .wt-menu .wt-filters .wt-item-menu span a {
              display: block;
              width: 100%;
              height: 100%;
              color: unset;
              text-decoration: none; }
          .wt-side-menu-grid .wt-side-menu .wt-menu .wt-filters .wt-item-menu ul {
            display: none;
            list-style: none;
            padding: 0 0 0 20px; }
            .wt-side-menu-grid .wt-side-menu .wt-menu .wt-filters .wt-item-menu ul li a {
              display: block;
              width: 100%;
              height: 100%;
              text-decoration: none;
              color: #373737; }
        .wt-side-menu-grid .wt-side-menu .wt-menu .wt-filters .opened ul {
          display: block; }
    .wt-side-menu-grid .wt-side-menu .wt-show-more {
      font-size: 18px;
      font-weight: 500;
      color: #1e8219;
      line-height: 26px;
      padding: 0 0 0 20px; }
  .wt-side-menu-grid .wt-contacts {
    margin: 20px 0 0 0;
    background-color: #f4f4f4;
    border-radius: 5px;
    overflow: hidden;
    padding: 0 0 20px 0; }
    .wt-side-menu-grid .wt-contacts .wt-title {
      display: block;
      height: 45px;
      background-color: #1e8219;
      border-radius: 5px;
      padding: 0 0 0 20px;
      font-size: 24px;
      font-weight: 500;
      text-align: left;
      line-height: 50px;
      color: #ffffff;
      margin: 0 0 20px 0; }
    .wt-side-menu-grid .wt-contacts .wt-list-contacts {
      font-size: 18px;
      font-family: 'PFDC';
      font-weight: 700;
      line-height: 26px;
      list-style: none;
      padding: 0 20px 0 20px; }
      .wt-side-menu-grid .wt-contacts .wt-list-contacts li {
        margin: 20px 0; }
      .wt-side-menu-grid .wt-contacts .wt-list-contacts .wt-item-contact p {
        font-family: 'PFD';
        font-size: 18px;
        font-weight: 300;
        line-height: 26px; }
        .wt-side-menu-grid .wt-contacts .wt-list-contacts .wt-item-contact p span {
          font-family: 'PFDC';
          font-weight: 700; }
      .wt-side-menu-grid .wt-contacts .wt-list-contacts .wt-item-contact a {
        text-decoration: none;
        color: #1e8219;
        font-weight: 500; }
      .wt-side-menu-grid .wt-contacts .wt-list-contacts .wt-item-contact .wt-tel {
        margin-top: 10px; }
        .wt-side-menu-grid .wt-contacts .wt-list-contacts .wt-item-contact .wt-tel span {
          color: #1e8219; }
      .wt-side-menu-grid .wt-contacts .wt-list-contacts .wt-item-contact .wt-side-menu-description {
        margin-top: 10px; }
      .wt-side-menu-grid .wt-contacts .wt-list-contacts .wt-item-contact .wt-adress {
        position: relative; }
        .wt-side-menu-grid .wt-contacts .wt-list-contacts .wt-item-contact .wt-adress span {
          position: absolute;
          color: #1e8219;
          left: 55px; }

.wt-side-menu-grid .wt-mobile-filters {
  display: none;
  position: fixed;
  background-color: #ffffff;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  width: 100vw;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(-20px);
  transition: 0.2s;
  overflow-x: scroll; }
  .wt-side-menu-grid .wt-mobile-filters .wt-close {
    display: block;
    background-image: url("../img/close-1.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 25px;
    width: 25px; }
  .wt-side-menu-grid .wt-mobile-filters .wt-filters {
    margin: 20px 0 0 0;
    list-style: none;
    font-size: 20px;
    font-family: 'PFDC';
    font-weight: 700; }
    .wt-side-menu-grid .wt-mobile-filters .wt-filters a {
      color: #373737;
      text-decoration: none; }
    .wt-side-menu-grid .wt-mobile-filters .wt-filters .wt-item-filters {
      position: relative;
      font-size: 18px;
      font-weight: 400;
      padding: 0 0 0 0px;
      box-sizing: border-box;
      line-height: 30px; }

.wt-side-menu-grid .wt-opened-mobile-filters {
  display: block;
  opacity: 1;
  transform: translateY(0px);
  transition: .2s; }

.wt-card-item {
  display: grid;
  gap: 20px;
  width: 300px;
  grid-template-columns: 300px;
  margin: 0 auto;
  margin-bottom: 70px; }
  @media (min-width: 455px) {
    .wt-card-item {
      width: 400px;
      grid-template-columns: 400px; } }
  @media (min-width: 768px) {
    .wt-card-item {
      grid-template-columns: 300px 1fr;
      width: unset; } }
  @media (min-width: 1025px) {
    .wt-card-item {
      width: 100%;
      grid-template-rows: auto auto 1fr;
      margin-bottom: 0; } }
  .wt-card-item .wt-item-slider-wrap {
    overflow: hidden;
    position: relative;
    display: grid;
    grid-template-rows: 45px 80px 402px;
    gap: 20px;
    width: 300px; }
    @media (min-width: 455px) {
      .wt-card-item .wt-item-slider-wrap {
        width: 400px;
        grid-template-columns: 400px; } }
    @media (min-width: 768px) {
      .wt-card-item .wt-item-slider-wrap {
        grid-template-rows: 381px 45px;
        width: 300px;
        grid-template-columns: unset; } }
    @media (min-width: 1025px) {
      .wt-card-item .wt-item-slider-wrap {
        grid-template-rows: 381px; } }
    .wt-card-item .wt-item-slider-wrap .slick-next, .wt-card-item .wt-item-slider-wrap .slick-prev {
      font-size: 0;
      top: 50%;
      color: rgba(255, 255, 255, 0.53);
      transform: translate(-50%, -50%); }
    .wt-card-item .wt-item-slider-wrap .slick-next {
      right: 0px;
      transform: translate(-50%, -50%);
      background: none;
      border: none;
      color: transparent;
      background-image: url("../img/arrow.svg");
      background-position: center;
      background-size: cover;
      height: 45px;
      width: 20px; }
    .wt-card-item .wt-item-slider-wrap .slick-prev {
      left: 20px;
      background: none;
      border: none;
      color: transparent;
      background-image: url("../img/arrow.svg");
      background-position: center;
      background-size: cover;
      transform: translate(-50%, -50%) scale(-1, 1);
      height: 45px;
      width: 20px; }
    .wt-card-item .wt-item-slider-wrap .wt-filters {
      display: block;
      line-height: 45px;
      font-size: 16px;
      max-width: 300px;
      width: 100%;
      margin: 0 auto;
      color: #ffffff;
      font-weight: 500;
      text-align: center;
      background-color: #1e8219;
      border-radius: 5px; }
      @media (min-width: 768px) {
        .wt-card-item .wt-item-slider-wrap .wt-filters {
          grid-row: 2; } }
      @media (min-width: 1025px) {
        .wt-card-item .wt-item-slider-wrap .wt-filters {
          display: none; } }
    .wt-card-item .wt-item-slider-wrap .wt-title {
      font-family: 'PFDC';
      font-size: 35px;
      font-weight: 700;
      line-height: 40px; }
      @media (min-width: 768px) {
        .wt-card-item .wt-item-slider-wrap .wt-title {
          display: none; } }
    .wt-card-item .wt-item-slider-wrap .wt-wrap {
      width: 100%; }
      @media (min-width: 768px) {
        .wt-card-item .wt-item-slider-wrap .wt-wrap {
          grid-row: 1; } }
      .wt-card-item .wt-item-slider-wrap .wt-wrap .wt-item-slider {
        width: 300px;
        height: 320px;
        margin: 0 0 12px 0; }
        @media (min-width: 455px) {
          .wt-card-item .wt-item-slider-wrap .wt-wrap .wt-item-slider {
            width: 400px; } }
        @media (min-width: 768px) {
          .wt-card-item .wt-item-slider-wrap .wt-wrap .wt-item-slider {
            width: 300px;
            height: 300px; } }
        .wt-card-item .wt-item-slider-wrap .wt-wrap .wt-item-slider img {
          height: 320px;
          border-radius: 5px; }
          @media (min-width: 455px) {
            .wt-card-item .wt-item-slider-wrap .wt-wrap .wt-item-slider img {
              width: 400px; } }
          @media (min-width: 768px) {
            .wt-card-item .wt-item-slider-wrap .wt-wrap .wt-item-slider img {
              width: 300px;
              height: 300px; } }
        .wt-card-item .wt-item-slider-wrap .wt-wrap .wt-item-slider .slick-list {
          margin: 0 0; }
        .wt-card-item .wt-item-slider-wrap .wt-wrap .wt-item-slider .slick-slide {
          margin: 0 0; }
      .wt-card-item .wt-item-slider-wrap .wt-wrap .wt-slider-dots {
        position: relative;
        width: 300px;
        height: 60px; }
        .wt-card-item .wt-item-slider-wrap .wt-wrap .wt-slider-dots img {
          height: 70px;
          width: 80px;
          border-radius: 5px; }
        .wt-card-item .wt-item-slider-wrap .wt-wrap .wt-slider-dots .slick-list {
          margin: 0 -10px; }
        .wt-card-item .wt-item-slider-wrap .wt-wrap .wt-slider-dots .slick-slide {
          margin: 0 10px; }
  .wt-card-item .wt-order-item {
    display: grid;
    gap: 20px; }
    @media (min-width: 768px) {
      .wt-card-item .wt-order-item {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 118px 20px 20px 20px auto-10px; } }
    @media (min-width: 1190px) {
      .wt-card-item .wt-order-item {
        grid-template-rows: 118px 20px 20px 20px auto; } }
    .wt-card-item .wt-order-item .wt-title {
      display: none;
      position: relative;
      top: -4px;
      font-family: 'PFDC';
      font-size: 60px;
      font-weight: 700;
      line-height: 70px; }
      @media (min-width: 768px) {
        .wt-card-item .wt-order-item .wt-title {
          display: unset;
          grid-column: 1/-1; } }
      @media (min-width: 1025px) {
        .wt-card-item .wt-order-item .wt-title {
          font-size: 55px;
          line-height: 60px; } }
      @media (min-width: 1190px) {
        .wt-card-item .wt-order-item .wt-title {
          font-size: 60px;
          line-height: 70px; } }
    .wt-card-item .wt-order-item .wt-order-type {
      font-size: 18px;
      font-family: 'PFDC';
      font-weight: 700;
      line-height: 13px; }
      @media (min-width: 768px) {
        .wt-card-item .wt-order-item .wt-order-type {
          grid-row: 2;
          grid-column: 1; } }
    .wt-card-item .wt-order-item .wt-price {
      font-size: 18px;
      font-family: 'PFD';
      font-weight: 300;
      line-height: 13px; }
      @media (min-width: 768px) {
        .wt-card-item .wt-order-item .wt-price {
          grid-row: 3;
          grid-column: 1; } }
      .wt-card-item .wt-order-item .wt-price span {
        color: #1e8219;
        font-size: 20px;
        font-family: 'PFDC';
        font-weight: 700; }
    .wt-card-item .wt-order-item .wt-country {
      font-size: 18px;
      font-family: 'PFD';
      font-weight: 300;
      line-height: 13px; }
      @media (min-width: 768px) {
        .wt-card-item .wt-order-item .wt-country {
          grid-row: 4;
          grid-column: 1/-1; } }
      .wt-card-item .wt-order-item .wt-country span {
        font-family: 'PFDC';
        font-weight: 700; }
    .wt-card-item .wt-order-item .wt-buy {
      display: block;
      background-color: #1e8219;
      border-radius: 5px;
      text-align: center;
      width: 100%;
      height: 45px;
      line-height: 46px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer; }
      @media (min-width: 768px) {
        .wt-card-item .wt-order-item .wt-buy {
          grid-row: 2/4;
          grid-column: 2; } }
    .wt-card-item .wt-order-item .wt-payment {
      display: block;
      width: 100%;
      background-color: #f4f4f4;
      border-radius: 5px;
      padding: 15px 22px;
      box-sizing: border-box; }
      .wt-card-item .wt-order-item .wt-payment .wt-title {
        font-size: 20px;
        font-family: 'PFDC';
        font-weight: 700;
        line-height: 26px; }
      .wt-card-item .wt-order-item .wt-payment p {
        font-size: 18px;
        font-family: 'PFD';
        font-weight: 300;
        line-height: 26px; }
      .wt-card-item .wt-order-item .wt-payment a {
        font-size: 18px;
        font-weight: 500;
        color: #1e8219;
        text-decoration: none; }
    .wt-card-item .wt-order-item .wt-delivery {
      display: block;
      width: 100%;
      background-color: #f4f4f4;
      border-radius: 5px;
      padding: 15px 22px;
      box-sizing: border-box; }
      .wt-card-item .wt-order-item .wt-delivery .wt-title {
        font-size: 20px;
        font-family: 'PFDC';
        font-weight: 700;
        line-height: 26px; }
      .wt-card-item .wt-order-item .wt-delivery p {
        font-size: 18px;
        font-family: 'PFD';
        font-weight: 300;
        line-height: 26px; }
      .wt-card-item .wt-order-item .wt-delivery a {
        font-size: 18px;
        font-weight: 500;
        color: #1e8219;
        text-decoration: none; }
  .wt-card-item .wt-item-description {
    width: 100%; }
    @media (min-width: 768px) {
      .wt-card-item .wt-item-description {
        grid-column: 1/-1; } }
    .wt-card-item .wt-item-description .wt-select-tab {
      display: grid;
      grid-template-columns: 30% 40% 30%;
      justify-content: space-between;
      height: 35px;
      list-style: none;
      background-color: #f4f4f4;
      border-radius: 5px; }
      .wt-card-item .wt-item-description .wt-select-tab .wt-tab-button {
        text-align: center;
        font-size: 18px;
        font-family: 'PFDC';
        font-weight: 700;
        line-height: 30px;
        padding: 5px 0 0 0;
        box-sizing: border-box; }
      .wt-card-item .wt-item-description .wt-select-tab .wt-selected {
        background-color: #1e8219;
        border-radius: 5px;
        color: #ffffff; }
    .wt-card-item .wt-item-description .tab-content {
      display: block;
      visibility: collapse;
      height: 0;
      padding: 0 0 0 0;
      box-sizing: border-box; }
      .wt-card-item .wt-item-description .tab-content .wt-title {
        font-size: 18px;
        font-family: 'PFDC';
        font-weight: 700;
        line-height: 26px;
        margin-bottom: 10px; }
      .wt-card-item .wt-item-description .tab-content .wt-description {
        font-size: 18px;
        font-family: 'PFD';
        font-weight: 300;
        line-height: 26px; }
      .wt-card-item .wt-item-description .tab-content .wt-list {
        font-size: 18px;
        font-family: 'PFD';
        font-weight: 300;
        line-height: 26px;
        list-style: none; }
        .wt-card-item .wt-item-description .tab-content .wt-list .wt-length-list {
          padding: 0 0 0 20px;
          position: relative; }
          .wt-card-item .wt-item-description .tab-content .wt-list .wt-length-list::before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 0;
            height: 23px;
            border-right: 2px solid #1e8219; }
          .wt-card-item .wt-item-description .tab-content .wt-list .wt-length-list ul {
            list-style: none; }
            .wt-card-item .wt-item-description .tab-content .wt-list .wt-length-list ul li {
              display: inline-block;
              padding: 0;
              margin: 0; }
    .wt-card-item .wt-item-description .wt-selected {
      height: auto;
      visibility: unset;
      padding: 10px 0 0 0; }
  @media (min-width: 768px) {
    .wt-card-item .wt-product-catalog {
      grid-column: 1/-1; } }
  .wt-card-item .wt-product-catalog .wt-main-grid .wt-filters {
    display: none; }

.wt-map {
  width: 100%;
  display: block;
  margin-bottom: 40px; }
  @media (min-width: 1025px) {
    .wt-map {
      margin-bottom: 103px; } }
  .wt-map .wt-map-container {
    margin: 0 auto;
    width: 100%; }
    @media (min-width: 768px) {
      .wt-map .wt-map-container {
        display: flex; } }
    .wt-map .wt-map-container .wt-text-col {
      width: 100%; }
      @media (min-width: 768px) {
        .wt-map .wt-map-container .wt-text-col {
          width: 333px;
          min-height: 692px;
          position: relative; } }
      @media (min-width: 1025px) {
        .wt-map .wt-map-container .wt-text-col {
          width: 100%; } }
      .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap {
        position: relative;
        padding: 40px 10px 20px 10px;
        box-sizing: border-box;
        background-color: transparent; }
        @media (min-width: 768px) {
          .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap {
            width: 480px;
            position: unset;
            padding: 40px 0px 30px 20px; } }
        @media (min-width: 1025px) {
          .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap {
            margin: 0 125px 0 auto;
            padding: 70px 0 70px 10px; } }
        .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-green-cont {
          background-color: #1e8219;
          position: absolute;
          height: 100%;
          width: 100%;
          z-index: 2;
          top: 0;
          left: 0; }
          @media (min-width: 1025px) {
            .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-green-cont {
              left: unset;
              right: 200px; } }
        .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-text-map-heading {
          position: relative;
          z-index: 3; }
          .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-text-map-heading .wt-border-title {
            display: block;
            height: 35px;
            font-size: 35px;
            font-family: 'PFDC';
            font-weight: 700;
            color: transparent;
            line-height: 29px;
            -webkit-text-stroke-color: #ffffff;
            -webkit-text-stroke-width: 1.00px; }
            @media (min-width: 768px) {
              .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-text-map-heading .wt-border-title {
                height: 54px;
                font-size: 70px;
                line-height: 70px; } }
            @media (min-width: 1025px) {
              .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-text-map-heading .wt-border-title {
                height: 74px;
                font-size: 100px;
                line-height: 100px; } }
          .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-text-map-heading .wt-title {
            position: relative;
            font-size: 35px;
            font-family: 'PFDC';
            font-weight: 700;
            color: #ffffff;
            line-height: 30px;
            top: 10px;
            left: 2px;
            margin: 0 0 20px 0; }
            @media (min-width: 768px) {
              .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-text-map-heading .wt-title {
                font-size: 60px;
                line-height: 0px;
                margin: 0 0 55px 0; } }
            @media (min-width: 1025px) {
              .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-text-map-heading .wt-title {
                font-size: 80px;
                line-height: 0px;
                margin: 0 0 55px 0; } }
        .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact {
          width: 100%;
          box-sizing: border-box;
          padding: 20px 10px;
          background-color: #ffffff;
          border-radius: 7px;
          position: relative;
          z-index: 3; }
          @media (min-width: 768px) {
            .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact {
              width: 420px; } }
          @media (min-width: 768px) {
            .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact {
              width: 480px; } }
          .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact p {
            margin: 0;
            line-height: 40px; }
          .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-phone {
            padding: 0 0 0 10px;
            box-sizing: border-box; }
            @media (min-width: 768px) {
              .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-phone {
                padding: 0 0 0 20px; } }
            .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-phone a {
              font-size: 20px;
              font-family: 'PFDC';
              font-weight: 700;
              color: #373737;
              line-height: 40px;
              text-decoration: none; }
            .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-phone .wt-title {
              font-size: 20px;
              font-weight: 500; }
          .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-email {
            padding: 0 0 0 10px;
            box-sizing: border-box; }
            @media (min-width: 768px) {
              .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-email {
                padding: 0 0 0 20px; } }
            .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-email .wt-title {
              font-size: 20px;
              font-weight: 500;
              color: #373737;
              line-height: 40px; }
            .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-email a {
              color: #1e8219;
              text-decoration: none;
              line-height: 40px; }
          .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-site {
            padding: 0 0 0 10px;
            box-sizing: border-box; }
            @media (min-width: 768px) {
              .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-site {
                padding: 0 0 0 20px; } }
            .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-site .wt-title {
              font-size: 20px;
              font-weight: 500;
              color: #373737; }
            .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-site a {
              color: #1e8219;
              text-decoration: none;
              line-height: 40px; }
          .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-adress {
            padding: 0 0 0 10px;
            box-sizing: border-box; }
            @media (min-width: 768px) {
              .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-adress {
                padding: 0 0 0 20px; } }
            .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-adress p {
              font-size: 18px;
              font-weight: 400;
              color: #373737; }
            .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-adress .wt-title {
              font-size: 20px;
              font-weight: 500; }
            .wt-map .wt-map-container .wt-text-col .wt-ifo-wrap .wt-white-contact .wt-adress a {
              color: #1e8219;
              font-size: 18px;
              font-weight: 500;
              text-decoration: none; }
    .wt-map .wt-map-container .wt-map-col {
      width: 100%;
      z-index: 1; }
      @media (min-width: 768px) {
        .wt-map .wt-map-container .wt-map-col {
          height: 0; } }
      .wt-map .wt-map-container .wt-map-col .wt-map-container {
        width: 100%;
        display: block; }
        @media (min-width: 768px) {
          .wt-map .wt-map-container .wt-map-col .wt-map-container {
            position: relative;
            top: -38px; } }
        @media (min-width: 1025px) {
          .wt-map .wt-map-container .wt-map-col .wt-map-container {
            top: -105px;
            width: calc(100% + 300px);
            left: -300px; } }
        .wt-map .wt-map-container .wt-map-col .wt-map-container .wt-map-google {
          width: 100%;
          height: 350px; }
          @media (min-width: 768px) {
            .wt-map .wt-map-container .wt-map-col .wt-map-container .wt-map-google {
              height: 640px; } }
          @media (min-width: 1025px) {
            .wt-map .wt-map-container .wt-map-col .wt-map-container .wt-map-google {
              height: 860px; } }

.wt-about-company-short {
  margin: 0 0 40px 0; }
  @media (min-width: 768px) {
    .wt-about-company-short {
      margin: 0 0 70px 0; } }
  @media (min-width: 1190px) {
    .wt-about-company-short {
      margin: 0 0 150px 0; } }
  .wt-about-company-short .wt-info-flex-container {
    position: relative;
    display: grid;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    grid-template-columns: 300px;
    justify-content: space-around;
    gap: 20px; }
    @media (min-width: 768px) {
      .wt-about-company-short .wt-info-flex-container {
        grid-template-columns: 416px 291px;
        justify-content: center; } }
    @media (min-width: 1190px) {
      .wt-about-company-short .wt-info-flex-container {
        grid-template-columns: 580px 480px;
        column-gap: 120px;
        row-gap: 20px; } }
    @media (min-width: 768px) {
      .wt-about-company-short .wt-info-flex-container .wt-text-map-heading {
        grid-column: 1; } }
    @media (min-width: 1190px) {
      .wt-about-company-short .wt-info-flex-container .wt-text-map-heading {
        height: 192px;
        margin-bottom: 60px; } }
    .wt-about-company-short .wt-info-flex-container .wt-text-map-heading .wt-border-title {
      display: block;
      height: 28px;
      font-size: 35px;
      font-family: 'PFDC';
      font-weight: 700;
      color: transparent;
      line-height: 36px;
      -webkit-text-stroke-color: #373737;
      -webkit-text-stroke-width: 1.00px; }
      @media (min-width: 768px) {
        .wt-about-company-short .wt-info-flex-container .wt-text-map-heading .wt-border-title {
          font-size: 70px;
          height: 52px;
          line-height: 65px; } }
      @media (min-width: 1190px) {
        .wt-about-company-short .wt-info-flex-container .wt-text-map-heading .wt-border-title {
          font-size: 100px;
          height: 72px;
          line-height: 90px; } }
    .wt-about-company-short .wt-info-flex-container .wt-text-map-heading .wt-title {
      position: relative;
      font-size: 35px;
      font-family: 'PFDC';
      font-weight: 700;
      color: #373737;
      line-height: 45px;
      top: 10px; }
      @media (min-width: 768px) {
        .wt-about-company-short .wt-info-flex-container .wt-text-map-heading .wt-title {
          line-height: 100%;
          font-size: 60px;
          height: 81px;
          top: -24px;
          margin-bottom: 50px; } }
      @media (min-width: 1190px) {
        .wt-about-company-short .wt-info-flex-container .wt-text-map-heading .wt-title {
          font-size: 80px;
          height: 72px;
          line-height: 90px;
          top: -36px;
          margin-bottom: unset; } }
    .wt-about-company-short .wt-info-flex-container .wt-image-wrap {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 5px;
      height: 271px;
      width: 100%; }
      @media (min-width: 768px) {
        .wt-about-company-short .wt-info-flex-container .wt-image-wrap {
          grid-column: 2;
          grid-row: 1/4;
          height: 100%; } }
    .wt-about-company-short .wt-info-flex-container .wt-direct {
      background-color: #ffffff;
      border-radius: 5px;
      box-sizing: border-box;
      padding: 20px 10px 20px 8px; }
      @media (min-width: 768px) {
        .wt-about-company-short .wt-info-flex-container .wt-direct {
          grid-column: 1;
          padding: 20px 40px 20px 29px; } }
      @media (min-width: 1190px) {
        .wt-about-company-short .wt-info-flex-container .wt-direct {
          padding: 33px 40px 30px 29px; } }
      .wt-about-company-short .wt-info-flex-container .wt-direct div {
        border-left: 2px solid #1e8219;
        box-sizing: border-box;
        padding-left: 10px; }
        @media (min-width: 768px) {
          .wt-about-company-short .wt-info-flex-container .wt-direct div {
            padding-left: 20px; } }
        .wt-about-company-short .wt-info-flex-container .wt-direct div .wt-name {
          font-size: 20px;
          font-weight: 500; }
        .wt-about-company-short .wt-info-flex-container .wt-direct div .wt-text {
          font-size: 18px;
          font-weight: 400;
          line-height: 26px; }
    .wt-about-company-short .wt-info-flex-container .wt-info {
      padding: 20px 10px 20px 8px;
      box-sizing: border-box;
      background-color: #ffffff;
      border-radius: 5px; }
      @media (min-width: 768px) {
        .wt-about-company-short .wt-info-flex-container .wt-info {
          grid-column: 1;
          padding: 20px 10px 20px 29px; } }
      @media (min-width: 1190px) {
        .wt-about-company-short .wt-info-flex-container .wt-info {
          padding: 33px 10px 30px 29px; } }
      .wt-about-company-short .wt-info-flex-container .wt-info ul {
        list-style: none;
        display: grid;
        gap: 25px; }
        @media (min-width: 768px) {
          .wt-about-company-short .wt-info-flex-container .wt-info ul {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-auto-flow: column; } }
        .wt-about-company-short .wt-info-flex-container .wt-info ul li a {
          position: relative;
          display: block;
          height: 16px;
          line-height: 20px;
          padding-left: 10px;
          font-size: 20px;
          font-weight: 500;
          text-decoration: none;
          color: #1e8219; }
          @media (min-width: 768px) {
            .wt-about-company-short .wt-info-flex-container .wt-info ul li a {
              padding-left: 20px; } }
          .wt-about-company-short .wt-info-flex-container .wt-info ul li a::before {
            content: '';
            display: block;
            position: absolute;
            height: 16px;
            width: 0;
            border-left: 2px solid #1e8219;
            left: 0;
            top: 0; }
    .wt-about-company-short .wt-info-flex-container .wt-gray-back {
      position: absolute;
      background-color: #f4f4f4;
      height: calc(100% - 83px);
      width: 100%;
      bottom: -40px;
      left: 0;
      z-index: -2; }
      @media (min-width: 768px) {
        .wt-about-company-short .wt-info-flex-container .wt-gray-back {
          bottom: -30px;
          height: calc(100% - 143px); } }
      @media (min-width: 1190px) {
        .wt-about-company-short .wt-info-flex-container .wt-gray-back {
          bottom: -40px;
          height: calc(100% - 190px); } }
    .wt-about-company-short .wt-info-flex-container .wt-green-back {
      position: absolute;
      background-color: #1e8219;
      height: calc(100% - 83px);
      width: calc(50% - 80px);
      top: 0;
      right: 0;
      z-index: -1; }
      @media (min-width: 768px) {
        .wt-about-company-short .wt-info-flex-container .wt-green-back {
          top: unset;
          bottom: -30px;
          height: calc(100% - 103px);
          width: calc(50% - 20px); } }
      @media (min-width: 1190px) {
        .wt-about-company-short .wt-info-flex-container .wt-green-back {
          bottom: -80px;
          height: calc(100% - 110px);
          width: calc(50% + 85px); } }

.wt-cta-container {
  background-color: #1f831a; }
  @media (min-width: 320px) {
    .wt-cta-container {
      display: block; } }
  @media (min-width: 768px) {
    .wt-cta-container {
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 1440px) {
    .wt-cta-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%; } }
  .wt-cta-container .image-col {
    width: 35%;
    position: relative;
    top: 4em;
    right: 22.3em;
    z-index: 0; }
    @media (min-width: 320px) {
      .wt-cta-container .image-col {
        display: none; } }
    @media (min-width: 768px) {
      .wt-cta-container .image-col {
        display: block; } }
    @media (min-width: 1440px) {
      .wt-cta-container .image-col {
        width: 50%;
        right: 0; } }
    .wt-cta-container .image-col img {
      width: 100%;
      height: auto; }
      @media (min-width: 768px) {
        .wt-cta-container .image-col img {
          display: block;
          width: 265%; } }
      @media (min-width: 1440px) {
        .wt-cta-container .image-col img {
          display: block;
          width: 100%; } }
  @media (min-width: 768px) {
    .wt-cta-container .form-text-col {
      width: 65%; } }
  @media (min-width: 1440px) {
    .wt-cta-container .form-text-col {
      width: 50%; } }
  .wt-cta-container .form-text-col .cta-heading {
    display: block;
    text-align: left;
    padding: 0 20px; }
    @media (min-width: 768px) {
      .wt-cta-container .form-text-col .cta-heading {
        padding: 0; } }
    @media (min-width: 1025px) {
      .wt-cta-container .form-text-col .cta-heading {
        font-size: 5em; } }
    .wt-cta-container .form-text-col .cta-heading .transparent-text {
      font-size: 2.18em;
      font-family: 'PFDC';
      font-weight: bold;
      color: transparent;
      -webkit-text-stroke-color: #ffffff;
      -webkit-text-stroke-width: 1px;
      position: relative;
      top: 32px;
      z-index: 0; }
      @media (min-width: 768px) {
        .wt-cta-container .form-text-col .cta-heading .transparent-text {
          font-size: 70px;
          line-height: 110px;
          -webkit-text-stroke-width: 1px;
          top: 18px;
          left: 2.3em;
          width: 4.4em; } }
      @media (min-width: 1440px) {
        .wt-cta-container .form-text-col .cta-heading .transparent-text {
          font-size: 100px;
          left: 0;
          width: 4.4em; } }
    .wt-cta-container .form-text-col .cta-heading .cta-subheading {
      font-weight: bold;
      font-family: 'PFDC';
      font-size: 2.18em;
      text-align: right;
      line-height: 40px;
      color: white;
      z-index: 1;
      position: relative;
      display: block; }
      @media (min-width: 768px) {
        .wt-cta-container .form-text-col .cta-heading .cta-subheading {
          font-size: 60px;
          line-height: 70px;
          right: 11px;
          bottom: .8em;
          width: 8em; } }
      @media (min-width: 1440px) {
        .wt-cta-container .form-text-col .cta-heading .cta-subheading {
          font-size: 80px;
          line-height: 80px;
          right: 0.2em;
          bottom: .8em;
          width: 8em; } }
  .wt-cta-container .form-text-col .cta-text-block {
    padding: 0 20px;
    z-index: 1; }
    @media (min-width: 768px) {
      .wt-cta-container .form-text-col .cta-text-block {
        text-align: inherit;
        position: relative;
        bottom: 3em;
        padding: 0px 45px 12px 0px; } }
    @media (min-width: 1440px) {
      .wt-cta-container .form-text-col .cta-text-block {
        padding: 0 15em 40px 0; } }
    .wt-cta-container .form-text-col .cta-text-block > p {
      letter-spacing: 0px;
      line-height: 26px;
      color: #ffffff;
      font-family: 'PFD';
      font-weight: 300;
      text-align: center;
      font-size: 18px; }
      @media (min-width: 455px) {
        .wt-cta-container .form-text-col .cta-text-block > p {
          text-align: inherit; } }
  .wt-cta-container .form-text-col .cta-mob-image {
    display: none; }
    .wt-cta-container .form-text-col .cta-mob-image img {
      width: 94%;
      height: auto; }
    @media (min-width: 320px) {
      .wt-cta-container .form-text-col .cta-mob-image {
        display: block; } }
    @media (min-width: 768px) {
      .wt-cta-container .form-text-col .cta-mob-image {
        display: none; } }
  .wt-cta-container .form-text-col .cta-form {
    width: 100%;
    max-width: 300px;
    background-color: #fff;
    display: block;
    margin: 0 auto;
    position: relative;
    bottom: 2.8em;
    border-radius: 5px 0px 0px 5px; }
    @media (min-width: 768px) {
      .wt-cta-container .form-text-col .cta-form {
        max-width: 100em; } }
    @media (min-width: 1440px) {
      .wt-cta-container .form-text-col .cta-form {
        max-width: 100em; } }
    .wt-cta-container .form-text-col .cta-form form fieldset {
      display: flex;
      flex-wrap: wrap; }
      @media (min-width: 768px) {
        .wt-cta-container .form-text-col .cta-form form fieldset {
          flex-wrap: nowrap; } }
      .wt-cta-container .form-text-col .cta-form form fieldset input {
        width: 250px;
        height: 45px;
        border-radius: 5px;
        background-color: #d2e6d1;
        margin: 20px auto;
        border: 1px solid #d2e6d1;
        box-sizing: border-box;
        padding: 0 0 0 12px;
        color: #373737;
        font-size: 15px; }
        @media (min-width: 768px) {
          .wt-cta-container .form-text-col .cta-form form fieldset input {
            width: 251px;
            height: 46px;
            margin: 30px 20px 30px 30px; } }
      .wt-cta-container .form-text-col .cta-form form fieldset button {
        width: 260px;
        height: 45px;
        border-radius: 5px;
        font-size: 16px;
        color: #ffffff;
        font-weight: 500;
        font-family: "PFD";
        text-align: center;
        background-color: #1e8219;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
        border: 1px solid #1e8219; }
        @media (min-width: 768px) {
          .wt-cta-container .form-text-col .cta-form form fieldset button {
            width: 181px;
            height: 46px;
            margin: 30px 20px 30px 0; } }

.wt-description {
  margin: 2em 0 70px 0;
  position: relative;
  display: none; }
  @media (min-width: 768px) {
    .wt-description {
      display: block;
      margin: 2em 0 140px 0; } }
  @media (min-width: 1025px) {
    .wt-description {
      margin: 2em 0 153px 0; } }
  .wt-description .company {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    margin: 40px 0 0 0; }
    @media (min-width: 768px) {
      .wt-description .company {
        flex-wrap: nowrap; } }
    @media (min-width: 1025px) {
      .wt-description .company {
        width: 100%;
        max-width: 1180px;
        margin: 0 auto;
        flex-wrap: nowrap; } }
    .wt-description .company .left-info {
      padding: 0 10px; }
      @media (min-width: 768px) {
        .wt-description .company .left-info {
          padding: 0 10px 0 20px;
          z-index: 2;
          position: relative;
          width: 50%;
          max-width: 375px;
          top: 10em; } }
      @media (min-width: 1025px) {
        .wt-description .company .left-info {
          max-width: 955px; } }
      .wt-description .company .left-info .wt-image-container {
        position: relative;
        overflow: hidden;
        width: auto;
        height: auto; }
        @media (min-width: 768px) {
          .wt-description .company .left-info .wt-image-container {
            background-position: 63% 91%;
            background-size: 48em;
            background-repeat: no-repeat;
            width: 334px;
            height: 495px;
            margin: 0 0 30px 0;
            position: relative;
            bottom: 10em; } }
        @media (min-width: 1025px) {
          .wt-description .company .left-info .wt-image-container {
            width: 480px; } }
        @media (min-width: 1080px) {
          .wt-description .company .left-info .wt-image-container {
            width: 500px; } }
        @media (min-width: 1120px) {
          .wt-description .company .left-info .wt-image-container {
            width: 534px;
            height: 434px; } }
        @media (min-width: 1190px) {
          .wt-description .company .left-info .wt-image-container {
            right: 1em; } }
        .wt-description .company .left-info .wt-image-container .wt-image-container-img {
          width: 100%;
          display: none; }
          @media (min-width: 768px) {
            .wt-description .company .left-info .wt-image-container .wt-image-container-img {
              position: relative;
              left: 50%;
              display: block;
              transform: translate(-50%, 0);
              width: auto;
              height: 100%; } }
      .wt-description .company .left-info .container-text-info {
        padding: 16px 0; }
        @media (min-width: 768px) {
          .wt-description .company .left-info .container-text-info {
            position: relative;
            bottom: 10em;
            left: 1em;
            width: 89%;
            padding: 0; } }
        @media (min-width: 1025px) {
          .wt-description .company .left-info .container-text-info {
            left: 0.4em; } }
        .wt-description .company .left-info .container-text-info h3 {
          font-family: 'PFD';
          font-size: 20px;
          line-height: 34px;
          color: #ffffff;
          font-weight: 500; }
          @media (min-width: 1025px) {
            .wt-description .company .left-info .container-text-info h3 {
              font-size: 24px;
              padding: 0 0 10px 0; } }
        .wt-description .company .left-info .container-text-info ul li {
          font-family: 'PFD';
          font-size: 18px;
          line-height: 26px;
          color: #ffffff;
          font-weight: 300;
          list-style-type: none;
          position: relative;
          padding: 0 0 0 30px; }
          @media (min-width: 768px) {
            .wt-description .company .left-info .container-text-info ul li {
              padding: 0 0px 15px 25px; } }
          @media (min-width: 1025px) {
            .wt-description .company .left-info .container-text-info ul li {
              padding: 0 0px 13px 25px; } }
          .wt-description .company .left-info .container-text-info ul li::before {
            position: absolute;
            content: '';
            width: 15px;
            height: 2px;
            background-color: #ffffff;
            top: 12px;
            left: 0; }
    .wt-description .company .right-info {
      position: relative;
      padding: 0 10px; }
      @media (min-width: 768px) {
        .wt-description .company .right-info {
          width: 353px;
          padding: 0; } }
      @media (min-width: 1025px) {
        .wt-description .company .right-info {
          width: 47%; } }
      .wt-description .company .right-info .right-text {
        position: relative;
        display: flex; }
        @media (min-width: 768px) {
          .wt-description .company .right-info .right-text {
            display: block;
            margin: 0; } }
        .wt-description .company .right-info .right-text h4 {
          font-family: 'PFD';
          font-size: 50px;
          line-height: 63px;
          color: transparent;
          -webkit-text-stroke-color: #373737;
          -webkit-text-stroke-width: 1px;
          font-weight: bold; }
          @media (min-width: 768px) {
            .wt-description .company .right-info .right-text h4 {
              font-size: 70px; } }
          @media (min-width: 1025px) {
            .wt-description .company .right-info .right-text h4 {
              font-size: 100px;
              line-height: 90px; } }
        .wt-description .company .right-info .right-text p {
          position: relative;
          left: 4px;
          top: -20px;
          font-family: 'PFDC';
          font-size: 60px;
          line-height: .8;
          color: #ffffff;
          font-weight: 700;
          margin: 0; }
          @media (min-width: 768px) {
            .wt-description .company .right-info .right-text p {
              color: #373737; } }
          @media (min-width: 1025px) {
            .wt-description .company .right-info .right-text p {
              font-size: 80px;
              line-height: 0.8; } }
      .wt-description .company .right-info img {
        width: 100%; }
        @media (min-width: 768px) {
          .wt-description .company .right-info img {
            display: none; } }
      .wt-description .company .right-info .right-p {
        position: relative;
        bottom: 0;
        padding: 0 10px;
        text-align: center; }
        @media (min-width: 768px) {
          .wt-description .company .right-info .right-p {
            padding: 0 20px 0 10px;
            text-align: initial; } }
        .wt-description .company .right-info .right-p p {
          font-family: 'PFD';
          font-size: 18px;
          line-height: 26px;
          color: #ffffff;
          font-weight: 300; }
          @media (min-width: 768px) {
            .wt-description .company .right-info .right-p p {
              margin-top: 6px;
              color: #373737; } }
          @media (min-width: 1025px) {
            .wt-description .company .right-info .right-p p {
              margin-top: 20px; } }
          .wt-description .company .right-info .right-p p span {
            font-size: 20px;
            line-height: 40px;
            color: #ffffff;
            font-weight: 500; }
            @media (min-width: 768px) {
              .wt-description .company .right-info .right-p p span {
                font-size: 18px;
                margin-top: 6px;
                color: #373737; } }
            @media (min-width: 1025px) {
              .wt-description .company .right-info .right-p p span {
                font-size: 24px; } }
  .wt-description .green-block {
    background-color: #1f831a;
    display: block;
    height: calc(100% + -200px);
    width: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0; }
    @media (min-width: 768px) {
      .wt-description .green-block {
        height: 87%;
        width: calc(50% + 21px); } }
    @media (min-width: 1025px) {
      .wt-description .green-block {
        height: 81%; } }

.wt-description-rtl {
  margin: 2em 0 70px 0;
  position: relative;
  display: none; }
  @media (min-width: 768px) {
    .wt-description-rtl {
      display: block;
      margin: 2em 0 140px 0; } }
  @media (min-width: 1025px) {
    .wt-description-rtl {
      margin: 2em 0 153px 0; } }
  .wt-description-rtl .company {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;
    margin: 40px 0 0 0; }
    @media (min-width: 768px) {
      .wt-description-rtl .company {
        flex-wrap: nowrap; } }
    @media (min-width: 1025px) {
      .wt-description-rtl .company {
        width: 100%;
        max-width: 1180px;
        margin: 0 auto;
        flex-wrap: nowrap; } }
    .wt-description-rtl .company .left-info {
      direction: rtl;
      padding: 0 10px; }
      @media (min-width: 768px) {
        .wt-description-rtl .company .left-info {
          padding-right: 20px;
          z-index: 2;
          position: relative;
          width: 50%;
          max-width: 375px;
          top: 10em; } }
      @media (min-width: 1025px) {
        .wt-description-rtl .company .left-info {
          max-width: 955px; } }
      .wt-description-rtl .company .left-info .wt-image-container {
        position: relative;
        overflow: hidden; }
        @media (min-width: 768px) {
          .wt-description-rtl .company .left-info .wt-image-container {
            background-position: 63% 91%;
            background-size: 48em;
            background-repeat: no-repeat;
            width: 334px;
            height: 495px;
            margin: 0 0 30px 0;
            position: relative;
            bottom: 10em; } }
        @media (min-width: 1025px) {
          .wt-description-rtl .company .left-info .wt-image-container {
            width: 480px; } }
        @media (min-width: 1080px) {
          .wt-description-rtl .company .left-info .wt-image-container {
            width: 500px; } }
        @media (min-width: 1120px) {
          .wt-description-rtl .company .left-info .wt-image-container {
            width: 534px;
            height: 434px; } }
        @media (min-width: 1190px) {
          .wt-description-rtl .company .left-info .wt-image-container {
            left: 1em; } }
        .wt-description-rtl .company .left-info .wt-image-container .wt-image-container-img {
          display: none; }
          @media (min-width: 768px) {
            .wt-description-rtl .company .left-info .wt-image-container .wt-image-container-img {
              position: relative;
              right: 50%;
              display: block;
              transform: translate(50%, 0);
              width: auto;
              height: 100%; } }
      .wt-description-rtl .company .left-info .container-text-info {
        direction: ltr;
        padding: 16px 0; }
        @media (min-width: 768px) {
          .wt-description-rtl .company .left-info .container-text-info {
            position: relative;
            bottom: 10em;
            left: 1em;
            width: 89%;
            padding: 0; } }
        @media (min-width: 1025px) {
          .wt-description-rtl .company .left-info .container-text-info {
            left: 0.4em; } }
        .wt-description-rtl .company .left-info .container-text-info h3 {
          font-family: 'PFD';
          font-size: 20px;
          line-height: 34px;
          color: #ffffff;
          font-weight: 500; }
          @media (min-width: 1025px) {
            .wt-description-rtl .company .left-info .container-text-info h3 {
              font-size: 24px;
              padding: 0 0 10px 0; } }
        .wt-description-rtl .company .left-info .container-text-info ul li {
          font-family: 'PFD';
          font-size: 18px;
          line-height: 26px;
          color: #ffffff;
          font-weight: 300;
          list-style-type: none;
          position: relative;
          padding: 0 0 0 30px; }
          @media (min-width: 768px) {
            .wt-description-rtl .company .left-info .container-text-info ul li {
              padding: 0 0px 15px 25px; } }
          @media (min-width: 1025px) {
            .wt-description-rtl .company .left-info .container-text-info ul li {
              padding: 0 0px 13px 25px; } }
          .wt-description-rtl .company .left-info .container-text-info ul li::before {
            position: absolute;
            content: '';
            width: 15px;
            height: 2px;
            background-color: #ffffff;
            top: 12px;
            left: 0; }
    .wt-description-rtl .company .right-info {
      position: relative;
      padding: 0 10px;
      direction: rtl; }
      @media (min-width: 768px) {
        .wt-description-rtl .company .right-info {
          width: 353px;
          padding: 0; } }
      @media (min-width: 1025px) {
        .wt-description-rtl .company .right-info {
          width: 47%; } }
      .wt-description-rtl .company .right-info .right-text {
        position: relative;
        display: flex; }
        @media (min-width: 768px) {
          .wt-description-rtl .company .right-info .right-text {
            display: block;
            margin: 0; } }
        .wt-description-rtl .company .right-info .right-text h4 {
          font-family: 'PFD';
          font-size: 50px;
          line-height: 63px;
          color: transparent;
          -webkit-text-stroke-color: #373737;
          -webkit-text-stroke-width: 1px;
          font-weight: bold; }
          @media (min-width: 768px) {
            .wt-description-rtl .company .right-info .right-text h4 {
              font-size: 70px; } }
          @media (min-width: 1025px) {
            .wt-description-rtl .company .right-info .right-text h4 {
              font-size: 100px;
              line-height: 90px; } }
        .wt-description-rtl .company .right-info .right-text p {
          position: relative;
          left: 4px;
          top: -20px;
          font-family: 'PFDC';
          font-size: 60px;
          line-height: .8;
          color: #ffffff;
          font-weight: 700;
          margin: 0; }
          @media (min-width: 768px) {
            .wt-description-rtl .company .right-info .right-text p {
              color: #373737; } }
          @media (min-width: 1025px) {
            .wt-description-rtl .company .right-info .right-text p {
              font-size: 80px;
              line-height: 0.8; } }
      .wt-description-rtl .company .right-info img {
        width: 100%; }
        @media (min-width: 768px) {
          .wt-description-rtl .company .right-info img {
            display: none; } }
      .wt-description-rtl .company .right-info .right-p {
        direction: ltr;
        position: relative;
        bottom: 0;
        padding: 0 10px;
        text-align: center; }
        @media (min-width: 768px) {
          .wt-description-rtl .company .right-info .right-p {
            text-align: initial; } }
        .wt-description-rtl .company .right-info .right-p p {
          font-size: 18px;
          line-height: 26px;
          color: #ffffff;
          font-family: 'PFD';
          font-weight: 300; }
          @media (min-width: 768px) {
            .wt-description-rtl .company .right-info .right-p p {
              margin-top: 6px;
              color: #373737; } }
          @media (min-width: 1025px) {
            .wt-description-rtl .company .right-info .right-p p {
              margin-top: 20px; } }
          .wt-description-rtl .company .right-info .right-p p span {
            font-size: 20px;
            line-height: 40px;
            color: #ffffff;
            font-weight: 500; }
            @media (min-width: 768px) {
              .wt-description-rtl .company .right-info .right-p p span {
                font-size: 18px;
                margin-top: 6px;
                color: #373737; } }
            @media (min-width: 1025px) {
              .wt-description-rtl .company .right-info .right-p p span {
                font-size: 24px; } }
  .wt-description-rtl .green-block {
    background-color: #1f831a;
    display: block;
    height: calc(100% + -200px);
    width: 100%;
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0; }
    @media (min-width: 768px) {
      .wt-description-rtl .green-block {
        height: 87%;
        width: calc(50% + 21px); } }
    @media (min-width: 1025px) {
      .wt-description-rtl .green-block {
        height: 81%; } }

.wt-additional-info {
  position: relative;
  margin: 0 0 70px 0;
  padding: 0 0 0px 0; }
  @media (min-width: 768px) {
    .wt-additional-info {
      padding: 0 20px 40px 20px; } }
  @media (min-width: 1190px) {
    .wt-additional-info {
      padding: 0 0px 40px 0px; } }
  .wt-additional-info .wt-container-text {
    display: block;
    margin: 0 auto 70px auto;
    width: 280px; }
    @media (min-width: 768px) {
      .wt-additional-info .wt-container-text {
        margin: 0 auto 100px auto;
        max-width: 724px;
        width: unset; } }
    @media (min-width: 1025px) {
      .wt-additional-info .wt-container-text {
        max-width: 1180px;
        margin: 0 auto 113px auto; } }
    .wt-additional-info .wt-container-text h5 {
      font-family: 'PFDC';
      font-size: 35px;
      line-height: 40px;
      font-weight: bold;
      color: transparent;
      -webkit-text-stroke-color: #373737;
      -webkit-text-stroke-width: 1px; }
      @media (min-width: 768px) {
        .wt-additional-info .wt-container-text h5 {
          font-size: 70px;
          height: 50px;
          line-height: 63px; } }
      @media (min-width: 1025px) {
        .wt-additional-info .wt-container-text h5 {
          font-size: 100px;
          height: 70px;
          line-height: 90px; } }
    .wt-additional-info .wt-container-text p {
      font-family: 'PFDC';
      font-size: 35px;
      line-height: 0;
      top: 25px;
      left: -1px;
      font-weight: bold;
      color: #373737;
      position: relative; }
      @media (min-width: 768px) {
        .wt-additional-info .wt-container-text p {
          font-size: 60px;
          top: 7px; } }
      @media (min-width: 1025px) {
        .wt-additional-info .wt-container-text p {
          font-size: 80px;
          top: 14px; } }
  .wt-additional-info .wt-main-container-info {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    justify-content: center; }
    @media (min-width: 768px) {
      .wt-additional-info .wt-main-container-info {
        width: 100%; } }
    .wt-additional-info .wt-main-container-info .wt-container-ul {
      width: 100%;
      top: 0;
      left: 0;
      padding: 20px 10px; }
      @media (min-width: 768px) {
        .wt-additional-info .wt-main-container-info .wt-container-ul {
          width: 480px;
          padding: 0;
          margin: 0 20px 0 0; } }
      @media (min-width: 1025px) {
        .wt-additional-info .wt-main-container-info .wt-container-ul {
          width: 49%;
          margin: 0; } }
      .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul {
        background-color: #ffffff;
        border-radius: 5px;
        width: 280px;
        margin: 0 auto; }
        @media (min-width: 768px) {
          .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul {
            position: relative;
            width: 100%;
            margin: unset; } }
        @media (min-width: 1025px) {
          .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul {
            z-index: 1; } }
        @media (min-width: 768px) {
          .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul {
            padding: 30px 0;
            margin-right: -7em; } }
        @media (min-width: 1025px) {
          .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul {
            padding: 30px 0 0 0px; } }
        .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul li {
          position: relative;
          list-style-type: none;
          padding-bottom: 20px; }
          .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul li .wt-block-li {
            padding-left: 20px; }
            @media (min-width: 768px) {
              .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul li .wt-block-li {
                padding-left: 50px; } }
            .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul li .wt-block-li::before {
              position: absolute;
              content: '';
              width: 2px;
              height: 55%;
              background-color: #1e8219;
              left: 10px;
              top: 4px; }
              @media (min-width: 768px) {
                .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul li .wt-block-li::before {
                  left: 30px;
                  top: 4px; } }
              @media (min-width: 1025px) {
                .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul li .wt-block-li::before {
                  height: 60%; } }
            .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul li .wt-block-li h6 {
              font-family: 'PFD';
              font-size: 20px;
              line-height: 26px;
              font-weight: 500;
              color: #373737; }
              @media (min-width: 1025px) {
                .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul li .wt-block-li h6 {
                  padding: 0 0 6px 0; } }
            .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul li .wt-block-li p {
              font-family: 'PFD';
              font-size: 18px;
              line-height: 26px;
              font-weight: 400;
              padding: 0 2px 0 0; }
          .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul li .wt-product {
            position: relative; }
            .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul li .wt-product::before {
              height: 88%; }
              @media (min-width: 1025px) {
                .wt-additional-info .wt-main-container-info .wt-container-ul .wt-block-ul ul li .wt-product::before {
                  height: 88%; } }
  .wt-additional-info .wt-container-certificate {
    width: 222px;
    margin: 0 auto;
    position: relative;
    z-index: 11; }
    @media (min-width: 768px) {
      .wt-additional-info .wt-container-certificate {
        position: relative;
        width: 224px;
        right: 0;
        padding: 0; } }
    @media (min-width: 1025px) {
      .wt-additional-info .wt-container-certificate {
        width: 51%; } }
    .wt-additional-info .wt-container-certificate .certificate {
      position: relative;
      padding-left: 20px; }
      @media (min-width: 768px) {
        .wt-additional-info .wt-container-certificate .certificate {
          position: relative;
          padding: 0 0 0 20px; } }
      @media (min-width: 1025px) {
        .wt-additional-info .wt-container-certificate .certificate {
          padding: 0 0 0 20px;
          position: relative;
          top: 75px; } }
      .wt-additional-info .wt-container-certificate .certificate::before {
        display: block;
        position: absolute;
        content: '';
        width: 2px;
        height: 97%;
        background-color: #fff;
        left: 0px;
        top: 1px; }
        @media (min-width: 1025px) {
          .wt-additional-info .wt-container-certificate .certificate::before {
            left: 20px; } }
      .wt-additional-info .wt-container-certificate .certificate h6 {
        padding: 0 0 20px 0;
        font-size: 20px;
        color: #ffffff;
        font-weight: 500;
        font-family: "PFD"; }
        @media (min-width: 768px) {
          .wt-additional-info .wt-container-certificate .certificate h6 {
            padding: 0 0 10px 0; } }
        @media (min-width: 1025px) {
          .wt-additional-info .wt-container-certificate .certificate h6 {
            padding: 0 0 10px 20px; } }
      .wt-additional-info .wt-container-certificate .certificate img {
        padding: 0 0 20px 0;
        margin: 0 0 -8px 0; }
        @media (min-width: 768px) {
          .wt-additional-info .wt-container-certificate .certificate img {
            padding: 0 0 20px 0;
            width: 182px;
            height: 258px; } }
        @media (min-width: 1025px) {
          .wt-additional-info .wt-container-certificate .certificate img {
            width: 202px;
            height: 286px;
            padding: 0px 0 0 20px; } }

.block-green {
  background-color: #1f831a;
  display: block;
  height: 65%;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: -1; }
  @media (min-width: 768px) {
    .block-green {
      height: calc(100% + (-70px));
      width: calc(50% + (-10px));
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1; } }
  @media (min-width: 825px) {
    .block-green {
      width: calc(50% + (100px));
      height: calc(100% + (-80px)); } }
  @media (min-width: 1025px) {
    .block-green {
      height: 612px;
      width: calc(50% + 80px);
      position: absolute;
      bottom: 80px;
      z-index: -1; } }

.block-gray {
  background-color: #f4f4f4;
  display: block;
  height: calc(50% + (1px));
  width: 100%;
  position: absolute;
  left: 0;
  top: 100px;
  z-index: -2; }
  @media (min-width: 768px) {
    .block-gray {
      height: calc(100% + (-110px));
      width: calc(50% + 20px);
      position: absolute;
      top: unset;
      bottom: 0;
      z-index: -2; } }
  @media (min-width: 1025px) {
    .block-gray {
      height: 652px;
      width: calc(50% + 102px);
      position: absolute;
      bottom: 0;
      z-index: -2; } }

.wt-woodtrade-info {
  position: relative; }
  .wt-woodtrade-info .wt-grid {
    display: grid;
    grid-template-columns: 15px auto 15px;
    position: relative;
    row-gap: 40px;
    padding: 20px 0 0 0;
    margin: 0 0 70px 0; }
    @media (min-width: 768px) {
      .wt-woodtrade-info .wt-grid {
        grid-template-rows: auto 62px auto 63px auto;
        grid-template-columns: 1fr 30px 250px;
        row-gap: 30px;
        padding: 0px 20px 40px 20px; } }
    @media (min-width: 1190px) {
      .wt-woodtrade-info .wt-grid {
        grid-template-columns: 1fr 800px 180px 200px 1fr;
        grid-template-rows: 95px 154px 100px 237px 1fr;
        padding: 70px 0px 70px 0px; } }
    .wt-woodtrade-info .wt-grid .wt-title {
      grid-column: 2; }
      @media (min-width: 768px) {
        .wt-woodtrade-info .wt-grid .wt-title {
          grid-column: 1;
          margin: 40px 0 0 0; } }
      @media (min-width: 1190px) {
        .wt-woodtrade-info .wt-grid .wt-title {
          grid-column: 2;
          grid-row: 1;
          margin: 0; } }
      .wt-woodtrade-info .wt-grid .wt-title .wt-border-text {
        position: relative;
        text-align: center;
        font-family: 'PFDC';
        font-size: 35px;
        font-weight: 700;
        color: transparent;
        -webkit-text-stroke-color: #ffffff;
        -webkit-text-stroke-width: 1px; }
        @media (min-width: 768px) {
          .wt-woodtrade-info .wt-grid .wt-title .wt-border-text {
            font-size: 70px;
            text-align: left;
            height: 49px;
            line-height: 63px; } }
        @media (min-width: 1190px) {
          .wt-woodtrade-info .wt-grid .wt-title .wt-border-text {
            font-size: 100px;
            line-height: 90px;
            height: 71px; } }
      .wt-woodtrade-info .wt-grid .wt-title .wt-text {
        text-align: center;
        font-family: 'PFDC';
        font-size: 35px;
        font-weight: 700;
        color: #ffffff; }
        @media (min-width: 768px) {
          .wt-woodtrade-info .wt-grid .wt-title .wt-text {
            font-size: 60px;
            text-align: left;
            position: relative;
            line-height: 0;
            top: 7px; } }
        @media (min-width: 1190px) {
          .wt-woodtrade-info .wt-grid .wt-title .wt-text {
            font-size: 80px; } }
    .wt-woodtrade-info .wt-grid .wt-info {
      grid-column: 2;
      grid-row: 3;
      padding: 20px;
      box-sizing: border-box;
      background-color: #ffffff;
      border-radius: 5px; }
      @media (min-width: 768px) {
        .wt-woodtrade-info .wt-grid .wt-info {
          grid-column: 1/-1;
          grid-row: 2/5; } }
      @media (min-width: 1190px) {
        .wt-woodtrade-info .wt-grid .wt-info {
          grid-column: 2/4;
          grid-row: 2/-1;
          padding: 20px 200px 20px 20px; } }
      .wt-woodtrade-info .wt-grid .wt-info h6 {
        font-size: 19px;
        font-weight: 500;
        margin: 0 0 20px 0; }
      .wt-woodtrade-info .wt-grid .wt-info ul {
        list-style: none;
        font-size: 18px;
        font-weight: 400; }
        .wt-woodtrade-info .wt-grid .wt-info ul li {
          margin: 0 0 20px 0;
          padding: 0 0 0 20px;
          box-sizing: border-box;
          position: relative; }
          .wt-woodtrade-info .wt-grid .wt-info ul li::before {
            content: '';
            position: absolute;
            left: 0;
            top: 4px;
            display: block;
            height: 16px;
            width: 0;
            border-left: 2px solid #1e8219; }
      .wt-woodtrade-info .wt-grid .wt-info p {
        font-size: 18px;
        font-weight: 400;
        margin: 0 0 20px 0; }
      .wt-woodtrade-info .wt-grid .wt-info span {
        font-size: 18px;
        font-family: 'PFDC';
        font-weight: 700; }
    .wt-woodtrade-info .wt-grid .wt-video {
      grid-column: 2;
      grid-row: 2;
      height: 200px;
      background-image: url("/libs/img/doska-video.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 5px; }
      @media (min-width: 768px) {
        .wt-woodtrade-info .wt-grid .wt-video {
          grid-column: 2/-1;
          grid-row: 1/3;
          height: 100%; } }
      @media (min-width: 1190px) {
        .wt-woodtrade-info .wt-grid .wt-video {
          grid-column: 3/5;
          grid-row: 1/3; } }
    .wt-woodtrade-info .wt-grid .wt-name {
      position: relative;
      grid-column: 2;
      grid-row: 4;
      background-color: #1e8219;
      border-radius: 5px;
      padding: 17px 20px 15px 20px;
      box-sizing: border-box;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400; }
      @media (min-width: 768px) {
        .wt-woodtrade-info .wt-grid .wt-name {
          grid-column: 3;
          grid-row: 4/-1;
          line-height: 30px;
          padding: 15px 25px 15px 25px;
          width: 280px; } }
      @media (min-width: 1190px) {
        .wt-woodtrade-info .wt-grid .wt-name {
          grid-column: 3/-1;
          grid-row: 3;
          width: 100%;
          border-radius: 5px 0px 0px 5px;
          padding: 0;
          display: grid; } }
      .wt-woodtrade-info .wt-grid .wt-name p {
        align-self: center; }
        @media (min-width: 1190px) {
          .wt-woodtrade-info .wt-grid .wt-name p {
            width: 380px;
            padding: 15px 0px 15px 30px; } }
    .wt-woodtrade-info .wt-grid .wt-background-green {
      position: absolute;
      display: block;
      z-index: -1;
      grid-row: 1/4;
      top: -20px;
      width: 100%;
      height: calc(100% + 40px);
      background-color: #1e8219; }
      @media (min-width: 768px) {
        .wt-woodtrade-info .wt-grid .wt-background-green {
          display: none; } }
  .wt-woodtrade-info .wt-background-green {
    display: none; }
    @media (min-width: 768px) {
      .wt-woodtrade-info .wt-background-green {
        display: block;
        position: absolute;
        background-color: #1e8219;
        bottom: 0;
        left: 0;
        height: 100%;
        width: calc(50% + 75px);
        z-index: -1; } }
    @media (min-width: 1190px) {
      .wt-woodtrade-info .wt-background-green {
        width: calc(50% + 90px); } }
  .wt-woodtrade-info .wt-background-gray {
    display: none; }
    @media (min-width: 768px) {
      .wt-woodtrade-info .wt-background-gray {
        display: block;
        position: absolute;
        background-color: #f4f4f4;
        bottom: 0;
        right: 0;
        height: calc(100% + 50px);
        width: calc(50% + 75px);
        z-index: -2; } }
    @media (min-width: 1190px) {
      .wt-woodtrade-info .wt-background-gray {
        bottom: unset;
        top: -100px;
        height: calc(100% + 35px);
        width: calc(50% + 280px); } }

.wt-about-ltd {
  position: relative;
  margin: 0 0 70px 0; }
  @media (min-width: 768px) {
    .wt-about-ltd {
      margin: 20px 0 70px 0;
      padding: 0 0 25px 0; } }
  .wt-about-ltd .wt-grid {
    display: grid;
    gap: 30px;
    box-sizing: border-box;
    max-width: 300px;
    margin: 0 auto;
    width: 100%; }
    @media (min-width: 768px) {
      .wt-about-ltd .wt-grid {
        max-width: unset;
        padding: 0 20px;
        grid-template-columns: 1fr 375px;
        grid-template-rows: auto auto 115px; } }
    @media (min-width: 868px) {
      .wt-about-ltd .wt-grid {
        grid-template-rows: unset; } }
    @media (min-width: 1190px) {
      .wt-about-ltd .wt-grid {
        max-width: 1180px;
        grid-template-columns: 1fr 680px;
        padding: 0;
        column-gap: 0; } }
    .wt-about-ltd .wt-grid .wt-title {
      grid-column: 1/-1;
      grid-row: 1; }
      @media (min-width: 768px) {
        .wt-about-ltd .wt-grid .wt-title {
          margin: 0 0 12px 0; } }
      .wt-about-ltd .wt-grid .wt-title .wt-border-text {
        position: relative;
        font-family: 'PFDC';
        font-size: 35px;
        font-weight: 700;
        color: transparent;
        -webkit-text-stroke-color: #373737;
        -webkit-text-stroke-width: 1px; }
        @media (min-width: 768px) {
          .wt-about-ltd .wt-grid .wt-title .wt-border-text {
            font-size: 70px;
            line-height: 0;
            text-align: left;
            top: 15px;
            left: 345px; } }
        @media (min-width: 1190px) {
          .wt-about-ltd .wt-grid .wt-title .wt-border-text {
            font-size: 100px;
            left: unset;
            right: 15px;
            top: 55px;
            text-align: right; } }
      .wt-about-ltd .wt-grid .wt-title .wt-text {
        font-family: 'PFDC';
        font-size: 35px;
        font-weight: 700;
        color: #373737; }
        @media (min-width: 768px) {
          .wt-about-ltd .wt-grid .wt-title .wt-text {
            font-size: 60px; } }
        @media (min-width: 1025px) {
          .wt-about-ltd .wt-grid .wt-title .wt-text {
            width: 70%; } }
        @media (min-width: 1190px) {
          .wt-about-ltd .wt-grid .wt-title .wt-text {
            font-size: 80px; } }
        .wt-about-ltd .wt-grid .wt-title .wt-text sub {
          display: inline; }
          @media (min-width: 768px) {
            .wt-about-ltd .wt-grid .wt-title .wt-text sub {
              display: none; } }
    .wt-about-ltd .wt-grid .wt-info {
      grid-row: 3;
      display: grid;
      gap: 20px; }
      @media (min-width: 768px) {
        .wt-about-ltd .wt-grid .wt-info {
          grid-row: 2/-1;
          grid-column: 1;
          grid-auto-rows: min-content;
          gap: 15px; } }
      @media (min-width: 868px) {
        .wt-about-ltd .wt-grid .wt-info {
          grid-row: 2; } }
      @media (min-width: 1025px) {
        .wt-about-ltd .wt-grid .wt-info {
          padding: 65px 0 0 0; } }
      .wt-about-ltd .wt-grid .wt-info h3 {
        font-size: 20px;
        font-weight: 500; }
        @media (min-width: 1025px) {
          .wt-about-ltd .wt-grid .wt-info h3 {
            width: 70%; } }
      .wt-about-ltd .wt-grid .wt-info p {
        font-family: 'PFD';
        font-size: 18px;
        font-weight: 300;
        margin: 0 0 0 0; }
        @media (min-width: 768px) {
          .wt-about-ltd .wt-grid .wt-info p {
            line-height: 26px; } }
        @media (min-width: 1025px) {
          .wt-about-ltd .wt-grid .wt-info p {
            line-height: 22px; } }
    .wt-about-ltd .wt-grid .wt-counters-col {
      grid-row: 2; }
      @media (min-width: 768px) {
        .wt-about-ltd .wt-grid .wt-counters-col {
          grid-row: unset;
          display: grid;
          grid-template-columns: 125px 1fr; } }
      @media (min-width: 1190px) {
        .wt-about-ltd .wt-grid .wt-counters-col {
          grid-auto-rows: min-content;
          grid-template-columns: 200px 1fr 200px; } }
      .wt-about-ltd .wt-grid .wt-counters-col .wt-years {
        display: grid;
        grid-template-columns: 80px 1fr;
        background-color: #ffffff;
        border-radius: 5px;
        padding: 10px 20px 10px 0;
        margin: 0 0 13px 0; }
        @media (min-width: 768px) {
          .wt-about-ltd .wt-grid .wt-counters-col .wt-years {
            grid-row: 1;
            grid-column: 1/3;
            grid-template-columns: 100px 1fr;
            padding: 10px 20px 10px 20px; } }
        @media (min-width: 1190px) {
          .wt-about-ltd .wt-grid .wt-counters-col .wt-years {
            grid-template-columns: 120px 1fr; } }
        .wt-about-ltd .wt-grid .wt-counters-col .wt-years span {
          align-self: center;
          text-align: center;
          font-size: 30px;
          font-family: 'PFDC';
          font-weight: 700;
          position: relative;
          top: 5px;
          color: transparent;
          -webkit-text-stroke-color: #1e8219;
          -webkit-text-stroke-width: 2px; }
          @media (min-width: 768px) {
            .wt-about-ltd .wt-grid .wt-counters-col .wt-years span {
              font-size: 40px;
              text-align: left; } }
          @media (min-width: 1190px) {
            .wt-about-ltd .wt-grid .wt-counters-col .wt-years span {
              font-size: 60px; } }
        .wt-about-ltd .wt-grid .wt-counters-col .wt-years h3 {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px; }
          @media (min-width: 768px) {
            .wt-about-ltd .wt-grid .wt-counters-col .wt-years h3 {
              align-self: center; } }
      .wt-about-ltd .wt-grid .wt-counters-col .wt-location {
        display: grid;
        grid-template-columns: 80px 1fr;
        background-color: #ffffff;
        border-radius: 5px;
        padding: 10px 20px 10px 0;
        margin: 0 0 13px 0; }
        @media (min-width: 768px) {
          .wt-about-ltd .wt-grid .wt-counters-col .wt-location {
            grid-row: 2;
            grid-column: 2/-1;
            grid-template-columns: 48px 1fr;
            padding: 10px 20px 10px 20px; } }
        @media (min-width: 1190px) {
          .wt-about-ltd .wt-grid .wt-counters-col .wt-location {
            grid-template-columns: 60px 1fr; } }
        .wt-about-ltd .wt-grid .wt-counters-col .wt-location span {
          align-self: center;
          text-align: center;
          font-size: 30px;
          font-family: 'PFDC';
          font-weight: 700;
          position: relative;
          top: 5px;
          color: transparent;
          -webkit-text-stroke-color: #1e8219;
          -webkit-text-stroke-width: 2px; }
          @media (min-width: 768px) {
            .wt-about-ltd .wt-grid .wt-counters-col .wt-location span {
              text-align: left;
              font-size: 40px; } }
          @media (min-width: 1190px) {
            .wt-about-ltd .wt-grid .wt-counters-col .wt-location span {
              font-size: 60px; } }
        .wt-about-ltd .wt-grid .wt-counters-col .wt-location h3 {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px; }
          @media (min-width: 768px) {
            .wt-about-ltd .wt-grid .wt-counters-col .wt-location h3 {
              align-self: center; } }
      .wt-about-ltd .wt-grid .wt-counters-col .wt-process {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 20px;
        margin: 0 0 13px 0; }
        @media (min-width: 768px) {
          .wt-about-ltd .wt-grid .wt-counters-col .wt-process {
            grid-row: 3;
            grid-column: 1/-1;
            display: grid;
            grid-template-columns: 170px 1fr; } }
        @media (min-width: 1190px) {
          .wt-about-ltd .wt-grid .wt-counters-col .wt-process {
            grid-template-columns: 262px 1fr; } }
        .wt-about-ltd .wt-grid .wt-counters-col .wt-process span {
          display: block;
          font-size: 30px;
          font-family: 'PFDC';
          font-weight: 700;
          position: relative;
          top: 5px;
          color: transparent;
          -webkit-text-stroke-color: #1e8219;
          -webkit-text-stroke-width: 2px; }
          @media (min-width: 768px) {
            .wt-about-ltd .wt-grid .wt-counters-col .wt-process span {
              align-self: center;
              font-size: 40px; } }
          @media (min-width: 1190px) {
            .wt-about-ltd .wt-grid .wt-counters-col .wt-process span {
              font-size: 60px;
              text-align: left; } }
        .wt-about-ltd .wt-grid .wt-counters-col .wt-process h3 {
          padding: 0 0 0 0;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px; }
          @media (min-width: 768px) {
            .wt-about-ltd .wt-grid .wt-counters-col .wt-process h3 {
              align-self: center; } }
  .wt-about-ltd .wt-picture-wrap {
    position: absolute;
    height: 455px;
    width: calc(50% + 150px);
    left: 0;
    top: 37.4%;
    transform: translate(0%, -50%);
    z-index: -2;
    background-color: #f4f4f4;
    overflow: hidden; }
    @media (min-width: 768px) {
      .wt-about-ltd .wt-picture-wrap {
        transform: translate(0, 0);
        top: unset;
        bottom: 0;
        height: 571px;
        width: 100%; } }
    @media (min-width: 868px) {
      .wt-about-ltd .wt-picture-wrap {
        height: 443px; } }
    @media (min-width: 1190px) {
      .wt-about-ltd .wt-picture-wrap {
        transform: translate(-100%, 0);
        left: calc(50% + 590px); } }
    .wt-about-ltd .wt-picture-wrap .wt-picture {
      display: none; }
      @media (min-width: 768px) {
        .wt-about-ltd .wt-picture-wrap .wt-picture {
          display: unset; } }
      .wt-about-ltd .wt-picture-wrap .wt-picture::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 110%;
        z-index: 1;
        background: linear-gradient(90deg, #f4f4f4 0%, rgba(244, 244, 244, 0.75) 50%, #f4f4f4 100%); }
      .wt-about-ltd .wt-picture-wrap .wt-picture img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
  .wt-about-ltd .wt-background {
    position: absolute;
    height: calc(455px + 67px);
    width: calc(50% + (-80px));
    right: 0;
    top: 36%;
    transform: translate(0%, -50%);
    z-index: -1;
    background-color: #1e8219; }
    @media (min-width: 768px) {
      .wt-about-ltd .wt-background {
        transform: translate(0, 0);
        top: unset;
        bottom: 30px;
        height: 613px;
        width: calc(50% + (-60px)); } }
    @media (min-width: 868px) {
      .wt-about-ltd .wt-background {
        height: 470px;
        width: 300px;
        bottom: 20px; } }
    @media (min-width: 1190px) {
      .wt-about-ltd .wt-background {
        height: 510px;
        width: calc(50% + -6px);
        bottom: 30px; } }

.wt-slider-container-production {
  position: relative;
  margin-top: 70px;
  margin-bottom: 70px;
  padding: 0 10px; }
  @media (min-width: 768px) {
    .wt-slider-container-production {
      padding: 0 20px; } }
  @media (min-width: 1025px) {
    .wt-slider-container-production {
      padding: 0; } }
  .wt-slider-container-production .wt-green-background-slider {
    background-color: #1f831a;
    width: 60%;
    height: 127px;
    position: absolute;
    left: 0;
    top: 64px; }
    @media (min-width: 736px) {
      .wt-slider-container-production .wt-green-background-slider {
        width: 55%; } }
    @media (min-width: 768px) {
      .wt-slider-container-production .wt-green-background-slider {
        height: 170px;
        top: 133px;
        width: 39%; } }
    @media (min-width: 1025px) {
      .wt-slider-container-production .wt-green-background-slider {
        height: 330px;
        top: 155px;
        width: 50%; } }
  .wt-slider-container-production #slider {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto; }
    .wt-slider-container-production #slider .dp-wrap {
      margin: 0 auto;
      position: relative;
      perspective: 1000px;
      height: 100%;
      font-family: "Bebas Neue", sans-serif;
      font-weight: 400; }
    .wt-slider-container-production #slider .dp-slider {
      height: 100%;
      width: 100%;
      position: absolute;
      transform-style: preserve-3d; }
      .wt-slider-container-production #slider .dp-slider div {
        transform-style: preserve-3d; }
    .wt-slider-container-production #slider .dp_item {
      display: block;
      position: absolute;
      text-align: center;
      color: #FFF;
      border-radius: 10px;
      transition: transform 1.2s;
      cursor: pointer;
      top: 45px; }
      @media (min-width: 768px) {
        .wt-slider-container-production #slider .dp_item {
          top: 95px; } }
    .wt-slider-container-production #slider #dp-slider .dp_item:first-child {
      z-index: 10 !important;
      transform: rotateY(0deg) translateY(0%) translateX(0px) !important;
      position: relative;
      top: 0; }
    .wt-slider-container-production #slider .dp_item[data-position="2"] {
      z-index: 9;
      transform: rotateY(0deg) translateY(6%) translateX(4%) scale(1); }
    .wt-slider-container-production #slider .dp_item[data-position="3"] {
      z-index: 8;
      transform: rotateY(0deg) translateY(12%) translateX(8%) scale(1); }
    .wt-slider-container-production #slider .dp_item[data-position="4"] {
      z-index: 7;
      transform: rotateY(0deg) translateY(12%) translateX(8%) scale(1); }
    .wt-slider-container-production #slider .dp_item[data-position="5"] {
      z-index: 6;
      transform: rotateY(0deg) translateY(12%) translateX(8%) scale(1); }
    .wt-slider-container-production #slider .dp_item[data-position="6"] {
      z-index: 5;
      transform: rotateY(0deg) translateY(12%) translateX(8%) scale(1); }
    .wt-slider-container-production #slider .dp_item[data-position="2"] .dp-content, .wt-slider-container-production #slider .dp_item[data-position="3"] .dp-content, .wt-slider-container-production #slider .dp_item[data-position="4"] .dp-content, .wt-slider-container-production #slider .dp_item[data-position="5"] .dp-content, .wt-slider-container-production #slider .dp_item[data-position="6"] .dp-content {
      visibility: hidden; }
    @media (min-width: 768px) {
      .wt-slider-container-production #slider #dp-slider .dp_item:first-child {
        z-index: 10 !important;
        transform: rotateY(0deg) translateY(0%) translateX(0px) !important; }
      .wt-slider-container-production #slider .dp_item[data-position="2"] {
        z-index: 9;
        transform: rotateY(0deg) translateY(12%) translateX(3%) scale(1); }
      .wt-slider-container-production #slider .dp_item[data-position="3"] {
        z-index: 8;
        transform: rotateY(0deg) translateY(23%) translateX(6%) scale(1); }
      .wt-slider-container-production #slider .dp_item[data-position="4"] {
        z-index: 7;
        transform: rotateY(0deg) translateY(23%) translateX(6%) scale(1); }
      .wt-slider-container-production #slider .dp_item[data-position="5"] {
        z-index: 6;
        transform: rotateY(0deg) translateY(23%) translateX(6%) scale(1); }
      .wt-slider-container-production #slider .dp_item[data-position="6"] {
        z-index: 5;
        transform: rotateY(0deg) translateY(23%) translateX(6%) scale(1); }
      .wt-slider-container-production #slider .dp_item[data-position="2"] .dp-content, .wt-slider-container-production #slider .dp_item[data-position="3"] .dp-content, .wt-slider-container-production #slider .dp_item[data-position="4"] .dp-content, .wt-slider-container-production #slider .dp_item[data-position="5"] .dp-content, .wt-slider-container-production #slider .dp_item[data-position="6"] .dp-content {
        visibility: hidden; } }
    .wt-slider-container-production #slider #dp-next {
      position: absolute;
      top: 55px;
      left: 50%;
      transform: translateX(4.8em);
      background-color: #ffffff;
      background-position: center;
      background-repeat: no-repeat;
      height: 40px;
      width: 40px;
      z-index: 10;
      border-radius: 5px 5px 0 0;
      cursor: pointer; }
      .wt-slider-container-production #slider #dp-next img {
        height: 100%;
        width: 14px;
        margin: 0 auto;
        display: block; }
      @media (min-width: 400px) {
        .wt-slider-container-production #slider #dp-next {
          transform: translateX(4.3em); } }
      @media (min-width: 472px) {
        .wt-slider-container-production #slider #dp-next {
          transform: translateX(3.86em); } }
      @media (min-width: 572px) {
        .wt-slider-container-production #slider #dp-next {
          transform: translateX(3.3em); } }
      @media (min-width: 658px) {
        .wt-slider-container-production #slider #dp-next {
          transform: translateX(2.75em); } }
      @media (min-width: 724px) {
        .wt-slider-container-production #slider #dp-next {
          transform: translateX(2.38em); } }
      @media (min-width: 768px) {
        .wt-slider-container-production #slider #dp-next {
          transform: translate(23.5em);
          top: 125px;
          left: 0; } }
      @media (min-width: 1025px) {
        .wt-slider-container-production #slider #dp-next {
          transform: translateX(41.5em); } }
    .wt-slider-container-production #slider #dp-prev {
      position: absolute;
      top: 95px;
      left: 50%;
      transform: rotate(180deg) translateX(-4.8em);
      background-color: #ffffff;
      background-position: center;
      background-repeat: no-repeat;
      height: 40px;
      width: 40px;
      z-index: 10;
      border-radius: 5px 5px 0 0;
      cursor: pointer; }
      .wt-slider-container-production #slider #dp-prev img {
        height: 100%;
        width: 14px;
        margin: 0 auto;
        display: block; }
      @media (min-width: 400px) {
        .wt-slider-container-production #slider #dp-prev {
          transform: rotate(180deg) translateX(-4.3em); } }
      @media (min-width: 472px) {
        .wt-slider-container-production #slider #dp-prev {
          transform: rotate(180deg) translateX(-3.86em); } }
      @media (min-width: 572px) {
        .wt-slider-container-production #slider #dp-prev {
          transform: rotate(180deg) translateX(-3.3em); } }
      @media (min-width: 658px) {
        .wt-slider-container-production #slider #dp-prev {
          transform: rotate(180deg) translateX(-2.75em); } }
      @media (min-width: 724px) {
        .wt-slider-container-production #slider #dp-prev {
          transform: rotate(180deg) translateX(-2.38em); } }
      @media (min-width: 768px) {
        .wt-slider-container-production #slider #dp-prev {
          transform: rotate(180deg) translateX(-23.5em);
          left: 0;
          top: 165px; } }
      @media (min-width: 1025px) {
        .wt-slider-container-production #slider #dp-prev {
          transform: rotate(180deg) translateX(-41.5em); } }
    .wt-slider-container-production #slider #dp-dots {
      position: absolute;
      bottom: 0;
      z-index: 12;
      cursor: pointer;
      left: 50%;
      transform: translate(-50%, 24%);
      height: 45px;
      width: 296px; }
      @media (min-width: 768px) {
        .wt-slider-container-production #slider #dp-dots {
          transform: translate(217%, 50%);
          left: 0%;
          height: unset;
          width: unset; } }
      @media (min-width: 850px) {
        .wt-slider-container-production #slider #dp-dots {
          transform: translate(220%, 50%); } }
      @media (min-width: 900px) {
        .wt-slider-container-production #slider #dp-dots {
          transform: translate(224%, 50%); } }
      @media (min-width: 972px) {
        .wt-slider-container-production #slider #dp-dots {
          transform: translate(228%, 50%); } }
      @media (min-width: 1025px) {
        .wt-slider-container-production #slider #dp-dots {
          transform: translate(50%, 50%);
          left: 60%; } }
      @media (min-width: 1070px) {
        .wt-slider-container-production #slider #dp-dots {
          transform: translate(50%, 50%);
          left: 57%; } }
      .wt-slider-container-production #slider #dp-dots li {
        position: relative;
        display: inline-block;
        font-family: 'PFDC';
        font-size: 20px;
        color: #1e8219;
        font-weight: 700; }
        .wt-slider-container-production #slider #dp-dots li:before {
          position: absolute;
          left: 50%;
          bottom: 35px;
          transform: translate(-50%, 0);
          content: '';
          background-color: #1e8219;
          width: 1px;
          height: 0px;
          display: block;
          transition: .6s; }
        .wt-slider-container-production #slider #dp-dots li:not(:first-child) {
          margin-left: 30px; }
        @media (min-width: 768px) {
          .wt-slider-container-production #slider #dp-dots li:not(:first-child) {
            margin-left: 15px; } }
        @media (min-width: 1070px) {
          .wt-slider-container-production #slider #dp-dots li:not(:first-child) {
            margin-left: 30px; } }
        @media (min-width: 1025px) {
          .wt-slider-container-production #slider #dp-dots li:not(:first-child) {
            margin-left: 25px; } }
      .wt-slider-container-production #slider #dp-dots .active {
        position: relative;
        font-family: 'PFD';
        font-weight: 300;
        font-size: 20px;
        top: -3px;
        transform: scale(1.5);
        color: #373737;
        transition: font-size .3s, color .3s;
        line-height: 45px; }
        .wt-slider-container-production #slider #dp-dots .active:before {
          position: absolute;
          left: 50%;
          bottom: 35px;
          transform: translate(-50%, 0);
          content: '';
          background-color: #1e8219;
          width: 1px;
          height: 27px;
          display: block; }
          @media (min-width: 768px) {
            .wt-slider-container-production #slider #dp-dots .active:before {
              height: 16px; } }
          @media (min-width: 1025px) {
            .wt-slider-container-production #slider #dp-dots .active:before {
              height: 50px; } }
    .wt-slider-container-production #slider .dp-title {
      position: absolute;
      top: 0px;
      left: calc(50% + -62px);
      transform: translate(-50%, 0);
      z-index: 999; }
      @media (min-width: 768px) {
        .wt-slider-container-production #slider .dp-title {
          top: 25px;
          left: 0;
          transform: unset; } }
      @media (min-width: 1025px) {
        .wt-slider-container-production #slider .dp-title {
          top: 0; } }
      .wt-slider-container-production #slider .dp-title h2 {
        font-family: 'PFDC';
        font-weight: 700;
        text-transform: uppercase;
        z-index: 999;
        position: relative; }
        .wt-slider-container-production #slider .dp-title h2 .wt-slider-title1 {
          font-size: 35px;
          -webkit-text-stroke-color: #373737;
          -webkit-text-stroke-width: 1.00px;
          color: transparent;
          display: block;
          position: absolute;
          line-height: 35px;
          top: 10px;
          left: 30px;
          transform-origin: right bottom;
          transform: translate(-100%, 0) rotate(270deg); }
          @media (min-width: 768px) {
            .wt-slider-container-production #slider .dp-title h2 .wt-slider-title1 {
              font-size: 70px;
              line-height: 70px;
              top: 0;
              left: 75px; } }
          @media (min-width: 1025px) {
            .wt-slider-container-production #slider .dp-title h2 .wt-slider-title1 {
              font-size: 100px;
              line-height: 100px;
              left: 125px; } }
        .wt-slider-container-production #slider .dp-title h2 .wt-slider-title2 {
          font-size: 35px;
          color: #373737;
          line-height: 35px;
          position: relative;
          top: 10px;
          left: 25px; }
          @media (min-width: 768px) {
            .wt-slider-container-production #slider .dp-title h2 .wt-slider-title2 {
              font-size: 60px;
              line-height: 60px;
              left: 70px;
              top: 10px; } }
          @media (min-width: 1025px) {
            .wt-slider-container-production #slider .dp-title h2 .wt-slider-title2 {
              font-size: 80px;
              line-height: 80px;
              height: 72px;
              display: block;
              top: 13px;
              left: 117px; } }
    .wt-slider-container-production #slider .dp_item {
      width: 100%; }
      @media (min-width: 768px) {
        .wt-slider-container-production #slider .dp_item {
          width: 100%; } }
    .wt-slider-container-production #slider .dp-content, .wt-slider-container-production #slider .dp-img {
      text-align: center;
      width: 100%; }
      @media (min-width: 768px) {
        .wt-slider-container-production #slider .dp-content, .wt-slider-container-production #slider .dp-img {
          text-align: left; } }
    .wt-slider-container-production #slider .dp_item {
      display: flex;
      flex-direction: column;
      align-items: end;
      border-radius: 10px; }
      @media (min-width: 768px) {
        .wt-slider-container-production #slider .dp_item {
          align-items: end;
          flex-direction: row; } }
      @media (min-width: 1025px) {
        .wt-slider-container-production #slider .dp_item {
          align-items: flex-start; } }
    .wt-slider-container-production #slider .dp-content {
      padding-left: 0%;
      padding-right: 0;
      display: inline-block;
      position: relative;
      top: 73px;
      width: 100%;
      z-index: 99; }
      @media (min-width: 400px) {
        .wt-slider-container-production #slider .dp-content {
          left: 0%; } }
      @media (min-width: 768px) {
        .wt-slider-container-production #slider .dp-content {
          padding-left: 13%;
          position: unset;
          top: unset;
          left: unset;
          width: 100%; } }
      @media (min-width: 1025px) {
        .wt-slider-container-production #slider .dp-content {
          margin-bottom: 70px; } }
    .wt-slider-container-production #slider .dp-img:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 25%;
      z-index: 1;
      top: 0;
      pointer-events: none; }
    .wt-slider-container-production #slider .dp-img img {
      width: 216px;
      height: 116px;
      border-radius: 10px; }
      @media (min-width: 768px) {
        .wt-slider-container-production #slider .dp-img img {
          width: 327px;
          height: 174px; } }
      @media (min-width: 1025px) {
        .wt-slider-container-production #slider .dp-img img {
          width: 565px;
          height: 300px; } }
    .wt-slider-container-production #slider #dp-slider {
      max-width: inherit;
      padding-top: 44px;
      padding-bottom: 155px; }
      @media (min-width: 768px) {
        .wt-slider-container-production #slider #dp-slider {
          padding-left: 72px;
          padding-top: 96px;
          padding-bottom: 60px; } }
      @media (min-width: 1025px) {
        .wt-slider-container-production #slider #dp-slider {
          padding-left: 120px;
          padding-top: 96px; } }
    .wt-slider-container-production #slider #dp-slider .dp_item:hover:not(:first-child) {
      cursor: pointer; }
    .wt-slider-container-production #slider .site-btn {
      color: #fff;
      font-size: 18px;
      background: #FA8282;
      padding: 14px 43px;
      display: inline-block;
      border-radius: 2px;
      position: relative;
      top: -12px; }
      .wt-slider-container-production #slider .site-btn:hover {
        text-decoration: none;
        color: #fff; }
    .wt-slider-container-production #slider .pre-title {
      font-family: "Bebas Neue", sans-serif;
      font-size: 20px;
      color: #393939;
      margin-bottom: 0; }
    .wt-slider-container-production #slider p.white-text {
      background: #ffffff;
      color: #393939;
      border-radius: 10px;
      font-family: "PFD";
      color: #373737;
      display: block; }
      @media (min-width: 471px) {
        .wt-slider-container-production #slider p.white-text {
          padding-left: 80px;
          padding-right: 80px; } }
      @media (min-width: 768px) {
        .wt-slider-container-production #slider p.white-text {
          padding-left: unset;
          padding-right: unset; } }
      .wt-slider-container-production #slider p.white-text .wt-production-name {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 500;
        display: block;
        margin-bottom: 10px; }
      .wt-slider-container-production #slider p.white-text .wt-production-description {
        font-size: 18px;
        font-family: 'PFD';
        font-weight: 300;
        display: inline-block;
        line-height: 26px; }
      .wt-slider-container-production #slider p.white-text .wt-production-button {
        display: inline-block;
        border: 1px solid #1e8219;
        text-transform: uppercase;
        font-size: 16px;
        color: #1e8219;
        padding: 17px 45px;
        border-radius: 3px;
        text-decoration: none;
        margin-top: 10px; }

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0); }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0); }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }
    .wt-slider-container-production #slider .animated {
      animation-duration: 1s;
      animation-fill-mode: both;
      -webkit-animation-duration: 1s;
      -webkit-animation-fill-mode: both; }
    .wt-slider-container-production #slider .animatedFadeInUp {
      opacity: 0; }
    .wt-slider-container-production #slider .fadeInUp {
      opacity: 0;
      animation-name: fadeInUp;
      -webkit-animation-name: fadeInUp; }
    @media screen and (min-width: 768px) {
      .wt-slider-container-production #slider .dp_item {
        width: 100%; } }

.wt-accordion-menu {
  display: block;
  margin: 30px auto 0 auto;
  width: 100%; }
  .wt-accordion-menu .wt-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    position: relative; }
    .wt-accordion-menu .wt-flex-container .wt-flex-col1 {
      display: none; }
      @media (min-width: 768px) {
        .wt-accordion-menu .wt-flex-container .wt-flex-col1 {
          display: block;
          width: 85%;
          z-index: 1; } }
      .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac {
        position: absolute;
        width: 96%;
        background-repeat: no-repeat;
        height: 516px;
        background-size: 116%;
        background-position: 366%;
        transition: 0.3s; }
        @media (min-width: 925px) {
          .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac {
            width: 80%; } }
        @media (min-width: 1025px) {
          .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac {
            width: 80%;
            height: 567px; } }
        @media (min-width: 1200px) {
          .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac {
            width: 82.5%; } }
        @media (min-width: 1300px) {
          .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac {
            width: 84%; } }
        @media (min-width: 1440px) {
          .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac {
            width: 85.5%; } }
        @media (min-width: 1550px) {
          .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac {
            width: 86.5%; } }
        @media (min-width: 1920px) {
          .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac {
            width: 83.5%; } }
        .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac::before {
          content: "";
          z-index: 1;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.9);
          background: #f4f4f4;
          background: linear-gradient(270deg, #f4f4f4 60%, rgba(244, 244, 244, 0) 100%); }
          @media (min-width: 1025px) {
            .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac::before {
              background: linear-gradient(270deg, #f4f4f4 50%, rgba(244, 244, 244, 0) 100%); } }
          @media (min-width: 1300px) {
            .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac::before {
              background: linear-gradient(270deg, #f4f4f4 40%, rgba(244, 244, 244, 0) 100%); } }
        .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac .wt-background {
          display: none; }
          .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac .wt-background img {
            display: block;
            position: absolute;
            left: 0;
            height: 100%;
            object-fit: contain; }
        .wt-accordion-menu .wt-flex-container .wt-flex-col1 .wt-img-block-ac .active {
          display: unset; }
    .wt-accordion-menu .wt-flex-container .wt-flex-col2 {
      background-color: #1f831a;
      padding: 0 10px;
      z-index: 8;
      position: relative;
      height: 764px; }
      @media (min-width: 342px) {
        .wt-accordion-menu .wt-flex-container .wt-flex-col2 {
          height: 728px; } }
      @media (min-width: 350px) {
        .wt-accordion-menu .wt-flex-container .wt-flex-col2 {
          height: 695px; } }
      @media (min-width: 360px) {
        .wt-accordion-menu .wt-flex-container .wt-flex-col2 {
          height: 664px; } }
      @media (min-width: 375px) {
        .wt-accordion-menu .wt-flex-container .wt-flex-col2 {
          height: 640px; } }
      @media (min-width: 455px) {
        .wt-accordion-menu .wt-flex-container .wt-flex-col2 {
          height: 585px; } }
      @media (min-width: 531px) {
        .wt-accordion-menu .wt-flex-container .wt-flex-col2 {
          height: 558px; } }
      @media (min-width: 768px) {
        .wt-accordion-menu .wt-flex-container .wt-flex-col2 {
          width: 65%;
          padding: 0px 0px 0px 62px;
          top: -4px;
          height: 477px; } }
      @media (min-width: 1025px) {
        .wt-accordion-menu .wt-flex-container .wt-flex-col2 {
          width: 65%;
          top: -72px;
          padding: 0 0 8px 100px;
          height: 546px; } }
      .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-title-accordion {
        font-family: 'PFDC';
        font-size: 35px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding-top: 30px;
        position: relative;
        z-index: 0; }
        @media (min-width: 768px) {
          .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-title-accordion {
            text-align: left;
            padding-top: 40px; } }
        @media (min-width: 1025px) {
          .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-title-accordion {
            text-align: left;
            padding-top: 70px; } }
        .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-title-accordion .wt-title-1 {
          color: #1f831a;
          position: relative;
          left: -4px;
          top: 3px;
          z-index: 1;
          text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff; }
          @media (min-width: 768px) {
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-title-accordion .wt-title-1 {
              font-size: 70px;
              text-align: left;
              top: -18px; } }
          @media (min-width: 1025px) {
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-title-accordion .wt-title-1 {
              font-size: 100px;
              text-align: left;
              top: -25px; } }
        .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-title-accordion .wt-title-2 {
          color: #ffffff;
          position: relative;
          left: 4px;
          top: 2px;
          z-index: 2; }
          @media (min-width: 768px) {
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-title-accordion .wt-title-2 {
              font-size: 60px;
              text-align: left;
              left: -189px; } }
          @media (min-width: 1025px) {
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-title-accordion .wt-title-2 {
              font-size: 80px;
              text-align: left;
              top: 0px;
              left: -262px; } }
      .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu {
        list-style-type: none;
        position: relative;
        margin-bottom: 21px; }
        .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .wt-menu-acc-element {
          cursor: pointer;
          background: transparent;
          position: relative;
          overflow: hidden;
          box-sizing: border-box; }
          .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .wt-menu-acc-element .wt-close {
            background-image: unset;
            background-repeat: unset;
            background-size: unset;
            position: unset;
            top: unset;
            left: unset; }
          .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .wt-menu-acc-element h4 {
            font-family: "PFD";
            font-size: 20px;
            font-weight: 500;
            line-height: 23px;
            text-transform: uppercase;
            color: #ffffff;
            padding-left: 25px;
            margin-bottom: 12px;
            position: relative; }
            @media (min-width: 768px) {
              .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .wt-menu-acc-element h4 {
                font-size: 24px; } }
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .wt-menu-acc-element h4:before {
              content: "";
              display: block;
              width: 3px;
              height: 2%;
              background: #ffffff;
              height: 93%;
              position: absolute;
              left: 1px;
              top: 0; }
          .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .wt-menu-acc-element .wt-text-accordion {
            padding-top: 1px;
            padding-left: 47px;
            position: relative;
            display: none; }
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .wt-menu-acc-element .wt-text-accordion:before {
              content: "";
              display: block;
              width: 2px;
              height: 50%;
              position: absolute;
              background: #1e8219;
              top: 15px;
              left: 28px; }
            @media (min-width: 768px) {
              .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .wt-menu-acc-element .wt-text-accordion:before {
                content: "";
                display: block;
                width: 2px;
                height: 71%;
                position: absolute;
                background: #1e8219;
                top: 15px;
                left: 8px; } }
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .wt-menu-acc-element .wt-text-accordion .wt-parag-accor {
              display: none;
              font-family: 'PFD';
              font-size: 18px;
              font-weight: 300;
              line-height: 26px;
              color: #373737;
              padding-top: 10px;
              padding-right: 20px;
              word-spacing: 5px; }
          .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .wt-menu-acc-element .wt-accordion-image-block {
            display: none;
            width: auto;
            height: 0; }
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .wt-menu-acc-element .wt-accordion-image-block picture {
              display: block;
              width: 100%; }
              .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .wt-menu-acc-element .wt-accordion-image-block picture img {
                height: 100%;
                width: auto; }
        .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active {
          background: white;
          border-radius: 5px;
          position: relative;
          top: 4px; }
          @media (min-width: 768px) {
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active {
              right: 42%;
              width: 680px;
              padding-left: 163px; } }
          @media (min-width: 1025px) {
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active {
              right: 31%; } }
          @media (min-width: 1800px) {
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active {
              right: 29%; } }
          @media (min-width: 1850px) {
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active {
              right: 28%; } }
          @media (min-width: 1920px) {
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active {
              right: 27%; } }
          .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active:not(:last-child) {
            margin-bottom: 24px; }
          .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active h4 {
            color: #373737;
            padding-top: 18px;
            padding-left: 45px;
            margin-bottom: 0px; }
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active h4:after {
              content: "";
              display: block;
              background-image: url("../img/x.png");
              background-repeat: no-repeat;
              background-size: cover;
              width: 16px;
              height: 16px;
              position: absolute;
              left: 21px;
              top: 21px; }
            @media (min-width: 768px) {
              .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active h4 {
                padding-top: 30px;
                transition: all 0.7s ease-in-out; }
                .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active h4:after {
                  content: "";
                  display: block;
                  background-image: url("../img/x.png");
                  background-repeat: no-repeat;
                  background-size: cover;
                  width: 16px;
                  height: 16px;
                  position: absolute;
                  left: 1px;
                  top: 32px; } }
          .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active .wt-text-accordion {
            display: block;
            color: #373737;
            padding-bottom: 30px; }
            .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active .wt-text-accordion .wt-parag-accor {
              display: block; }
          .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active:nth-of-type(3) {
            padding-bottom: 0px; }
          .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active .wt-accordion-image-block {
            display: block;
            width: auto;
            height: 176px;
            padding-bottom: 2px;
            margin-top: -12px; }
            @media (min-width: 768px) {
              .wt-accordion-menu .wt-flex-container .wt-flex-col2 .wt-menu .active .wt-accordion-image-block {
                display: none; } }

.wt-out-services {
  width: 100%;
  position: relative;
  margin: 80px 0 153px 0; }
  @media (min-width: 768px) {
    .wt-out-services {
      margin: 80px 0 90px 0;
      padding: 40px 0 40px 0; } }
  @media (min-width: 1190px) {
    .wt-out-services {
      margin: 10px 0 136px 0;
      padding: 70px 0 60px 0; } }
  .wt-out-services .wt-picture-block-services {
    width: calc(55% + 145px);
    height: 861px;
    position: absolute;
    right: 0;
    top: -80px;
    z-index: -1; }
    .wt-out-services .wt-picture-block-services img {
      display: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-filter: contrast(1.4);
      filter: contrast(1.4); }
      @media (min-width: 768px) {
        .wt-out-services .wt-picture-block-services img {
          display: unset; } }
    @media (min-width: 768px) {
      .wt-out-services .wt-picture-block-services {
        margin: 0;
        width: calc(55% + 130px);
        height: 566px;
        top: -55px; } }
    @media (min-width: 1190px) {
      .wt-out-services .wt-picture-block-services {
        height: 680px;
        top: -115px; } }
    @media (min-width: 1920px) {
      .wt-out-services .wt-picture-block-services {
        background-position: 0 49%; } }
  .wt-out-services .wt-green-block-services {
    width: 100%;
    height: calc(100% + 130px);
    background: #1f831a;
    position: absolute;
    left: 0;
    top: -80px; }
    @media (min-width: 768px) {
      .wt-out-services .wt-green-block-services {
        top: 0;
        width: 48%;
        height: 100%; } }
  .wt-out-services .wt-container .wt-medium-block-text {
    width: 8em;
    position: relative;
    left: 10px; }
    @media (min-width: 768px) {
      .wt-out-services .wt-container .wt-medium-block-text {
        top: 0;
        left: 20px; } }
    @media (min-width: 1190px) {
      .wt-out-services .wt-container .wt-medium-block-text {
        left: 0; } }
    .wt-out-services .wt-container .wt-medium-block-text p {
      line-height: 33px; }
      @media (min-width: 768px) {
        .wt-out-services .wt-container .wt-medium-block-text p {
          line-height: 16px; } }
      .wt-out-services .wt-container .wt-medium-block-text p .wt-text-1 {
        font-family: 'PFDC';
        font-size: 35px;
        font-weight: 700;
        color: transparent;
        -webkit-text-stroke-color: #ffffff;
        -webkit-text-stroke-width: 1px; }
        @media (min-width: 768px) {
          .wt-out-services .wt-container .wt-medium-block-text p .wt-text-1 {
            font-size: 70px;
            height: 49px;
            line-height: 63px;
            display: block; } }
        @media (min-width: 1025px) {
          .wt-out-services .wt-container .wt-medium-block-text p .wt-text-1 {
            font-size: 100px; } }
      .wt-out-services .wt-container .wt-medium-block-text p .wt-text-2 {
        font-family: 'PFDC';
        font-size: 35px;
        font-weight: 700;
        color: #ffffff; }
        @media (min-width: 768px) {
          .wt-out-services .wt-container .wt-medium-block-text p .wt-text-2 {
            font-size: 60px;
            line-height: 0.8;
            display: block;
            top: -30px;
            position: relative; } }
        @media (min-width: 1025px) {
          .wt-out-services .wt-container .wt-medium-block-text p .wt-text-2 {
            font-size: 80px; } }
  .wt-out-services .wt-container .wt-low-block-text {
    width: 100%;
    max-width: 300px;
    height: 659px;
    border-radius: 5px;
    background-color: #ffffff;
    margin: 0 auto;
    position: relative; }
    @media (min-width: 768px) {
      .wt-out-services .wt-container .wt-low-block-text {
        max-width: 729px;
        height: 292px;
        margin: 0 0 0 0; } }
    @media (min-width: 1025px) {
      .wt-out-services .wt-container .wt-low-block-text {
        max-width: 901px;
        height: 240px;
        margin-left: 20px; } }
    @media (min-width: 1190px) {
      .wt-out-services .wt-container .wt-low-block-text {
        margin: 0 0 0 0; } }
    .wt-out-services .wt-container .wt-low-block-text .wt-block-text {
      padding: 20px 20px 10px 20px; }
      @media (min-width: 1025px) {
        .wt-out-services .wt-container .wt-low-block-text .wt-block-text {
          padding: 30px 27px 30px 20px; } }
      .wt-out-services .wt-container .wt-low-block-text .wt-block-text h6 {
        font-family: "PFD";
        font-size: 20px;
        color: #373737;
        font-weight: 500; }
        @media (min-width: 768px) {
          .wt-out-services .wt-container .wt-low-block-text .wt-block-text h6 {
            font-size: 24px; } }
      .wt-out-services .wt-container .wt-low-block-text .wt-block-text p {
        font-size: 18px;
        font-family: 'PFD';
        line-height: 26px;
        font-weight: 300; }
        @media (min-width: 1025px) {
          .wt-out-services .wt-container .wt-low-block-text .wt-block-text p br {
            display: none; } }

.wt-price-on-services {
  position: relative; }
  .wt-price-on-services::before {
    content: '';
    width: 100%;
    height: 265px;
    background-color: #f3f3f2;
    position: absolute;
    top: -20px; }
    @media (min-width: 768px) {
      .wt-price-on-services::before {
        height: 310px; } }
  .wt-price-on-services .wt-container .wt-price-serv-text {
    text-align: center;
    position: relative;
    line-height: 32px; }
    @media (min-width: 768px) {
      .wt-price-on-services .wt-container .wt-price-serv-text {
        line-height: 22px;
        margin-bottom: 40px;
        top: 35px; } }
    @media (min-width: 1025px) {
      .wt-price-on-services .wt-container .wt-price-serv-text {
        top: 50px; } }
    .wt-price-on-services .wt-container .wt-price-serv-text .wt-price-span-1 {
      z-index: 1;
      font-family: 'PFDC';
      font-weight: 700;
      color: #373737;
      position: relative;
      font-size: 35px; }
      @media (min-width: 768px) {
        .wt-price-on-services .wt-container .wt-price-serv-text .wt-price-span-1 {
          font-size: 60px; } }
      @media (min-width: 1025px) {
        .wt-price-on-services .wt-container .wt-price-serv-text .wt-price-span-1 {
          font-size: 80px; } }
    .wt-price-on-services .wt-container .wt-price-serv-text .wt-price-span-2 {
      z-index: 0;
      font-size: 35px;
      font-family: 'PFDC';
      font-weight: 700;
      color: transparent;
      -webkit-text-stroke-color: #373737;
      -webkit-text-stroke-width: 1px;
      position: relative; }
      @media (min-width: 768px) {
        .wt-price-on-services .wt-container .wt-price-serv-text .wt-price-span-2 {
          font-size: 70px; } }
      @media (min-width: 1025px) {
        .wt-price-on-services .wt-container .wt-price-serv-text .wt-price-span-2 {
          font-size: 100px; } }
  .wt-price-on-services .wt-container .wt-price-serv-block {
    margin-bottom: 40px; }
    @media (min-width: 768px) {
      .wt-price-on-services .wt-container .wt-price-serv-block {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px; } }
    @media (min-width: 1025px) {
      .wt-price-on-services .wt-container .wt-price-serv-block {
        padding: 20px;
        gap: 40px; } }
    .wt-price-on-services .wt-container .wt-price-serv-block .wt-block-price {
      position: relative;
      display: block;
      padding: 40px 0;
      margin-top: 30px;
      background-color: #1f831a;
      border-radius: 5px;
      text-decoration: none; }
      @media (min-width: 1025px) {
        .wt-price-on-services .wt-container .wt-price-serv-block .wt-block-price {
          padding-bottom: 90px; } }
      @media (min-width: 768px) {
        .wt-price-on-services .wt-container .wt-price-serv-block .wt-block-price {
          padding-bottom: 130px; } }
      .wt-price-on-services .wt-container .wt-price-serv-block .wt-block-price .wt-price-p1 {
        font-family: 'PFD';
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
        font-weight: 500;
        padding: 0 25px;
        text-align: center; }
        @media (min-width: 768px) {
          .wt-price-on-services .wt-container .wt-price-serv-block .wt-block-price .wt-price-p1 {
            padding: 0 20px; } }
        @media (min-width: 1025px) {
          .wt-price-on-services .wt-container .wt-price-serv-block .wt-block-price .wt-price-p1 {
            padding: 0 55px; } }
      .wt-price-on-services .wt-container .wt-price-serv-block .wt-block-price .wt-price-p2 {
        position: relative;
        font-family: 'PFD';
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
        font-weight: 500;
        text-align: center;
        box-sizing: border-box;
        margin-top: 30px;
        padding: 0 25px; }
        .wt-price-on-services .wt-container .wt-price-serv-block .wt-block-price .wt-price-p2::before {
          content: '';
          position: absolute;
          display: block;
          height: 2px;
          top: -15px;
          left: 0;
          right: 0;
          width: 100%;
          background-color: #63a85f; }
        @media (min-width: 768px) {
          .wt-price-on-services .wt-container .wt-price-serv-block .wt-block-price .wt-price-p2 {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 20px;
            font-size: 30px;
            margin-top: 60px;
            padding: 0 55px; }
            .wt-price-on-services .wt-container .wt-price-serv-block .wt-block-price .wt-price-p2::before {
              top: -25px; } }
        @media (min-width: 1025px) {
          .wt-price-on-services .wt-container .wt-price-serv-block .wt-block-price .wt-price-p2 {
            bottom: 40px; }
            .wt-price-on-services .wt-container .wt-price-serv-block .wt-block-price .wt-price-p2::before {
              top: -35px; } }

.wt-services-section .wt-services-container {
  display: block;
  margin: 0 auto;
  width: 100%; }
  .wt-services-section .wt-services-container .wt-services-tabs-buttons {
    padding: 0 10px; }
    .wt-services-section .wt-services-container .wt-services-tabs-buttons .slick-prev,
    .wt-services-section .wt-services-container .wt-services-tabs-buttons .slick-next {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 289px;
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 0;
      transform: translate(50%, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
      z-index: 999; }
    .wt-services-section .wt-services-container .wt-services-tabs-buttons .slick-prev:before,
    .wt-services-section .wt-services-container .wt-services-tabs-buttons .slick-next:before {
      display: block;
      position: absolute;
      font-family: 'slick';
      font-size: 30px;
      line-height: 1;
      color: #978E76;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .wt-services-section .wt-services-container .wt-services-tabs-buttons .slick-prev {
      left: 0;
      background-color: #f4f4f4;
      background-image: url("/libs/img/up-arrow-svgrepo-com.svg");
      background-size: 14px 14px;
      background-position: center;
      background-repeat: no-repeat;
      padding: 15px;
      width: 40px;
      height: 40px;
      border-radius: 5px 5px 0 0;
      transform: translate(-50%, 0) rotate(270deg);
      right: unset;
      left: calc(50% + -20px); }
      .wt-services-section .wt-services-container .wt-services-tabs-buttons .slick-prev:before {
        content: ''; }
    .wt-services-section .wt-services-container .wt-services-tabs-buttons [dir='rtl'] .slick-prev {
      right: 0; }
      .wt-services-section .wt-services-container .wt-services-tabs-buttons [dir='rtl'] .slick-prev:before {
        content: ''; }
    .wt-services-section .wt-services-container .wt-services-tabs-buttons [dir='rtl'] .slick-next {
      left: 0; }
      .wt-services-section .wt-services-container .wt-services-tabs-buttons [dir='rtl'] .slick-next:before {
        content: ''; }
    .wt-services-section .wt-services-container .wt-services-tabs-buttons .slick-next {
      background-color: #f4f4f4;
      background-image: url("/libs/img/up-arrow-svgrepo-com.svg");
      background-size: 14px 14px;
      background-position: center;
      background-repeat: no-repeat;
      padding: 15px;
      width: 40px;
      height: 40px;
      border-radius: 5px 5px 0 0;
      right: calc(50% + -20px);
      transform: translate(50%, 0) rotate(90deg);
      left: unset; }
      .wt-services-section .wt-services-container .wt-services-tabs-buttons .slick-next:before {
        content: ''; }
      .wt-services-section .wt-services-container .wt-services-tabs-buttons .slick-next:after {
        content: "";
        font-family: "slick";
        font-size: 28px;
        display: block;
        color: #978E76;
        bottom: 0;
        left: 0;
        position: absolute; }
    .wt-services-section .wt-services-container .wt-services-tabs-buttons .slick-list {
      overflow: unset; }
    .wt-services-section .wt-services-container .wt-services-tabs-buttons .slick-slide {
      margin: 0 10px; }
    @media (min-width: 768px) {
      .wt-services-section .wt-services-container .wt-services-tabs-buttons {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 30%));
        justify-content: center;
        column-gap: 20px;
        max-width: 1180px;
        margin: 0 auto;
        padding: 0 20px; } }
    @media (min-width: 1025px) {
      .wt-services-section .wt-services-container .wt-services-tabs-buttons {
        padding: 0 0px; } }
    .wt-services-section .wt-services-container .wt-services-tabs-buttons .wt-service-tab {
      background-color: #1f831a;
      width: 100%;
      height: 310px;
      list-style-type: none;
      border-radius: 5px;
      transform: translateY(0px);
      transition: 0.5s; }
      @media (min-width: 768px) {
        .wt-services-section .wt-services-container .wt-services-tabs-buttons .wt-service-tab {
          height: 320px;
          background-color: #f4f4f4; } }
      .wt-services-section .wt-services-container .wt-services-tabs-buttons .wt-service-tab .wt-tab-img {
        width: 180px;
        height: 246px;
        border-radius: 5px;
        overflow: hidden;
        margin: 0 auto;
        position: relative;
        top: -41px; }
        .wt-services-section .wt-services-container .wt-services-tabs-buttons .wt-service-tab .wt-tab-img img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .wt-services-section .wt-services-container .wt-services-tabs-buttons .wt-service-tab .wt-service-title {
        font-family: 'PFD';
        font-size: 24px;
        line-height: 34px;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        display: block;
        width: 100%;
        margin: 0 auto;
        color: #ffffff;
        position: relative;
        top: -24px; }
        @media (min-width: 768px) {
          .wt-services-section .wt-services-container .wt-services-tabs-buttons .wt-service-tab .wt-service-title {
            font-size: 20px;
            line-height: 30px;
            color: #373737;
            position: unset;
            top: unset; } }
        @media (min-width: 1025px) {
          .wt-services-section .wt-services-container .wt-services-tabs-buttons .wt-service-tab .wt-service-title {
            font-size: 24px;
            line-height: 34px; } }
    @media (min-width: 768px) {
      .wt-services-section .wt-services-container .wt-services-tabs-buttons .wt-selected-tab {
        background-color: #1f831a;
        transform: translateY(-41px);
        transition: 0.5s; }
        .wt-services-section .wt-services-container .wt-services-tabs-buttons .wt-selected-tab .wt-service-title {
          color: #ffffff; } }

.wt-services-section .wt-information-service-container {
  display: block;
  margin: 0 auto;
  width: 100%;
  margin-top: 57px;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 70px; }
  @media (min-width: 768px) {
    .wt-services-section .wt-information-service-container {
      padding-bottom: 0;
      margin-top: 30px; } }
  .wt-services-section .wt-information-service-container::after {
    content: '';
    display: block;
    position: absolute;
    background-color: #1f831a;
    width: 100%;
    top: unset;
    bottom: 0;
    right: 0;
    z-index: -1;
    height: 57%; }
    @media (min-width: 768px) {
      .wt-services-section .wt-information-service-container::after {
        width: calc(50% + -156px);
        top: 0;
        bottom: unset;
        height: 500px; } }
    @media (min-width: 1084px) {
      .wt-services-section .wt-information-service-container::after {
        width: calc(50% + -126px); } }
  .wt-services-section .wt-information-service-container .wt-services-tab-content {
    max-width: 1180px;
    margin: 0 auto;
    position: relative;
    display: block;
    padding: 0 10px; }
    @media (min-width: 768px) {
      .wt-services-section .wt-information-service-container .wt-services-tab-content {
        padding: 0;
        display: none; } }
    .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-tab-title {
      font-family: 'PFDC';
      font-weight: 700;
      text-transform: uppercase;
      position: relative; }
      @media (min-width: 768px) {
        .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-tab-title {
          padding-left: 20px; } }
      @media (min-width: 1025px) {
        .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-tab-title {
          padding-left: 0; } }
      .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-tab-title .tab-title1 {
        font-size: 35px;
        -webkit-text-stroke-color: #373737;
        -webkit-text-stroke-width: 1.00px;
        font-weight: 600;
        color: transparent; }
        @media (min-width: 768px) {
          .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-tab-title .tab-title1 {
            line-height: 1;
            font-size: 70px;
            height: 55px;
            left: 20px; } }
        @media (min-width: 1025px) {
          .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-tab-title .tab-title1 {
            font-size: 100px;
            height: 80px;
            left: 0; } }
      .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-tab-title .tab-title2 {
        font-size: 35px;
        color: #373737;
        top: 33px;
        font-weight: 600;
        left: 0; }
        @media (min-width: 768px) {
          .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-tab-title .tab-title2 {
            line-height: 1;
            position: relative;
            top: -30px;
            font-size: 60px;
            left: 20px; } }
        @media (min-width: 1025px) {
          .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-tab-title .tab-title2 {
            top: -40px;
            font-size: 80px;
            left: 0; } }
    .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block {
      margin-top: 40px;
      height: fit-content;
      position: relative;
      z-index: 2;
      margin-right: 0;
      border-radius: 5px;
      overflow: hidden; }
      @media (min-width: 768px) {
        .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block {
          margin-top: 0;
          margin-right: 20px;
          height: 400px;
          width: unset; } }
      @media (min-width: 1025px) {
        .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block {
          margin-right: 0px;
          width: 100%; } }
      .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: #f4f4f4;
        background: linear-gradient(90deg, #f4f4f4 69%, rgba(244, 244, 244, 0.01) 120%);
        z-index: 2; }
        @media (min-width: 1025px) {
          .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block::before {
            background: linear-gradient(90deg, #f4f4f4 49%, rgba(244, 244, 244, 0.01) 100%); } }
      .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 1; }
      .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block .wt-service-text-information {
        position: relative;
        z-index: 3;
        text-align: left;
        font-family: 'PFD';
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        padding-left: 21px;
        padding-top: 21px;
        width: unset;
        max-width: unset; }
        @media (min-width: 768px) {
          .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block .wt-service-text-information {
            width: 100%;
            max-width: 700px;
            padding-left: 21px;
            padding-top: 34px; } }
        @media (min-width: 1025px) {
          .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block .wt-service-text-information {
            padding-left: 42px;
            padding-top: 42px; } }
        .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block .wt-service-text-information span {
          display: inline-block; }
          .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block .wt-service-text-information span:nth-of-type(2) {
            margin-top: 13px; }
          .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block .wt-service-text-information span:nth-of-type(3) {
            margin-top: 13px; }
        .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block .wt-service-text-information ul {
          list-style-type: none;
          margin-left: 10px; }
          .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block .wt-service-text-information ul li {
            margin-top: 13px;
            position: relative;
            margin-left: 20px; }
            .wt-services-section .wt-information-service-container .wt-services-tab-content .wt-service-information-block .wt-service-text-information ul li::before {
              content: '';
              display: block;
              position: absolute;
              top: 5px;
              left: -20px;
              width: 2px;
              height: 14px;
              background-color: #1e8219; }
  .wt-services-section .wt-information-service-container .wt-selected-tab {
    display: block !important;
    left: unset; }
    .wt-services-section .wt-information-service-container .wt-selected-tab .wt-service-information-block {
      opacity: 1;
      transition: 0.5s; }

.wt-certificate-slider {
  display: none;
  justify-content: center;
  align-content: center;
  grid-template-columns: 100%;
  grid-auto-rows: 90%;
  position: fixed;
  z-index: 19;
  padding: 15px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); }
  .wt-certificate-slider .wt-close-slider {
    position: absolute;
    height: 45px;
    width: 45px;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    background-image: url(../img/close-1-white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  .wt-certificate-slider .wt-slider-wrap {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden; }
    .wt-certificate-slider .wt-slider-wrap .wt-slider {
      display: block; }
      .wt-certificate-slider .wt-slider-wrap .wt-slider .wt-slider-img {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; }
  .wt-certificate-slider .slick-slider {
    position: unset;
    height: 100%; }
    .wt-certificate-slider .slick-slider .slick-list {
      height: 100%; }
      .wt-certificate-slider .slick-slider .slick-list .slick-track {
        height: 100%; }
        .wt-certificate-slider .slick-slider .slick-list .slick-track .slick-slide {
          width: 100%;
          height: 100%; }
  .wt-certificate-slider .slick-arrow {
    display: block;
    font-size: 0;
    background: none;
    border: none;
    top: 50%;
    transform: translate(0, -50%);
    height: 50px;
    width: 20px;
    background-image: url(../img/arrow.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
    @media (min-width: 1025px) {
      .wt-certificate-slider .slick-arrow {
        transform: translate(-50%, -50%); } }
  .wt-certificate-slider .slick-prev {
    left: 0;
    transform: scaleX(-1) translate(0, -50%); }
    @media (min-width: 1025px) {
      .wt-certificate-slider .slick-prev {
        left: calc(50% + -300px);
        transform: scaleX(-1) translate(50%, -50%); } }
  .wt-certificate-slider .slick-next {
    right: 0; }
    @media (min-width: 1025px) {
      .wt-certificate-slider .slick-next {
        left: calc(50% + 300px); } }

.wt-certificate-slider-visible {
  display: grid; }

.wt-buy-form-section {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  z-index: 100;
  padding: 15px;
  box-sizing: border-box; }
  .wt-buy-form-section .wt-buy-form-wrap {
    position: relative;
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
    background-color: white;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 15px;
    padding-top: 50px; }
    .wt-buy-form-section .wt-buy-form-wrap .wt-close-button {
      position: absolute;
      top: 10px;
      right: 15px;
      height: 30px;
      width: 30px;
      background-image: url("/libs/img/close-1.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer; }
    .wt-buy-form-section .wt-buy-form-wrap .wt-buy-form {
      display: grid; }
      .wt-buy-form-section .wt-buy-form-wrap .wt-buy-form .wt-input {
        font-family: "PFD";
        font-size: 16px;
        height: 45px;
        border-radius: 5px;
        background-color: #d2e6d1;
        margin-bottom: 10px;
        border: 1px solid #d2e6d1;
        box-sizing: border-box;
        padding: 0 12px 0 12px;
        color: #373737; }
      .wt-buy-form-section .wt-buy-form-wrap .wt-buy-form .wt-message {
        font-family: "PFD";
        font-size: 16px;
        color: #373737;
        display: none;
        height: 45px;
        border-radius: 5px;
        background-color: #d2e6d1;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 25px;
        line-height: 45px;
        font-family: "PFD";
        text-align: center; }
      .wt-buy-form-section .wt-buy-form-wrap .wt-buy-form .wt-button {
        height: 45px;
        border-radius: 5px;
        font-size: 20px;
        color: #ffffff;
        font-weight: 500;
        font-family: "PFD";
        text-align: center;
        background-color: #1e8219;
        margin: 0;
        display: block;
        border: 1px solid #1e8219;
        cursor: pointer; }
      .wt-buy-form-section .wt-buy-form-wrap .wt-buy-form .input-message {
        min-height: 100px;
        padding: 12px; }
      .wt-buy-form-section .wt-buy-form-wrap .wt-buy-form .wt-input-file {
        display: none; }
      .wt-buy-form-section .wt-buy-form-wrap .wt-buy-form .wt-input-file-label {
        display: block;
        position: relative;
        font-family: "PFD";
        font-size: 16px;
        line-height: 40px;
        color: #373737;
        padding-left: 45px;
        margin-bottom: 10px;
        cursor: pointer; }
        .wt-buy-form-section .wt-buy-form-wrap .wt-buy-form .wt-input-file-label::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 35px;
          height: 40px;
          background-image: url("/libs/img/attachment-paperclip.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          transform: rotate(-45deg);
          opacity: 0.5;
          cursor: pointer; }

.wt-page-404-section {
  min-height: calc(100vh - 528px);
  height: 100%;
  box-sizing: border-box;
  padding: 100px 10px 30px 10px; }
  @media (min-width: 768px) {
    .wt-page-404-section {
      min-height: calc(100vh - 256px); } }
  .wt-page-404-section .wt-text-wrap {
    max-width: 1180px;
    margin: 0 auto; }
    .wt-page-404-section .wt-text-wrap .wt-title {
      color: #d2e6d1;
      font-weight: 500;
      font-size: 100px;
      font-family: "PFD";
      text-align: center; }
      @media (min-width: 768px) {
        .wt-page-404-section .wt-text-wrap .wt-title {
          font-size: 200px; } }
    .wt-page-404-section .wt-text-wrap .wt-text {
      font-weight: 500;
      font-size: 15px;
      font-family: "PFD";
      text-align: center; }
      @media (min-width: 768px) {
        .wt-page-404-section .wt-text-wrap .wt-text {
          font-size: 25px; } }
    .wt-page-404-section .wt-text-wrap .wt-link-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      @media (min-width: 768px) {
        .wt-page-404-section .wt-text-wrap .wt-link-wrap {
          flex-direction: row; } }
      .wt-page-404-section .wt-text-wrap .wt-link-wrap .wt-link {
        color: #1f831a;
        font-weight: 500;
        font-size: 15px;
        font-family: "PFD";
        text-align: center;
        text-decoration: none;
        cursor: pointer; }
        @media (min-width: 768px) {
          .wt-page-404-section .wt-text-wrap .wt-link-wrap .wt-link {
            font-size: 25px; } }
      .wt-page-404-section .wt-text-wrap .wt-link-wrap span {
        margin: 0 10px; }

.consultation-form {
  margin: 0 auto;
  min-height: 200px;
  width: 100%; }
  .consultation-form .consultation-form-content {
    width: 100%;
    margin: 0 auto;
    background-color: #1f831a;
    min-height: 200px;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative; }
    @media (min-width: 768px) {
      .consultation-form .consultation-form-content {
        width: 80%; } }
    .consultation-form .consultation-form-content .button-background {
      width: 100%;
      max-width: 300px;
      height: 75px;
      background: black;
      border-radius: 5px; }
      .consultation-form .consultation-form-content .button-background .consultation-button {
        cursor: pointer;
        margin: 0 auto;
        width: 100%;
        max-width: 300px;
        height: 75px;
        border-radius: 5px;
        font-size: 1.6em;
        font-weight: 500;
        background: #1f831a;
        font-family: 'PFDC';
        font-weight: 600;
        color: #ffffff;
        border: 4px solid white; }
    .consultation-form .consultation-form-content .wt-client-req {
      position: fixed;
      display: none;
      z-index: 88;
      height: 100%;
      top: 0;
      background: #00000099;
      width: 100%; }
      .consultation-form .consultation-form-content .wt-client-req .wt-buy-form-wrap {
        position: relative;
        top: 0;
        z-index: 5;
        top: 10em;
        margin: 0 auto;
        max-width: 500px;
        width: 100%;
        background-color: white;
        border-radius: 15px;
        box-sizing: border-box;
        padding: 15px;
        padding-top: 50px;
        display: block; }
        .consultation-form .consultation-form-content .wt-client-req .wt-buy-form-wrap .wt-close-button {
          position: absolute;
          top: 10px;
          right: 15px;
          height: 30px;
          width: 30px;
          background-image: url("/libs/img/close-1.svg");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          cursor: pointer; }
        .consultation-form .consultation-form-content .wt-client-req .wt-buy-form-wrap .wt-buy-form {
          display: grid; }
          .consultation-form .consultation-form-content .wt-client-req .wt-buy-form-wrap .wt-buy-form .wt-input {
            font-family: "PFD";
            font-size: 16px;
            height: 45px;
            border-radius: 5px;
            background-color: #d2e6d1;
            margin-bottom: 10px;
            border: 1px solid #d2e6d1;
            box-sizing: border-box;
            padding: 0 12px 0 12px;
            color: #373737; }
          .consultation-form .consultation-form-content .wt-client-req .wt-buy-form-wrap .wt-buy-form .wt-message {
            display: none;
            height: 45px;
            border-radius: 5px;
            background-color: #d2e6d1;
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 25px;
            line-height: 45px;
            font-family: "PFD";
            text-align: center; }
          .consultation-form .consultation-form-content .wt-client-req .wt-buy-form-wrap .wt-buy-form .wt-button {
            height: 45px;
            border-radius: 5px;
            font-size: 20px;
            color: #ffffff;
            font-weight: 500;
            font-family: "PFD";
            text-align: center;
            background-color: #1e8219;
            margin: 0;
            display: block;
            border: 1px solid #1e8219;
            cursor: pointer; }

.wt-page-industries .wt-description, .wt-page-industries .wt-description-rtl {
  display: block; }

.wt-page-industries .wt-description-two-pictures .company .left-info .wt-image-container .wt-image-container-img {
  display: block; }

.wt-page-industries .wt-description-two-pictures .company .right-info {
  display: grid; }
  .wt-page-industries .wt-description-two-pictures .company .right-info img {
    display: block;
    margin-top: 10px; }
    @media (min-width: 768px) {
      .wt-page-industries .wt-description-two-pictures .company .right-info img {
        grid-row: 3; } }

header {
  padding: 7px 0 0 0; }
  @media (min-width: 1025px) {
    header {
      padding: 20px 0 0 0; } }
  header .wt-angle-down {
    display: inline-block;
    width: 9px;
    height: 11px;
    margin-left: 9px;
    background-image: url("../img/angle-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: .2s; }
  header .opened .wt-angle-down {
    transform: rotate(180deg); }
  header .opened .wt-dropdown-menu-wrap {
    display: block !important; }
  header .wt-dropdown {
    position: absolute;
    display: none;
    opacity: 0;
    list-style: none;
    width: 90px;
    top: 40px;
    left: 0;
    transform: translateY(0px);
    z-index: 99;
    transition: 0.2s; }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }
    header .wt-dropdown li {
      display: block;
      height: 44px;
      line-height: 2.75rem;
      background-color: #f4f4f4;
      text-align: left;
      padding-left: 10px; }
      header .wt-dropdown li:last-child {
        border-radius: 0 0 5px 5px; }
      header .wt-dropdown li .wt-lang-link {
        display: block;
        text-decoration: none;
        color: #373737; }
  header .wt-content-wrap {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 160px; }
    @media (min-width: 768px) {
      header .wt-content-wrap {
        display: grid;
        grid-template-columns: 90px 1fr;
        grid-template-rows: repeat(2, 50px); } }
    @media (min-width: 1025px) {
      header .wt-content-wrap {
        grid-template-columns: 90px 2fr 1fr;
        column-gap: 15px; } }
    header .wt-content-wrap .wt-logo {
      flex: 1 1 90px;
      height: 100px;
      max-width: 90px;
      width: 100%;
      background-image: url("../img/logo.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }
      @media (min-width: 768px) {
        header .wt-content-wrap .wt-logo {
          grid-row: 1/-1; } }
    header .wt-content-wrap .wt-top-contacts {
      flex: 1 1 200px;
      display: grid;
      justify-content: end;
      align-content: space-between;
      margin: 4px 1px 5px 0;
      box-sizing: border-box; }
      @media (min-width: 768px) {
        header .wt-content-wrap .wt-top-contacts {
          grid-template-columns: 10% 50%;
          justify-content: space-between;
          padding-left: 10px; } }
      @media (min-width: 1025px) {
        header .wt-content-wrap .wt-top-contacts {
          grid-template-columns: unset;
          justify-self: end;
          grid-column: 3;
          grid-row: 1;
          margin: 0;
          padding: 0; } }
      header .wt-content-wrap .wt-top-contacts .wt-lenguage {
        display: none;
        width: 90px;
        height: 2.75rem;
        background-color: #f4f4f4;
        border-radius: 5px;
        position: relative;
        text-align: center; }
        @media (min-width: 768px) {
          header .wt-content-wrap .wt-top-contacts .wt-lenguage {
            display: block; } }
        @media (min-width: 1025px) {
          header .wt-content-wrap .wt-top-contacts .wt-lenguage {
            display: none; } }
        header .wt-content-wrap .wt-top-contacts .wt-lenguage p {
          display: inline-block;
          line-height: 2.75rem;
          font-size: 1em;
          font-weight: 500; }
        header .wt-content-wrap .wt-top-contacts .wt-lenguage .wt-dropdown li {
          line-height: 2.75rem; }
          header .wt-content-wrap .wt-top-contacts .wt-lenguage .wt-dropdown li .wt-lang-link {
            display: block;
            text-decoration: none;
            color: #373737; }
        header .wt-content-wrap .wt-top-contacts .wt-lenguage .wt-flag {
          display: inline-block;
          position: relative;
          top: 1px;
          width: 22px;
          height: 14px;
          margin-right: 9px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
      @media (min-width: 768px) {
        header .wt-content-wrap .wt-top-contacts .wt-inner-grid {
          display: grid;
          grid-template-columns: repeat(2, 180px);
          justify-self: end;
          gap: 25px; } }
      @media (min-width: 1025px) {
        header .wt-content-wrap .wt-top-contacts .wt-inner-grid {
          gap: 20px; } }
      header .wt-content-wrap .wt-top-contacts .wt-inner-grid p {
        line-height: 3.1rem;
        text-align: end;
        font-size: 1.20em;
        font-weight: 500; }
        header .wt-content-wrap .wt-top-contacts .wt-inner-grid p a {
          color: #373737;
          text-decoration: none; }
      header .wt-content-wrap .wt-top-contacts .wt-inner-grid .wt-button-contact {
        background-color: #1e8219;
        display: block;
        line-height: 3.1rem;
        height: 2.813rem;
        width: 11.25rem;
        border-radius: 5px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        color: #ffffff; }
    header .wt-content-wrap .wt-top-menu {
      flex: 1 1 100%;
      height: 2.813rem;
      transition: .2s; }
      @media (min-width: 1025px) {
        header .wt-content-wrap .wt-top-menu {
          grid-column: 2;
          grid-row: 1; } }
      header .wt-content-wrap .wt-top-menu .wt-menu-ul {
        line-height: 2.82rem;
        display: grid;
        grid-template-columns: 2.5rem 7.5rem;
        justify-content: space-between;
        height: 100%;
        box-sizing: border-box; }
        @media (min-width: 768px) {
          header .wt-content-wrap .wt-top-menu .wt-menu-ul {
            grid-template-columns: max-content max-content max-content repeat(4, 12%);
            line-height: 4.8rem;
            padding-left: 10px; } }
        @media (min-width: 1025px) {
          header .wt-content-wrap .wt-top-menu .wt-menu-ul {
            line-height: 2.82rem;
            grid-template-columns: max-content max-content max-content 20% max-content; } }
        @media (min-width: 1190px) {
          header .wt-content-wrap .wt-top-menu .wt-menu-ul {
            padding: 0;
            grid-template-columns: max-content max-content max-content 89px max-content;
            column-gap: 45px; } }
        header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-burger {
          display: block;
          position: relative; }
          @media (min-width: 768px) {
            header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-burger {
              display: none; } }
          header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-burger::before {
            content: '';
            display: block;
            position: relative;
            top: 17px;
            height: 15px;
            width: 30px;
            border: 2px solid #1f831a;
            border-right: none;
            border-left: none; }
          header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-burger::after {
            content: '';
            display: block;
            position: relative;
            top: 8px;
            left: 7px;
            height: 0;
            width: 30px;
            border-top: 2px solid #1f831a; }
        header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-item {
          display: none;
          font-size: 16px;
          font-weight: 400;
          position: relative; }
          header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-item a {
            color: #373737;
            text-decoration: none; }
          @media (min-width: 768px) {
            header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-item {
              display: block;
              font-size: 15px; } }
          @media (min-width: 1025px) {
            header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-item:hover .wt-angle-down {
              transform: rotate(180deg); }
            header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-item:hover .wt-dropdown-menu-wrap {
              display: block; } }
          header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-item .wt-dropdown-menu-wrap {
            display: none;
            position: absolute;
            transform: translateY(0px);
            z-index: 99;
            top: 30px;
            padding: 26px 0 0 0;
            left: 0; }
            header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-item .wt-dropdown-menu-wrap .wt-dropdown-menu {
              position: relative;
              display: block;
              background-color: #f4f4f4;
              list-style: none;
              max-width: 250px;
              padding: 10px 20px 10px 20px;
              border-radius: 10px;
              transition: .2s;
              transition: 0.2s;
              animation: fadeInDown 0.3s; }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }
              header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-item .wt-dropdown-menu-wrap .wt-dropdown-menu:hover {
                display: block; }
              header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-item .wt-dropdown-menu-wrap .wt-dropdown-menu .wt-dropdown-item {
                display: block;
                height: 3rem;
                font-size: 18px;
                width: 230px; }
        header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-info, header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-feedback {
          display: none; }
        header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-lenguage {
          display: none;
          width: 90px;
          height: 2.75rem;
          background-color: #f4f4f4;
          border-radius: 5px;
          position: relative;
          text-align: left;
          padding-left: 10px;
          margin-top: 40px;
          box-sizing: border-box; }
          @media (min-width: 1025px) {
            header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-lenguage {
              display: block;
              margin-top: 0;
              justify-self: end; } }
          header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-lenguage p {
            display: inline-block;
            line-height: 2.75rem;
            font-size: 1em;
            font-weight: 500; }
            header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-lenguage p .wt-angle-down {
              display: inline-block;
              width: 9px;
              height: 11px;
              margin-left: 9px;
              background-image: url("../img/angle-down.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover; }
          header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-lenguage .wt-flag {
            display: inline-block;
            position: relative;
            top: 1px;
            width: 22px;
            height: 14px;
            margin-right: 9px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain; }
        header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-social-net {
          display: grid;
          grid-template-columns: repeat(4, 1.25rem);
          justify-content: space-between;
          box-sizing: border-box;
          padding: 6px 0px 0px 5px; }
          @media (min-width: 768px) {
            header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-social-net {
              grid-template-columns: repeat(3, 1.25rem);
              padding: 0;
              grid-column: 6/-1;
              justify-self: end;
              gap: 11px; } }
          @media (min-width: 1025px) {
            header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-social-net {
              grid-column: unset; } }
          header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-social-net a img {
            display: block;
            width: 20px;
            height: 100%; }
          header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-social-net p {
            color: #000000; }
            @media (min-width: 768px) {
              header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-social-net p {
                display: none; } }
            header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-social-net p img {
              display: block;
              width: 20px;
              height: 100%; }
          header .wt-content-wrap .wt-top-menu .wt-menu-ul .wt-social-net a:nth-child(1) img {
            width: 70%; }
    header .wt-content-wrap .wt-mobile-menu {
      display: block;
      position: fixed;
      top: 0;
      z-index: 999;
      left: 0;
      right: 0;
      background-color: white;
      height: unset;
      min-height: 100vh;
      width: 100vw;
      flex: unset;
      box-sizing: border-box;
      padding: 1.25rem;
      overflow-y: scroll; }
      header .wt-content-wrap .wt-mobile-menu .wt-menu-ul {
        line-height: unset;
        display: grid;
        grid-template-columns: 60% 40%;
        grid-template-rows: 4rem repeat(5, 3rem) min-content 4rem;
        grid-auto-flow: column;
        justify-content: space-between;
        height: 100%;
        list-style: none;
        overflow: hidden; }
        header .wt-content-wrap .wt-mobile-menu .wt-menu-ul .wt-burger {
          display: block;
          position: relative;
          height: 100%;
          background-image: url("/libs/img/close-1.svg");
          background-repeat: no-repeat;
          background-position: left;
          background-size: 1.43em; }
          header .wt-content-wrap .wt-mobile-menu .wt-menu-ul .wt-burger::before {
            content: '';
            display: none; }
          header .wt-content-wrap .wt-mobile-menu .wt-menu-ul .wt-burger::after {
            content: '';
            display: none; }
        header .wt-content-wrap .wt-mobile-menu .wt-menu-ul .wt-item {
          display: block;
          font-size: 20px;
          font-weight: 400; }
          header .wt-content-wrap .wt-mobile-menu .wt-menu-ul .wt-item .wt-dropdown-menu {
            display: none;
            position: absolute;
            transform: translateY(0px);
            z-index: 800;
            top: -10px;
            left: 0px;
            background-color: #f4f4f4;
            list-style: none;
            width: unset;
            padding: 10px 20px 10px 20px;
            border-radius: 10px;
            transition: .2s; }
            header .wt-content-wrap .wt-mobile-menu .wt-menu-ul .wt-item .wt-dropdown-menu .wt-dropdown-item {
              height: 2rem;
              font-size: 16px;
              line-height: 2.82rem; }
        header .wt-content-wrap .wt-mobile-menu .wt-menu-ul .wt-lenguage {
          display: block;
          margin: 0 auto;
          grid-column: 2;
          grid-row: 2; }
        header .wt-content-wrap .wt-mobile-menu .wt-menu-ul .wt-social-net {
          display: grid;
          grid-template-columns: repeat(4, 1.25rem);
          justify-content: space-between;
          align-content: space-around;
          box-sizing: border-box;
          padding: 0;
          grid-column: 2;
          grid-row: 1; }
        header .wt-content-wrap .wt-mobile-menu .wt-menu-ul .wt-info {
          width: 150%;
          text-align: start;
          display: block;
          line-height: 2.82rem;
          text-align: left;
          font-size: 16px;
          font-family: 'PFDC';
          font-weight: 700; }
          header .wt-content-wrap .wt-mobile-menu .wt-menu-ul .wt-info a {
            color: #373737;
            text-decoration: none; }
        header .wt-content-wrap .wt-mobile-menu .wt-menu-ul .wt-feedback {
          display: block;
          margin-top: 10px; }
          header .wt-content-wrap .wt-mobile-menu .wt-menu-ul .wt-feedback a {
            background-color: #1e8219;
            display: block;
            line-height: 3.1rem;
            height: 2.813rem;
            width: 11.25rem;
            border-radius: 5px;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            color: #ffffff; }

footer {
  position: relative;
  z-index: 10;
  background-color: #0c6108;
  height: 373px; }
  @media (min-width: 768px) {
    footer {
      height: 198px; } }
  @media (min-width: 1025px) {
    footer {
      height: 136px; } }
  footer .wt-container {
    height: 100%;
    padding: 20px 9px 20px 9px; }
    @media (min-width: 768px) {
      footer .wt-container {
        padding: 20px 18px 20px 18px; } }
    @media (min-width: 1025px) {
      footer .wt-container {
        padding: 26px 18px 26px 18px; } }
    @media (min-width: 1190px) {
      footer .wt-container {
        padding: 26px 0px 26px 0px; } }
    footer .wt-container .wt-footer-grid {
      display: grid;
      height: 100%;
      grid-template-columns: repeat(3, 33%);
      grid-template-rows: 25% 25% 10% 20% 20%;
      justify-content: space-between;
      color: #ffffff; }
      @media (min-width: 768px) {
        footer .wt-container .wt-footer-grid {
          grid-template-rows: 52% 40%;
          grid-template-columns: 13% 46% 31%;
          align-content: space-between; } }
      @media (min-width: 1025px) {
        footer .wt-container .wt-footer-grid {
          grid-template-rows: 50% 50%;
          grid-template-columns: 10% 29% 32% 23%; } }
      footer .wt-container .wt-footer-grid .wt-item .wt-inner-ul {
        list-style: none; }
      footer .wt-container .wt-footer-grid .wt-menu .wt-inner-ul {
        height: 100%;
        display: grid;
        align-content: space-between; }
        footer .wt-container .wt-footer-grid .wt-menu .wt-inner-ul li {
          position: relative; }
          footer .wt-container .wt-footer-grid .wt-menu .wt-inner-ul li a {
            display: block;
            width: 100%;
            text-decoration: none;
            color: #ffffff;
            font-size: 16px;
            font-weight: 400; }
          footer .wt-container .wt-footer-grid .wt-menu .wt-inner-ul li::after {
            content: '';
            display: block;
            position: absolute;
            top: 6px;
            left: 105px;
            width: 9px;
            height: 5px;
            background-image: url("../img/angle-down-white.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            transform: rotate(-90deg); }
      @media (min-width: 1025px) {
        footer .wt-container .wt-footer-grid .wt-menu {
          grid-column: 1;
          grid-row: 1/-1; } }
      footer .wt-container .wt-footer-grid .wt-contacts {
        grid-column: 2/-1; }
        @media (min-width: 768px) {
          footer .wt-container .wt-footer-grid .wt-contacts {
            grid-column: 3/-1;
            display: grid;
            grid-template-columns: 25px 180px;
            justify-content: end;
            gap: 25px; } }
        @media (min-width: 1025px) {
          footer .wt-container .wt-footer-grid .wt-contacts {
            grid-column: 4;
            grid-row: 1/-1; } }
        footer .wt-container .wt-footer-grid .wt-contacts p {
          display: none; }
          @media (min-width: 768px) {
            footer .wt-container .wt-footer-grid .wt-contacts p {
              display: grid;
              grid-template-rows: repeat(3, 22px);
              justify-content: center;
              gap: 6px; } }
          footer .wt-container .wt-footer-grid .wt-contacts p a img {
            display: block;
            width: 20px;
            height: 100%; }
        footer .wt-container .wt-footer-grid .wt-contacts .wt-inner-ul {
          list-style: none;
          display: grid;
          justify-content: end;
          align-content: space-between;
          height: 100%; }
          footer .wt-container .wt-footer-grid .wt-contacts .wt-inner-ul li .wt-phone {
            display: block;
            text-align: center;
            color: white;
            font-size: 19px;
            font-family: 'PFDC';
            font-weight: 700;
            height: 100%;
            text-decoration: none;
            line-height: 35px; }
            @media (min-width: 768px) {
              footer .wt-container .wt-footer-grid .wt-contacts .wt-inner-ul li .wt-phone {
                line-height: unset; } }
          footer .wt-container .wt-footer-grid .wt-contacts .wt-inner-ul li .wt-contact-us {
            background-color: #1e8219;
            display: block;
            line-height: 3.1rem;
            height: 2.813rem;
            width: 11.25rem;
            border-radius: 5px;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            color: #ffffff; }
      footer .wt-container .wt-footer-grid .wt-adress {
        grid-column: 1/-1;
        display: grid;
        align-content: center; }
        @media (min-width: 768px) {
          footer .wt-container .wt-footer-grid .wt-adress {
            grid-column: 2/3;
            grid-row: 1;
            align-content: start; } }
        @media (min-width: 1025px) {
          footer .wt-container .wt-footer-grid .wt-adress {
            grid-column: 3; } }
        footer .wt-container .wt-footer-grid .wt-adress P {
          text-align: center;
          line-height: 24px; }
          @media (min-width: 768px) {
            footer .wt-container .wt-footer-grid .wt-adress P {
              text-align: left;
              padding-left: 50px; } }
          @media (min-width: 1025px) {
            footer .wt-container .wt-footer-grid .wt-adress P {
              padding: unset; } }
      footer .wt-container .wt-footer-grid .wt-social-net {
        grid-column: 1/-1;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1.25rem);
        justify-content: center;
        gap: 25px; }
        @media (min-width: 768px) {
          footer .wt-container .wt-footer-grid .wt-social-net {
            display: none;
            grid-template-columns: repeat(3, 1.25rem); } }
        footer .wt-container .wt-footer-grid .wt-social-net a {
          color: #000000; }
          footer .wt-container .wt-footer-grid .wt-social-net a img {
            display: block;
            width: 20px;
            height: 100%; }
        footer .wt-container .wt-footer-grid .wt-social-net a:nth-child(1) img {
          width: 70%; }
        footer .wt-container .wt-footer-grid .wt-social-net p {
          color: #ffffff; }
          @media (min-width: 768px) {
            footer .wt-container .wt-footer-grid .wt-social-net p {
              display: none; } }
          footer .wt-container .wt-footer-grid .wt-social-net p img {
            display: block;
            width: 20px;
            height: 100%; }
      footer .wt-container .wt-footer-grid .wt-info {
        grid-column: 1/-1;
        display: grid;
        align-content: end;
        gap: 10px; }
        @media (min-width: 768px) {
          footer .wt-container .wt-footer-grid .wt-info {
            grid-column: 3/-1;
            grid-row: 2;
            justify-content: end; } }
        @media (min-width: 1025px) {
          footer .wt-container .wt-footer-grid .wt-info {
            grid-column: 2/4;
            justify-content: start;
            grid-template-columns: 49% 50%;
            gap: unset; } }
        footer .wt-container .wt-footer-grid .wt-info p {
          text-align: center;
          line-height: 24px; }
          @media (min-width: 768px) {
            footer .wt-container .wt-footer-grid .wt-info p {
              width: 220px;
              text-align: left; } }
      footer .wt-container .wt-footer-grid .wt-copyright {
        grid-column: 1/-1;
        display: grid;
        align-content: end; }
        @media (min-width: 768px) {
          footer .wt-container .wt-footer-grid .wt-copyright {
            grid-column: 1/3;
            grid-row: 2; } }
        @media (min-width: 1025px) {
          footer .wt-container .wt-footer-grid .wt-copyright {
            grid-column: 2;
            grid-row: 1; } }
        footer .wt-container .wt-footer-grid .wt-copyright p {
          text-align: center;
          line-height: 24px; }
          @media (min-width: 768px) {
            footer .wt-container .wt-footer-grid .wt-copyright p {
              text-align: left; } }
