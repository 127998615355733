.slider-section-bottom{
    background-color:#ffffff;
    .slider-container {
       width: 100%;
       max-width: 1360px;
       margin:0 auto;
      .grid-col {
        max-width:80rem;
        margin:auto;
        
        .slider-bottom {
           margin-top:40px;
           margin-bottom:96px;   
          .slide {
            position:relative;
            min-height:670px;
            .slider-content-text{
              text-align:center;
              position:absolute;
              top:0;
              right:120px;
              background-color:#2E3A36;
              font-size:15px;
              color:#ffffff;
              height:445px;
              width:610px;
               z-index:993;
              .slide-heading{
                font-size:38px;
                padding-left:3em;
                padding-right:3em;
              }
              .inner-slide-divider{
                height:1px;
                width:51px;
                background-color:#978E76;
                margin:0 auto;
              }
              .subtext{
                padding-left:130px;
                padding-right:130px;
                font-size:13px;
                
              }
              .slide-button{
                display:block;
                width:100%;
                max-width:250px;
                margin:0 auto;
                height:55px;
                background:#ffffff;
                line-height:55px;
                text-transform:uppercase;
                text-decoration:none;
              }
            }
            .img-block{
             margin-top: 31px;
              width: 100%;
              height: 100%;
              max-width: 563px;
              max-height: 383px;
              position: absolute;
                img {
                width:100%;
                max-width:563px;
                z-index:996;
                position:absolute;
                max-height:383px;
              }
              .border-line{
                position:absolute;
                width:90%;
                height:90%;
                border:1px solid #fff;
                z-index:997;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
            
          }
        }
      }
      
    .slick-prev,
    .slick-next {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top:0;
      left:0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 0;
    
      transform: translate(50%, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
      z-index:999;
    }
    
    .slick-prev:before,
    .slick-next:before {
      display: block;
      position: absolute;
      font-family: 'slick';
      font-size: 30px;
      line-height: 1;
      color: #978E76;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev {
      left: 0;
      &:before {
        content: '';
      }
    }
    [dir='rtl'] {
      .slick-prev {
        right: 0;
        left: auto;
        &:before {
          content: '';
        }
      }
      .slick-next {
        right: auto;
        left: 0;
        &:before {
          content: '';
        }
      }
    }
    .slick-next {
     
      &:before {
        content: '';
      }
      &:after {
        content: "";
        font-family: "slick";
        font-size: 28px;
        display: block;
        color: #978E76;
        bottom: 0;
        left: 0;
        position: absolute;
      }
    }
    
    }
  }
  .slick-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0 0px;
      padding: 0;
      &:focus {
        outline: none;
      }
      &.dragging {
        cursor: pointer;
        cursor: hand;
      }
    }
    .slick-slider .slick-track,
    .slick-slider .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .slick-track {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;
      &:after {
        clear: both;
      }
    }
    .slick-track:before,
    .slick-track:after {
      display: table;
      content: '';
    }
    .slick-loading {
      .slick-track {
        visibility: hidden;
      }
      .slick-slide {
        visibility: hidden;
      }
    }
    .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;
      margin: 0 0px;
      img {
        display: block;
      }
      &.slick-loading {
        img {
          display: none;
        }
      }
      &.dragging {
        img {
          pointer-events: none;
        }
      }
    }
    [dir='rtl'] {
      .slick-slide {
        float: right;
      }
    }
    .slick-initialized {
      .slick-slide {
        display: block;
      }
    }
    .slick-vertical {
      .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
      }
    }
    .slick-arrow {
      position: absolute;
      z-index: 99;
      &.slick-hidden {
        display: none;
      }
      .slick-prev:hover,
      .slick-prev:focus,
      .slick-next:hover,
      .slick-next:focus {
          color: transparent;
          outline: none;
          background: transparent;
      }
      .slick-prev:hover:before,
      .slick-prev:focus:before,
      .slick-next:hover:before,
      .slick-next:focus:before {
          opacity: 1;
      }
      .slick-prev.slick-disabled:before,
      .slick-next.slick-disabled:before {
      
      
     
    }
      .slick-dotted {
          &.slick-slider {
            margin-bottom: 30px;
          }
        }
        .slick-dots {
          position: absolute;
          bottom: -20px;
          display: block;
          width: 100%;
          padding: 0;
          margin: 0 0 0 0;
          list-style: none;
          text-align: center;
          li {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;
            button {
              font-size: 0;
              line-height: 0;
              display: block;
              width: 20px;
              height: 20px;
              padding: 5px;
              cursor: pointer;
              color: transparent;
              border: 0;
              outline: none;
              background: transparent;
              &:before {
                font-family: 'slick';
                font-size: 6px;
                line-height: 20px;
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                content: '•';
                text-align: center;
                opacity: .25;
                color: black;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
              }
            }
            &.slick-active {
              button {
                &:before {
                  opacity: .75;
                  color: black;
                }
              }
            }
          }
        }
        .slick-dots li button:hover,
        .slick-dots li button:focus {
          outline: none;
        }
        .slick-dots li button:hover:before,
        .slick-dots li button:focus:before {
          opacity: 1;
        }
      
      
  
  
  }