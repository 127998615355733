

.wt-cta-container{
    background-color: $green1;

    @media (min-width:320px) {
        display: block;
    }

    @media (min-width:$screen-sm-min) {
        display: flex;
        flex-wrap: wrap;
    }

    @media (min-width:$screen-xxl-min) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .image-col{
        width: 35%;
        position: relative;
        top: 4em;
        right: 22.3em;
        z-index: 0;

        @media (min-width:320px) {
            display: none;
        }

        @media (min-width:$screen-sm-min) {
            display: block;
        }

        @media (min-width:$screen-xxl-min) {
            width: 50%;
            right: 0;

        }
        
        img{
            width: 100%;
            height: auto;

            @media (min-width:$screen-sm-min) {
                display: block;
                width: 265%;
            }  
            
            @media (min-width:$screen-xxl-min) {
                display: block;
                width: 100%;
            }
        }
    }

    .form-text-col{
        @media (min-width:$screen-sm-min) {
            width: 65%;
        }

        @media (min-width:$screen-xxl-min) {
            width: 50%;
        }
      
        .cta-heading{
            display: block;
            text-align: left;
            padding: 0 20px;

            @media(min-width:$screen-sm-min){
                padding: 0;
            }

            @media (min-width:$screen-xl-min) {
                font-size: 5em;
            }

            .transparent-text{
                font-size: 2.18em;
                font-family: 'PFDC';
                font-weight: bold;
                color: transparent;
                -webkit-text-stroke-color: $white;
                -webkit-text-stroke-width: 1px; 
                position: relative;
                top: 32px;
                z-index: 0;   

                @media (min-width:$screen-sm-min) {
                    font-size: 70px;
                    line-height: 110px;
                    -webkit-text-stroke-width: 1px; 
                    top: 18px;
                    left: 2.3em;
                    width: 4.4em;
                }  

                @media (min-width:$screen-xxl-min) {
                    font-size: 100px;
                    left: 0;
                    width: 4.4em;
                }

            }

            .cta-subheading{
                font-weight: bold;
                font-family: 'PFDC';
                font-size: 2.18em;
                text-align: right;
                line-height: 40px;
                color: white;
                z-index: 1;
                position: relative;
                display: block;

                @media (min-width:$screen-sm-min) {
                    font-size: 60px;
                    line-height: 70px;
                    right: 11px;
                    bottom: .8em;
                    width: 8em;
                }
                
                @media (min-width:$screen-xxl-min) {
                    font-size: 80px;
                    line-height: 80px;
                    right: 0.2em;
                    bottom: .8em;
                    width: 8em;
                }
           
            }
        }

        .cta-text-block{
            padding: 0 20px;
            z-index: 1;

            @media(min-width:$screen-sm-min){
                text-align: inherit;
                position: relative;
                bottom: 3em;
                padding: 0px 45px 12px 0px;
            }

            @media (min-width:$screen-xxl-min) {
                padding: 0 15em 40px 0;
            }

            >p{
                letter-spacing: 0px;
                line-height: 26px;
                color: #ffffff;
                font-family: 'PFD';
                font-weight: 300;
                text-align: center;
                font-size: 18px;

                @media(min-width:$screen-xs-min){
                    text-align:inherit;
                }        
            }
        }

        .cta-mob-image{
            display: none;

            img{
                width: 94%;
                height: auto;
            }

            @media (min-width:320px) {
                display: block;
            } 

            @media (min-width:$screen-sm-min) {
                display: none;
            } 
        }

        .cta-form{
            width: 100%;
            max-width: 300px;
            background-color: #fff;
            display: block;
            margin: 0 auto;
            position: relative;
            bottom: 2.8em;
            border-radius: 5px 0px 0px 5px;


            @media(min-width:$screen-sm-min){
                max-width: 100em;
            }

            @media (min-width:$screen-xxl-min) {
                max-width: 100em;
            }

            form{
                fieldset{
                    display:flex;
                    flex-wrap: wrap;

                    @media(min-width:$screen-sm-min){
                        flex-wrap: nowrap;
                    }

                    input{
                        width: 250px;
                        height: 45px;
                        border-radius: 5px;
                        background-color: #d2e6d1;
                        margin: 20px auto;  
                        border: 1px solid #d2e6d1;
                        box-sizing: border-box;
                        padding: 0 0 0 12px;
                        color: $text1;
                        font-size: 15px;

                        @media (min-width:$screen-sm-min) {
                            width: 251px;
                            height: 46px;
                            margin: 30px 20px 30px 30px;
                        }      

                        // &::placeholder{
                        //     padding-left: 12px;
                        // }
                    }

                    button{
                        width: 260px;
                        height: 45px;
                        border-radius: 5px;
                        font-size: 16px;
                        color: $white;
                        font-weight: 500;
                        font-family: "PFD";
                        text-align: center;
                        background-color: $green2;
                        margin: 0 auto;         
                        display: block;
                        margin-bottom: 20px;
                        border: 1px solid $green2;

                        @media(min-width:$screen-sm-min){
                            width: 181px;
                            height: 46px;
                            margin: 30px 20px 30px 0;
                        }
                    }
                }
            }
        }
    }
}



