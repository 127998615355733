.wt-about-ltd{
    position: relative;
    margin: 0 0 70px 0;
    @media (min-width: $screen-sm-min) {
        margin: 20px 0 70px 0;
        padding: 0 0 25px 0;
    }
    .wt-grid{
        display: grid;
        gap: 30px;
        box-sizing: border-box;

        max-width: 300px;
        margin: 0 auto;
        width: 100%;
        
        @media (min-width: $screen-sm-min) {
            max-width: unset;
            padding: 0 20px;
            grid-template-columns: 1fr 375px;
            grid-template-rows: auto auto 115px;
        }
        @media (min-width: $screen-sm-min + 100px) {
            grid-template-rows: unset;
        }
        @media (min-width: $screen-contain-min) {
            max-width: 1180px;
            grid-template-columns: 1fr 680px;
            padding: 0;
            column-gap: 0;
        }
        .wt-title{
            grid-column: 1/-1;
            grid-row: 1;

            @media (min-width: $screen-sm-min) {
                margin: 0 0 12px 0;
            }
            .wt-border-text{
                position: relative;
                font-family: 'PFDC';
                font-size: 35px;
                font-weight: 700;
                color: transparent;
                -webkit-text-stroke-color: $text1;
                -webkit-text-stroke-width: 1px;

                @media (min-width: $screen-sm-min) {
                    font-size: 70px;
                    line-height: 0;
                    text-align: left;
                    top: 15px;
                    left: 345px;
                }
                @media (min-width: $screen-contain-min) {
                    font-size: 100px;
                    left: unset;
                    right: 15px;
                    top: 55px;
                    text-align: right;
                }
            }
            .wt-text{
                font-family: 'PFDC';
                font-size: 35px;
                font-weight: 700;
                color: $text1;
                @media (min-width: $screen-sm-min) {
                    font-size: 60px;
                }
                @media (min-width: $screen-xl-min) {
                    width: 70%;
                }
                @media (min-width: $screen-contain-min) {
                    font-size: 80px;
                }
                sub{
                    display: inline;
                    @media (min-width: $screen-sm-min) {
                        display: none;
                    }
                }
            }
        }
        .wt-info{
            grid-row: 3;
            display: grid;
            gap: 20px;

            @media (min-width: $screen-sm-min) {
                grid-row: 2/-1;
                grid-column: 1;
                grid-auto-rows: min-content;
                gap: 15px;
            }
            @media (min-width: $screen-sm-min + 100px) {
                grid-row: 2;
            }
            @media (min-width: $screen-xl-min) {
                padding: 65px 0 0 0;
            }
            h3{
                font-size: 20px;
                font-weight: 500;
                @media (min-width: $screen-xl-min) {
                    width: 70%;
                }
            }
            p{
                font-family: 'PFD';
                font-size: 18px;
                font-weight: 300;
                margin: 0 0 0 0;
                @media (min-width: $screen-sm-min) {
                    line-height: 26px;
                }
                @media (min-width: $screen-xl-min) {
                    line-height: 22px;
                }
            }
        }
        .wt-counters-col{
            grid-row: 2;
            @media (min-width: $screen-sm-min) {
                grid-row: unset;
                display: grid;
                grid-template-columns: 125px 1fr;
            }
            @media (min-width: $screen-contain-min) {
                grid-auto-rows: min-content;
                grid-template-columns: 200px 1fr 200px;
            }
            .wt-years{
                display: grid;
                grid-template-columns: 80px 1fr;

                background-color: $white;
                border-radius: 5px;
                padding: 10px 20px 10px 0;
                margin: 0 0 13px 0;
                @media (min-width: $screen-sm-min) {
                    grid-row: 1;
                    grid-column: 1/3;
                    grid-template-columns: 100px 1fr;
                    padding: 10px 20px 10px 20px;
                }
                @media (min-width: $screen-contain-min) {
                    grid-template-columns: 120px 1fr;
                }
                span{
                    align-self: center;
                    text-align: center;
                    font-size: 30px;
                    font-family: 'PFDC';
                    font-weight: 700;
                    position: relative;
                    top: 5px;
                    color: transparent;
                    -webkit-text-stroke-color: $green2;
                    -webkit-text-stroke-width: 2px;
                    @media (min-width: $screen-sm-min) {
                        font-size: 40px;
                        text-align: left;
                    }
                    @media (min-width: $screen-contain-min) {
                        font-size: 60px;
                    }
                }
                h3{
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 30px;
                    @media (min-width: $screen-sm-min) {
                        align-self: center;
                    }
                }
            }
            .wt-location{
                display: grid;
                grid-template-columns: 80px 1fr;

                background-color: $white;
                border-radius: 5px;
                padding: 10px 20px 10px 0;
                margin: 0 0 13px 0;
                @media (min-width: $screen-sm-min) {
                    grid-row: 2;
                    grid-column: 2/-1;
                    grid-template-columns: 48px 1fr;
                    padding: 10px 20px 10px 20px;
                }
                @media (min-width: $screen-contain-min) {
                    grid-template-columns: 60px 1fr;
                }
                span{
                    align-self: center;
                    text-align: center;
                    font-size: 30px;
                    font-family: 'PFDC';
                    font-weight: 700;
                    position: relative;
                    top: 5px;
                    color: transparent;
                    -webkit-text-stroke-color: $green2;
                    -webkit-text-stroke-width: 2px;
                    @media (min-width: $screen-sm-min) {
                        text-align: left;
                        font-size: 40px;
                    }
                    @media (min-width: $screen-contain-min) {
                        font-size: 60px;
                    }
                }
                h3{
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 30px;
                    @media (min-width: $screen-sm-min) {
                        align-self: center;
                    }
                }
            }
            .wt-process{
                background-color: $white;
                border-radius: 5px;
                padding: 20px;
                margin: 0 0 13px 0;
                @media (min-width: $screen-sm-min) {
                    grid-row: 3;
                    grid-column: 1/-1;
                    display: grid;
                    grid-template-columns: 170px 1fr;
                }
                @media (min-width: $screen-contain-min) {
                    grid-template-columns: 262px 1fr;
                }
                span{
                    display: block;
                    font-size: 30px;
                    font-family: 'PFDC';
                    font-weight: 700;
                    position: relative;
                    top: 5px;
                    color: transparent;
                    -webkit-text-stroke-color: $green2;
                    -webkit-text-stroke-width: 2px;
                    @media (min-width: $screen-sm-min) {
                        align-self: center;
                        font-size: 40px;
                    }
                    @media (min-width: $screen-contain-min) {
                        font-size: 60px;
                        text-align: left;
                    }
                }
                h3{
                    padding: 0 0 0 0;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 30px;
                    @media (min-width: $screen-sm-min) {
                        align-self: center;
                    }
                    @media (min-width: $screen-contain-min) {
                        
                    }
                }
            }
        }
    }
    .wt-picture-wrap{
        position: absolute;
        height: 455px;
        width: calc(50% + 150px);
        left: 0;
        top: 37.4%;
        transform: translate(0%, -50%);
        z-index: -2;

        background-color: $whiteF4;
        overflow: hidden;

        @media (min-width: $screen-sm-min) {
            transform: translate(0, 0);
            top: unset;
            bottom: 0;
            height: 571px;
            width: 100%;
        }
        @media (min-width: $screen-sm-min + 100px) {
            height: 443px;
        }
        @media (min-width: $screen-contain-min) {
            transform: translate(-100%, 0);
            left: calc(50% + 590px);
        }
        .wt-picture{
            display: none;

            @media (min-width: $screen-sm-min) {
                display: unset;
            }

            &::before{
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 110%;
                z-index: 1;
                background: linear-gradient(90deg, rgba(244,244,244,1) 0%, rgba(244,244,244,0.75) 50%, rgba(244,244,244, 1) 100%);
            }

            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .wt-background{
        position: absolute;
        height: calc(455px + 67px);
        width: calc(50% + (-80px));
        right: 0;
        top: 36%;
        transform: translate(0%, -50%);
        z-index: -1;

        background-color: $green2;
        @media (min-width: $screen-sm-min) {
            transform: translate(0, 0);
            top: unset;
            bottom: 30px;
            height: 613px;
            width: calc(50% + (-60px));
        }
        @media (min-width: $screen-sm-min + 100px) {
            height: 470px;
            width: 300px;
            bottom: 20px;
        }
        @media (min-width: $screen-contain-min) {
            height: 510px;
            width: calc(50% + -6px);
            bottom: 30px;
        }
    }
}