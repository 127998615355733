.wt-certificate-slider{
    display: none;
    justify-content: center;
    align-content: center;
    grid-template-columns: 100%;
    grid-auto-rows: 90%;
    position: fixed;
    z-index: 19;
    padding: 15px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.5);

    .wt-close-slider{
        position: absolute;
        height: 45px;
        width: 45px;

        top: 10px;
        left: 50%;
        transform: translate(-50%, 0);

        background-image: url(../img/close-1-white.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .wt-slider-wrap{
        position: relative;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;

        .wt-slider{
            display: block;
            .wt-slider-img{
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
    
        }
    }

    .slick-slider{
        position: unset;
        height: 100%;

        .slick-list{
            height: 100%;
            .slick-track{
                height: 100%;
                .slick-slide{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        
    }

    .slick-arrow{
        display: block;
        font-size: 0;
        background: none;
        border: none;
        top: 50%;
        transform: translate(0, -50%);
        height: 50px;
        width: 20px;

        background-image: url(../img/arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        @media (min-width: $screen-xl-min) {
            transform: translate(-50%, -50%);
        }
    }

    .slick-prev{
        left: 0;
        transform: scaleX(-1) translate(0, -50%);
        @media (min-width: $screen-xl-min) {
            left: calc(50% + -300px);
            transform: scaleX(-1) translate(50%, -50%);
        }
    }

    .slick-next{
        right: 0;
        @media (min-width: $screen-xl-min) {
            left: calc(50% + 300px);
        }
    }
}

.wt-certificate-slider-visible{
    display: grid;
}