.wt-card-item{
    display: grid;
    gap: 20px;
    width: 300px;
    grid-template-columns: 300px;
    margin: 0 auto;
    margin-bottom: 70px;
    @media (min-width: $screen-xs-min) {
        width: 400px;
        grid-template-columns: 400px;
    }
    @media (min-width: $screen-sm-min) {
        grid-template-columns: 300px 1fr;
        width: unset;
    }
    @media (min-width: $screen-xl-min) {
        width: 100%;
        grid-template-rows: auto auto 1fr;
        margin-bottom: 0;
    }
    .wt-item-slider-wrap{
        overflow: hidden;
        position: relative;
        display: grid;
        grid-template-rows: 45px 80px 402px;
        gap: 20px;
        width: 300px;
        @media (min-width: $screen-xs-min) {
            width: 400px;
            grid-template-columns: 400px;
        }
        @media (min-width: $screen-sm-min) {
            grid-template-rows: 381px 45px;
            width: 300px;
            grid-template-columns: unset;
        }
        @media (min-width: $screen-xl-min) {
            grid-template-rows: 381px;
        }
        .slick-next, .slick-prev{
            font-size: 0;
            top: 50%;
            color: rgba(255, 255, 255, 0.53);
            transform: translate(-50%, -50%);
        }
        .slick-next{
            right: 0px;
            transform: translate(-50%, -50%);
            background: none;
            border: none;
            color: transparent;
            background-image: url('../img/arrow.svg');
            background-position: center;
            background-size: cover;
            height: 45px;
            width: 20px;
        }
        .slick-prev{
            left: 20px;
            background: none;
            border: none;
            color: transparent;
            background-image: url('../img/arrow.svg');
            background-position: center;
            background-size: cover;
            transform: translate(-50%, -50%) scale(-1, 1);
            height: 45px;
            width: 20px;
        }
        .wt-filters{
            display: block;
            line-height: 45px;
            font-size: 16px;
            max-width: 300px;
            width: 100%;
            margin: 0 auto;
            color: $white;
            font-weight: 500;
            text-align: center;
            background-color: $green2;
            border-radius: 5px;
            @media (min-width: $screen-sm-min) {
                grid-row: 2;
            }
            @media (min-width: $screen-xl-min) {
                display: none;
            }
        }
        .wt-title{
            font-family: 'PFDC';
            font-size: 35px;
            font-weight: 700;
            line-height: 40px;
            @media (min-width: $screen-sm-min) {
                display: none;
            }
        }
        .wt-wrap{
            width: 100%;
            @media (min-width: $screen-sm-min) {
                grid-row: 1;
            }
            .wt-item-slider{
                width: 300px;
                height: 320px;
                margin: 0 0 12px 0;
                @media (min-width: $screen-xs-min) {
                    width: 400px;
                }
                @media (min-width: $screen-sm-min) {
                    width: 300px;
                    height: 300px;
                }
                img{
                    height: 320px;
                    border-radius: 5px;
                    @media (min-width: $screen-xs-min) {
                        width: 400px;
                    }
                    @media (min-width: $screen-sm-min) {
                        width: 300px;
                        height: 300px;
                    }
                }
                .slick-list {
                    margin: 0 0;
                }
                .slick-slide {
                    margin: 0 0;
                }
            }
            .wt-slider-dots{
                position: relative;
                width: 300px;
                height: 60px;
                img{
                    height: 70px;
                    width: 80px;
                    border-radius: 5px;

                }
                .slick-list {
                    margin: 0 -10px;
                }
                .slick-slide {
                    margin: 0 10px;
                }
            }
        }
    }
    .wt-order-item{
        display: grid;
        gap: 20px;
        @media (min-width: $screen-sm-min) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 118px 20px 20px 20px (auto+(-10px));
        }
        @media (min-width: $screen-contain-min) {
            grid-template-rows: 118px 20px 20px 20px auto;
        }
        .wt-title{
            display: none;
            position: relative;
            top: -4px;
            font-family: 'PFDC';
            font-size: 60px;
            font-weight: 700;
            line-height: 70px;
            @media (min-width: $screen-sm-min) {
                display: unset;
                grid-column: 1/-1;
            }
            @media (min-width: $screen-xl-min) {
                font-size: 55px;
                line-height: 60px;
            }
            @media (min-width: $screen-contain-min) {
                font-size: 60px;
                line-height: 70px;
            }
        }
        .wt-order-type{
            font-size: 18px;
            font-family: 'PFDC';
            font-weight: 700;
            line-height: 13px;
            @media (min-width: $screen-sm-min) {
                grid-row: 2;
                grid-column: 1;
            }
        }
        .wt-price{
            font-size: 18px;
            font-family: 'PFD';
            font-weight: 300;
            line-height: 13px;
            @media (min-width: $screen-sm-min) {
                grid-row: 3;
                grid-column: 1;
            }
            span{
                color: $green2;
                font-size: 20px;
                font-family: 'PFDC';
                font-weight: 700;
            }
        }
        .wt-country{
            font-size: 18px;
            font-family: 'PFD';
            font-weight: 300;
            line-height: 13px;
            @media (min-width: $screen-sm-min) {
                grid-row: 4;
                grid-column: 1/-1;
            }
            span{
                font-family: 'PFDC';
                font-weight: 700;
            }
        }
        .wt-buy{
            display: block;
            background-color: $green2;
            border-radius: 5px;
            text-align: center;
            width: 100%;
            height: 45px;

            line-height: 46px;
            color: $white;
            font-size: 16px;
            font-weight: 500;

            cursor: pointer;
            @media (min-width: $screen-sm-min) {
                grid-row: 2/4;
                grid-column: 2;
            }
        }
        .wt-payment{
            display: block;
            width: 100%;
            background-color: $whiteF4;
            border-radius: 5px;
            padding: 15px 22px;
            box-sizing: border-box;
            .wt-title{
                font-size: 20px;
                font-family: 'PFDC';
                font-weight: 700;
                line-height: 26px;
            }
            p{
                font-size: 18px;
                font-family: 'PFD';
                font-weight: 300;
                line-height: 26px;
            }
            a{
                font-size: 18px;
                font-weight: 500;
                color: $green2;
                text-decoration: none;
            }
        }
        .wt-delivery{
            display: block;
            width: 100%;
            background-color: $whiteF4;
            border-radius: 5px;
            padding: 15px 22px;
            box-sizing: border-box;
            .wt-title{
                font-size: 20px;
                font-family: 'PFDC';
                font-weight: 700;
                line-height: 26px;
            }
            p{
                font-size: 18px;
                font-family: 'PFD';
                font-weight: 300;
                line-height: 26px;
            }
            a{
                font-size: 18px;
                font-weight: 500;
                color: $green2;
                text-decoration: none;
            }
        }
    }
    .wt-item-description{
        width: 100%;
        @media (min-width: $screen-sm-min) {
            grid-column: 1/-1;
        }
        .wt-select-tab{
            display: grid;
            grid-template-columns: 30% 40% 30%;
            justify-content: space-between;

            height: 35px;
            

            list-style: none;
            background-color: $whiteF4;
            border-radius: 5px;
            .wt-tab-button{
                text-align: center;
                font-size: 18px;
                font-family: 'PFDC';
                font-weight: 700;
                line-height: 30px;
                padding: 5px 0 0 0;
                box-sizing: border-box;
            }
            .wt-selected{
                background-color: $green2;
                border-radius: 5px;
                color: $white;
            }
        }
        .tab-content{
            display: block;
            visibility: collapse;
            height: 0;
            padding: 0 0 0 0;
            box-sizing: border-box;
            .wt-title{
                font-size: 18px;
                font-family: 'PFDC';
                font-weight: 700;
                line-height: 26px;
                margin-bottom: 10px;
            }
            .wt-description{
                font-size: 18px;
                font-family: 'PFD';
                font-weight: 300;
                line-height: 26px;
            }
            .wt-list{
                font-size: 18px;
                font-family: 'PFD';
                font-weight: 300;
                line-height: 26px;
                list-style: none;
                .wt-length-list{
                    padding: 0 0 0 20px;
                    position: relative;
                    &::before{
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 0;
                        height: 23px;
                        border-right: 2px solid $green2;
                    }
                    ul{
                        list-style: none;
                        li{
                            display: inline-block;
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
            }
        }
        .wt-selected{
            height: auto;
            visibility: unset;
            padding: 10px 0 0 0;
        }
    }
    .wt-product-catalog{
        @media (min-width: $screen-sm-min) {
            grid-column: 1/-1;
        }
        .wt-main-grid{
            .wt-filters{
                display: none;
            }
        }
    }
}