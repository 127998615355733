footer{
    position: relative;
    z-index: 10;
    background-color: $greenFooter;
    height: 373px;
    @media (min-width: $screen-sm-min) {
        height: 198px;
    }
    @media (min-width: $screen-xl-min) {
        height: 136px;
    }
    .wt-container{
        height: 100%;
        padding: 20px 9px 20px 9px;
        @media (min-width: $screen-sm-min) {
            padding: 20px 18px 20px 18px;
        }
        @media (min-width: $screen-xl-min) {
            padding: 26px 18px 26px 18px;
        }
        @media (min-width: $screen-contain-min) {
            padding: 26px 0px 26px 0px;
        }
        .wt-footer-grid{
            display: grid;
            height: 100%;
            grid-template-columns: repeat(3, 33%);
            grid-template-rows: 25% 25% 10% 20% 20%;
            justify-content: space-between;
            color: $white;
            @media (min-width: $screen-sm-min) {
                grid-template-rows: 52% 40%;
                grid-template-columns: 13% 46% 31%;
                align-content: space-between; 
            }
            @media (min-width: $screen-xl-min) {
                grid-template-rows: 50% 50%;
                grid-template-columns: 10% 29% 32% 23%;
            }
            .wt-item{
                .wt-inner-ul{
                    list-style: none;
                    
                }
            }
            .wt-menu{
                .wt-inner-ul{
                    height: 100%;
                    display: grid;
                    align-content: space-between;
                    li{
                        position: relative;
                        a{
                            display: block;
                            width: 100%;
                            text-decoration: none;
                            color: $white;
                            font-size: 16px;
                            font-weight: 400;
                        }
                        &::after{
                            content: '';
                            display: block;
                            position: absolute;
                            top: 6px;
                            left: 105px;

                            width: 9px;
                            height: 5px;
                            background-image: url('../img/angle-down-white.svg');
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            transform: rotate(-90deg);
                        }
                    }
                }
                @media (min-width: $screen-xl-min) {
                    grid-column: 1;
                    grid-row: 1/-1;
                }
            }
            .wt-contacts{
                grid-column: 2/-1;
                
                @media (min-width: $screen-sm-min) {
                    grid-column: 3/-1;
                    display: grid;
                    grid-template-columns: 25px 180px;
                    justify-content: end;
                    gap: 25px;
                }
                @media (min-width: $screen-xl-min) {
                    grid-column: 4;
                    grid-row: 1/-1;
                }
                p{
                    display: none;
                    @media (min-width: $screen-sm-min) {
                        display: grid;
                        grid-template-rows: repeat(3, 22px);
                        justify-content: center;
                        gap: 6px;
                    }
                    a{
                        img{
                            display: block;
                            width: 20px;
                            height: 100%;
                        }
                    }
                }
                .wt-inner-ul{
                    list-style: none;
                    display: grid;
                    justify-content: end;
                    align-content: space-between;
                    height: 100%;
                    @media (min-width: $screen-sm-min) {
                        
                    }
                    
                    li{
                        .wt-phone{
                            display: block;
                            text-align: center;
                            color: white;
                            font-size: 19px;
                            font-family: 'PFDC';
                            font-weight: 700;
                            height: 100%;
                            text-decoration: none;
                            line-height: 35px;
                            @media (min-width: $screen-sm-min) {
                                line-height: unset;
                            }
                        }

                        .wt-contact-us{
                            background-color: $green2;
                            display: block;
                            line-height: 3.1rem;
                            height: 2.813rem;
                            width: 11.25rem;
                            border-radius: 5px;
                            text-align: center;

                            font-size: 16px;
                            font-weight: 500;
                            text-decoration: none;
                            color: $white;
                        }
                    }
                }
            }
            .wt-adress{
                grid-column: 1/-1;
                display: grid;
                align-content: center;
                @media (min-width: $screen-sm-min) {
                    grid-column: 2/3;
                    grid-row: 1;
                    align-content: start;
                }
                @media (min-width: $screen-xl-min) {
                    grid-column: 3;
                }
                P{
                    text-align: center;
                    line-height: 24px;
                    @media (min-width: $screen-sm-min) {
                        text-align: left;
                        padding-left: 50px;
                    }
                    @media (min-width: $screen-xl-min) {
                        padding: unset;
                    }
                }
            }
            .wt-social-net{
                grid-column: 1/-1;
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, 1.25rem);
                justify-content: center;
                gap: 25px;
                
                @media (min-width: $screen-sm-min) {
                    display: none;
                    grid-template-columns: repeat(3, 1.25rem);
                }

                a{
                    color: $black;
                    
                    img{
                        display: block;
                        width: 20px;
                        height: 100%;
                    }
                }

                a:nth-child(1){
                    img{
                        width: 70%;
                    }
                }

                p{
                    color: $white;

                    @media (min-width: $screen-sm-min) {
                        display: none;
                    }
                        
                    img{
                        display: block;
                        width: 20px;
                        height: 100%;
                    }
                }
            }
            .wt-info{
                grid-column: 1/-1;
                display: grid;
                align-content: end;
                gap: 10px;
                @media (min-width: $screen-sm-min) {
                    grid-column: 3/-1;
                    grid-row: 2;
                    justify-content: end;
                }
                @media (min-width: $screen-xl-min) {
                    grid-column: 2/4;
                    justify-content: start;
                    grid-template-columns: 49% 50%;
                    gap: unset;
                }
                p{
                    text-align: center;
                    line-height: 24px;
                    @media (min-width: $screen-sm-min) {
                        width: 220px;
                        text-align: left;
                    }
                }
            }
            .wt-copyright{
                grid-column: 1/-1;
                display: grid;
                align-content: end;
                @media (min-width: $screen-sm-min) {
                    grid-column: 1/3;
                    grid-row: 2;
                }
                @media (min-width: $screen-xl-min) {
                    grid-column: 2;
                    grid-row: 1;
                }
                p{
                    text-align: center;
                    line-height: 24px;
                    @media (min-width: $screen-sm-min) {
                        text-align: left;
                    }
                }
            }
        }
    }
}